import React, { useEffect, useState } from 'react';
import axios from 'axios';
import Dropdown from './DropDown';
import Swal from 'sweetalert2';
import Loader from '../loader'
import { useNavigate } from 'react-router-dom';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

function AssignProjectForm() {
  const navigate = useNavigate();

  const [formData, setFormData] = useState({
    fullName: '',
    email: '',
    contactno: '',
    ssn: '',
    hname: '',
    gender: '',
    visa: '',
    area: '',
    city: '',
    startDate: '',
    endDate: '',
    job_role: '',
    clientPoc: '',
    cleintEmail: '',
    cleintMobNo: '',
    passwordd: '',
    clientPOCName: '',
    clientPOCRole: '',
    clientPOCPriNo: '',
    clientPOCSecNo: '',
    clientPOCEmail: '',
    duration: '',
    rate: '',
    pay_type: ''
  });
  const [isPlaceholderVisible, setIsPlaceholderVisible] = useState(true);
  const [payType, setPayType] = useState('');
  const token = localStorage.getItem('token');
  const [isLoading, setIsLoading] = useState(false);
  const paytypes = [
    { id: 1, value: "FullTime" },
    { id: 2, value: "C2C" },
    { id: 3, value: "W2" },
  ]

  const handlePayTypes = (event) => {

    setPayType(event.value)

  }
  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData(prevState => ({
      ...prevState,
      [name]: value
    }));
    // if (!formData.startDate || !formData.endDate) {
    //   setIsPlaceholderVisible(true)
    // }
  }
  const handleStartDateChange = (date) => {
    const dateObject = new Date(date);
    const year = dateObject.getFullYear();
    const month = dateObject.getMonth() + 1;
    const day = dateObject.getDate();

    const formattedDate = `${year}/${month < 10 ? '0' : ''}${month}/${day}`;

    // const formattedDate = `${day < 10 ? '0' : ''}${day}/${month < 10 ? '0' : ''}${month}/${year}`;
    setFormData((prevData) => ({
      ...prevData,
      startDate: formattedDate,
    }));
    if (new Date(formattedDate) > new Date(formData.endDate)) {
      setFormData((prevData) => ({
        ...prevData,
        endDate: formattedDate,
      }));
    }
  };

  const handleEndDateChange = (date) => {
    const dateObject = new Date(date);
    const year = dateObject.getFullYear();
    const month = dateObject.getMonth() + 1;
    const day = dateObject.getDate();
    const formattedDate = `${year}/${month < 10 ? '0' : ''}${month}/${day < 10 ? '0' : ''}${day}`;
    setFormData((prevData) => ({
      ...prevData,
      endDate: formattedDate,
    }));
    if (new Date(formattedDate) < new Date(formData.startDate)) {
      // setStartDate(formattedDate);
      setFormData((prevData) => ({
        ...prevData,
        startDate: formattedDate,
      }));
    }
  };

  const DateInput = ({ date, required, isPlaceholderVisible, handleBlur, handleChange, handleFocus, label, className, name }) => (


    <DatePicker
      selected={date}
      onChange={handleChange}
      // dateFormat="yyyy/MM/DD"
      // showDateMonthYearPicker
      // maxDate={maxDate}
      placeholderText={`${label} 📅`}
      required={required}
      className={className}
      // className={dynamicClasses}
      // className='w-full h-[45px] rounded-[4px] px-2 bg-transparent border border-neutral-500'
    />
    // <div style={{ position: 'relative', display: 'inline-block', }}>
    //   <input
    //     type="date"
    //     name={name}
    //     value={date}
    //     onChange={handleChange}
    //     onFocus={handleFocus}
    //     onBlur={handleBlur}
    //     className={` ${className} rounded-lg`}
    //     style={{
    //       border: 'none',
    //       outline: 'none',
    //       padding: '10px',
    //       width: '160px',
    //       fontSize: '16px',
    //       color: isPlaceholderVisible ? 'transparent' : "black", // Always use black color for input
    //       backgroundColor: 'transparent', // Ensure background remains transparent
    //       border: '1px solid #ccc', borderRadius: '5px'
    //     }}
    //     required
    //   />
    //   {isPlaceholderVisible && (
    //     <span style={{ position: 'absolute', top: '10px', left: '10px', color: '#888', pointerEvents: 'none' }}>
    //       {label}
    //     </span>
    //   )}
    //   <div className="invalid-feedback">
    //     Valid {label} is required.
    //   </div>
    // </div>

  )



  const [vendorsPOC, setVendorsPOC] = useState([
    { company_id: '', job_role: '', email_address: '', contact_number: '', secondary_number: '', name: '' }
  ]);
  const [showNewVendorModal, setShowNewVendorModal] = useState(false);
  const [showClientModal, setShowClientModal] = useState(false);
  const [clientSelectValue, setclientSelectValue] = useState('');
  const [clients, setClients] = useState([]);
  const [vendors, setVendors] = useState([]);

  const [consultants, setConsultants] = useState([]);


  const [selectedClient, setSelectedClient] = useState([]);
  const [vendorsId, setVendorsId] = useState([]);
  const [companyPOCId, setCompanyPOCId] = useState([]);
  const [consultantId, setConsultantId] = useState("");

  const headers = {
    Authorization: `${token}`
  }
  useEffect(() => {
    // Retrieve token from local storage


    setIsLoading(true);
    // Make API call{}user
    axios.get(`${process.env.REACT_APP_API_URL}/consultants`, { headers })
      .then(response => {


        setConsultants(response.data);
        // setUserData(response.data);
      })
      .catch(error => {

        if (error.response.status == 401) {
          console.error('Failed to fetch week data:');

          alert("Session Expired Please Login");
          navigate("/admin");
        }
        console.error('Error fetching user data:', error);
      });
    axios.get(`${process.env.REACT_APP_API_URL}/companies/clients`, {
      headers: {
        'Authorization': token,
      }
    })
      .then(response => {
        setIsLoading(false);
        const newClients = response.data.map(item => ({
          id: item._id,
          name: item.company_name
        }));
        // Update state only if the fetched clients are different from the current state
        // This checks if the length is different or content is different (simplified check)
        if (newClients.length !== clients.length || newClients.some((client, index) => client.id !== clients[index]?.id)) {
          setClients(newClients);

        }
      })
      .catch(error => {
        console.error('Error fetching clients:', error);
      });
  }, []);
  // Client Dropdown
  useEffect(() => {

    // Conditionally fetch clients if the array is empty to prevent infinite loops
    const token = localStorage.getItem('token');
    if (!token) {
      console.error('Token not found in local storage');
      return;
    }
    axios.get(`${process.env.REACT_APP_API_URL}/companies/clients`, {
      headers: {
        'Authorization': token,
      }
    })
      .then(response => {
        const newClients = response.data.map(item => ({
          id: item._id,
          name: item.company_name
        }));
        // Update state only if the fetched clients are different from the current state
        // This checks if the length is different or content is different (simplified check)
        if (newClients.length !== clients.length || newClients.some((client, index) => client.id !== clients[index]?.id)) {
          setClients(newClients);
        }
      })
      .catch(error => {
        if (error.response.status == 401) {
          console.error('Failed to fetch week data:');

          alert("Session Expired Please Login");
          navigate("/admin");
        }
        console.error('Error fetching clients:', error);
      });

  }, []);

  //Vendors Dropdwon
  useEffect(() => {
    const token = localStorage.getItem('token');
    if (!token) {
      console.error('Token not found in local storage');
      return;
    }
    axios.get(`${process.env.REACT_APP_API_URL}/companies/vendors`, {
      headers: {
        'Authorization': `${token}`,
      }
    })
      .then(response => {

        const newVendors = response.data.map((item) => ({
          id: item._id,
          name: item.company_name
        }));
        setVendors(newVendors);
      })
      .catch(error => {
        if (error.response.status == 401) {
          console.error('Failed to fetch week data:');

          alert("Session Expired Please Login");
          navigate("/admin");
        }

      });
  }, []);


  // Dropdown Value change for Vendor
  const [vendorInfo, setVendorInfo] = useState({
    company_name: '',
    company_type: 1,
    email: '',
    contact_number: '',
    website: '',
    status: true
  })

  // Dropdown Value Change for client


  const handleVendorSelectionChange = (index, event) => {
    const companyId = event;


    if (companyId === 'new') {
      // Set up for a new vendor
      setVendorInfo({
        company_name: '',
        company_type: 1,
        email: '',
        contact_number: '',
        website: '',
        status: true
      });
      setShowNewVendorModal(true);
    } else {
      // Update existing vendor details in the form
      const selectedVendor = vendors.find(vendor => vendor.id === companyId);
      setVendorsPOC(prevVendorsPOC => prevVendorsPOC.map((vendor, i) =>
        i === index ? {
          ...vendor,
          company_id: companyId,
          // name: selectedVendor ? selectedVendor.name : '',
          // email_address: selectedVendor ? selectedVendor.email : '',
          // contact_number: selectedVendor ? selectedVendor.contact_number : ''
        } : vendor
      ));
    }
  };

  const handleVendorInputChange = (index, event) => {
    const { name, value } = event.target;
    setVendorsPOC(prevVendorsPOC => prevVendorsPOC.map((vendor, i) =>
      i === index ? { ...vendor, [name]: value } : vendor
    ));
  };

  const addVendor = () => {
    setVendorsPOC(prevVendorsPOC => [
      ...prevVendorsPOC,
      {
        company_id: '',
        name: '',
        job_role: '',
        email_address: '',
        contact_number: '',
        secondary_number: '',
      }
    ]);
  };
  const removeVendor = index => {
    setVendorsPOC(prevVendorsPOC => prevVendorsPOC.filter((_, i) => i !== index));
  };
  const submitNewVendor = () => {

    const token = localStorage.getItem('token');
    if (!token) {
      console.error('Token not found in local storage');
      return;
    }

    axios.post(`${process.env.REACT_APP_API_URL}/companies/`, vendorInfo, {
      headers: {
        'Authorization': `${token}`
      }
    }).then(response => {
      const newVendor = response.data;
      setVendors(prevVendors => [...prevVendors, { id: newVendor._id, name: newVendor.company_name }]);
      setVendorsPOC(prevVendorsPOC => prevVendorsPOC.map((vendor, idx) =>
        idx === prevVendorsPOC.length - 1 ? {
          ...vendor,
          company_id: newVendor._id, // Set the company_id to the new vendor's ID
          // name: newVendor.company_name,
          // email_address: newVendor.email,
          // contact_number: newVendor.contact_number
        } : vendor
      ));
      setShowNewVendorModal(false); // Close the modal on save

    }).catch(error => {
      if (error.response.status == 401) {
        console.error('Failed to fetch week data:');

        alert("Session Expired Please Login");
        navigate("/admin");
      }
      console.error('Failed to save new vendor:', error);
    });
  };


  const [clientInfo, setClientInfo] = useState({
    name: '',
    company_type: 0,
    email: '',
    contact_number: '',
    website: '',
    status: true
  })


  const handleClientSelectChange = (event) => {
    const selectedValue = event;

    setclientSelectValue(selectedValue);
    if (selectedValue === 'new') {

      setShowClientModal(true);  // Show the client modal
    } else {
      // Optionally, find the client object if more data is needed
      const selectedClient = clients.find(client => client.id === selectedValue.id);

      setSelectedClient(selectedClient.id);
      setclientSelectValue(selectedClient);


    }
  };

  const handleClientClose = () => {
    setShowClientModal(false);
    setclientSelectValue('');

  };

  const handleClientFieldChange = (event) => {
    const { name, value } = event.target;
    setClientInfo(prevInfo => ({
      ...prevInfo,
      [name]: value
    }));

    // setClientName(event.target.value);
  };


  const handleSaveClient = (event) => {
    event.preventDefault();

    const token = localStorage.getItem('token');
    if (!token) {
      console.error('Token not found in local storage');
      return;
    }

    const headers = {
      Authorization: `${token}`
    }
    axios.post(`${process.env.REACT_APP_API_URL}/companies/`, clientInfo, { headers })
      .then(response => {
        const newClient = response.data;
        if (newClient && newClient._id) {

          setClients(prevClients => [
            ...prevClients.filter(client => client.id !== 'new'),
            { id: newClient._id, name: newClient.company_name }
          ]);

          setSelectedClient(newClient._id)
         

          setclientSelectValue(newClient._id);

          setShowClientModal(false);
        } else {
          console.error('New client data is invalid:', newClient);
        }
      })
      .catch(error => {
        if (error.response.status == 401) {
          console.error('Failed to fetch week data:');

          alert("Session Expired Please Login");
          navigate("/admin");
        }
        console.error('Failed to save client:', error);
        alert('Failed to save client');
      });
  };




  const handleVendorSubmit = async (vendorsPOC) => {
    const token = localStorage.getItem('token');
    if (!token) {
      console.error('Token not found in local storage');
      return;
    }

    const headers = {
      Authorization: `${token}`
    }

    const promises = vendorsPOC.map(vendor => {
      return axios.post(`${process.env.REACT_APP_API_URL}/companypocs/`, vendor, { headers }).then(response => {
        setVendorsId(prev => {
          const vendorId = vendor.company_id;
          if (!prev.includes(vendorId)) {
            return [...prev, vendorId];
          }
          return prev;
        });
        setCompanyPOCId(prev => {
          const vendorPOCId = response.data._id;
          if (!prev.includes(vendorPOCId)) {
            return [...prev, vendorPOCId];
          }
          return prev;
        });
      });
    });

    return Promise.all(promises);
  };


  const handleClientSubmit = async (clientData) => {
    const token = localStorage.getItem('token');
    if (!token) {
      console.error('Token not found in local storage');
      return;
    }

    const headers = {
      Authorization: `${token}` // Typically Bearer authentication scheme is used
    };

    try {
      const response = await axios.post(`${process.env.REACT_APP_API_URL}/companypocs/`, clientData, {
        headers
      });

      // Update companyPOCId state
      setCompanyPOCId(prev => {
        const clientPOCId = response.data._id;
        if (!prev.includes(clientPOCId)) {
          return [...prev, clientPOCId];
        }
        return prev;
      });
    } catch (error) {
      if (error.response.status == 401) {


        alert("Session Expired Please Login");
        navigate("/admin");
      }
      if (error.response.status == 401) {
        console.error('Failed to fetch week data:');

        alert("Session Expired Please Login");
        navigate("/");
      }
      console.error("Failed to submit client data:", error.response ? error.response.data : error.message);
    }
  };


  const handleCreateProject = () => {

    const projectData = {
      client_id: selectedClient,
      vendor_ids: vendorsId,
      start_date: formData.startDate,
      end_date: formData.endDate,
      rate: formData.rate,
      rate_type: payType,
      job_role: formData.job_role,
      company_poc_ids: companyPOCId,
      consultant_id: consultantId,
      duration: formData.duration,
      total_hrs: 0,
      status: true
    };

    const token = localStorage.getItem('token');
    if (!token) {
      console.error('Token not found in local storage');
      return;
    }

    const headers = {
      Authorization: `${token}`
    }
    axios.post(`${process.env.REACT_APP_API_URL}/projects`, projectData, { headers }).then(response => {

      Swal.fire({
        showConfirmButton: false,
        timer: 1500,
        title: 'Project has been created successfully!',
        icon: 'success'
      });
    }).catch(error => {
      if (error.response.status == 401) {
        console.error('Failed to fetch week data:');

        alert("Session Expired Please Login");
        navigate("/admin");
      }
      Swal.fire({
        title: 'Error!',
        text: 'Failed to create the project.',
        icon: 'error'
      });
      console.error("Error creating project:", error);
    });
  };

  const [submitSuccess, setSubmitSuccess] = useState(false);
  const [isSubmit, setIsSubmit] = useState(false);

  const handleSubmit = async (event) => {
    event.preventDefault();
    setIsSubmit(true);

    if (!event.currentTarget.checkValidity()) {
      event.stopPropagation();
      event.currentTarget.classList.add('was-validated');
      return;
    }
    // Assuming formData has the needed info for client and vendors
    const clientData = {
      name: formData.clientPOCName,
      company_id: clientSelectValue.id,
      email_address: formData.clientPOCEmail,
      contact_number: formData.clientPOCPriNo,
      secondary_number: formData.clientPOCSecNo,
      job_role: formData.clientPOCRole,
    };

    const personalData = {
      name: formData.fullName,
      email: formData.email,
      contact_number: formData.contactno,
      gender: formData.gender,
      ssn: formData.ssn,
      status: true,
      visa: formData.visa,
      location: formData.city,
      residential_address: formData.hname + formData.area,
      password: formData.passwordd
    };

    try {
      // Simultaneous async operations to ensure all data is ready
      await Promise.all([
        vendorsPOC ? handleVendorSubmit(vendorsPOC) : Promise.resolve(),
        handleClientSubmit(clientData)
      ]);
      setSubmitSuccess(true);
    }
    catch (error) {
      if (error.response.status == 401) {


        alert("Session Expired Please Login");
        navigate("/");
      }
    }
  };

  useEffect(() => {
    if (submitSuccess && companyPOCId.length > 0) {

      handleCreateProject();
    }
    setSubmitSuccess(false);
  }, [submitSuccess]);

  // Manage placeholder visibility on focus
  const handleFocus = () => {
    setIsPlaceholderVisible(false);
  };
  // Manage placeholder visibility on blur
  const handleBlur = () => {
    if (!formData.startDate || !formData.endDate) {
      setIsPlaceholderVisible(true);  // Show placeholder when no date is selected
    }
  };

  return (
    <>
      {isLoading ? (
        <Loader /> // You can replace this with your own loading indicator component
      ) : (
        <div className="container" style={{ width: '1000px', paddingLeft: '100px' }}>
          <div className="row">
            <form className="needs-validation" onSubmit={handleSubmit} noValidate>
              <div className="row">




                {/* Second Column */}
                <div className="col-md-6 mb-3 mt-4">
                  <div className="flex gap-1.5 justify-between w-100 py-1.5 text-2xl text-zinc-500">
                    {/* <label htmlFor="firstName">Full name</label> */}
                    <Dropdown
                      options={consultants.map(consultant => ({
                        value: consultant._id,
                        label: consultant.name
                      }))}
                      onSelect={option => setConsultantId(option.value)}
                      label={"Select Consultant"}
                    />
                  </div>

                  {isSubmit && !consultantId && (
                    <div className="text-danger">
                      Consultant is required
                    </div>
                  )}
                  {/* Pay rate Duration */}

                  <div className="col-md-10 col-sm-12 mb-3  pt-2">
                    {/* <label htmlFor="firstName">Rate</label> */}
                    <input
                      type="text"
                      // className="form-control"
                      className="formal text-xl bg-transparent text-dark form-control justify-center items-start p-2.5 mt-2 capitalize rounded-lg  max-md:pr-5 focus:outline-none focus:border-violet-900"
                      style={{ border: '2px solid rgba(142, 142, 142, 1)' }}
                      id="rate"
                      placeholder="Rate"
                      name="rate"
                      value={formData.rate}
                      onChange={handleChange}
                      required
                    />
                    <div className="invalid-feedback">
                      Valid rate  is required.
                    </div>
                  </div>

                  <div className="col-md-10 col-sm-12 mb-3">
                    {/* <label htmlFor="firstName">Duration</label> */}
                    <input
                      type="text"
                      // className="form-control"
                      className="formal text-xl bg-transparent text-dark form-control justify-center items-start p-2.5 mt-2 capitalize rounded-lg  max-md:pr-5 focus:outline-none focus:border-violet-900"
                      style={{ border: '2px solid rgba(142, 142, 142, 1)' }}
                      placeholder="Duration"
                      id="duration"
                      name="duration"
                      value={formData.duration}
                      onChange={handleChange}
                      required
                    />
                    <div className="invalid-feedback">
                      Valid duration is required.
                    </div>
                  </div>

                  {/* pay type */}
                  <div className="col-md-10">
                    <Dropdown label={"Pay Type"}
                      options={paytypes.map(type => ({
                        id: type.id,
                        value: type.value,
                        label: type.value
                      }
                      ))}
                      onSelect={handlePayTypes}
                    />

                    {isSubmit && payType == '' &&
                      <div className='text-danger'>
                        Paytype is required.
                      </div>
                    }
                  </div>


                  {/* Add Vendor Details */}
                  <div className="col-md-10 mb-3 mt-2">
                    <form onSubmit={handleVendorSubmit}>
                      {vendorsPOC.map((vendor, index) => (
                        <div key={index} className="mb-3 border-bottom pb-3">
                          {/* Vendor selection */}
                          <Dropdown label={"Select Vendor"}
                            options={vendors.map(vendorOption => (
                              { id: vendorOption.id, value: vendorOption.name, label: vendorOption.name }
                            ))}
                            addnewACF2={true}
                            onSelectACF={handleVendorSelectionChange}
                            // onChangeACF={}
                            // onSelect={vendor.company_id}
                            index={index}
                          />
                   
                          {isSubmit && vendorsPOC[0].company_id === '' &&
                            <div className='text-danger'>
                              Vendor is required.
                            </div>
                          }
                       
                          {/* Vendor details */}
                          <input
                            type="text"
                            // className="form-control mb-3"
                            placeholder="Vendor Point Of Contact Name"
                            className="formal text-xl bg-transparent text-dark form-control justify-center items-start p-2.5 mt-2 capitalize rounded-lg  max-md:pr-5 focus:outline-none focus:border-violet-900"
                            style={{ border: '2px solid rgba(142, 142, 142, 1)' }}
                            name="name"
                            value={vendor.name}
                            onChange={(e) => handleVendorInputChange(index, e)}
                            required
                          />
                          <div className="invalid-feedback">
                            Valid name is required.
                          </div>
                          <input
                            type={"email"}
                            // className="form-control mb-3"
                            className="formal text-xl bg-transparent text-dark form-control justify-center items-start p-2.5 mt-3  rounded-lg  max-md:pr-5 focus:outline-none focus:border-violet-900"
                            style={{ border: '2px solid rgba(142, 142, 142, 1)' }}
                            placeholder="Vendor Email ID"
                            name="email_address"
                            value={vendor.email_address}
                            onChange={(e) => handleVendorInputChange(index, e)}
                            required
                          />
                          <div className="invalid-feedback">
                            Valid email is required.
                          </div>
                          <input
                            type="number"
                            // className="form-control mb-3"
                            className="formal text-xl bg-transparent text-dark form-control justify-center items-start p-2.5 mt-3 capitalize rounded-lg  max-md:pr-5 focus:outline-none focus:border-violet-900"
                            style={{ border: '2px solid rgba(142, 142, 142, 1)' }}
                            placeholder="Vendor Primary No"
                            name="contact_number"
                            value={vendor.contact_number}
                            onChange={(e) => handleVendorInputChange(index, e)}
                            required
                          />
                          <div className="invalid-feedback">
                            Valid contact number is required.
                          </div>
                          <input
                            type="text"
                            // className="form-control mb-3"
                            className="formal text-xl bg-transparent text-dark form-control justify-center items-start p-2.5 mt-3 capitalize rounded-lg  max-md:pr-5 focus:outline-none focus:border-violet-900"
                            style={{ border: '2px solid rgba(142, 142, 142, 1)' }}
                            placeholder="Vendor Secondary No"
                            name="secondary_number"
                            value={vendor.secondary_number}
                            onChange={(e) => handleVendorInputChange(index, e)}
                            required

                          />
                          <div className="invalid-feedback">
                            Valid secondary number  is required.
                          </div>
                          <input
                            type="text"
                            // className="form-control mb-3"
                            className="formal text-xl bg-transparent text-dark form-control justify-center items-start p-2.5 mt-3 capitalize rounded-lg  max-md:pr-5 focus:outline-none focus:border-violet-900"
                            style={{ border: '2px solid rgba(142, 142, 142, 1)' }}
                            placeholder="Vendor POC Job Role"
                            name="job_role"
                            value={vendor.job_role}
                            onChange={(e) => handleVendorInputChange(index, e)}
                            required
                          />
                          <div className="invalid-feedback">
                            Valid job role is required.
                          </div>
                          <button type="button" className="btn btn-danger mt-3 w-100" onClick={() => removeVendor(index)}>
                            Remove Vendor
                          </button>

                          {showNewVendorModal && (
                            <div className="modal show" style={{ display: 'block' }}>
                              <div className="modal-dialog">
                                <div className="modal-content">
                                  <div className="modal-header">
                                    <h5 className="modal-title">Add New Vendor</h5>
                                    <button type="button" className="btn-close" onClick={() => setShowNewVendorModal(false)}></button>
                                  </div>
                                  <div className="modal-body">
                                    <input type="text"
                                      className="formal text-xl bg-transparent text-dark form-control justify-center items-start p-2.5 mt-3 capitalize rounded-lg  max-md:pr-5 focus:outline-none focus:border-violet-900"
                                      style={{ border: '2px solid rgba(142, 142, 142, 1)' }}
                                      placeholder="Company Name"
                                      name="company_name"
                                      value={vendorInfo.company_name}
                                      onChange={e => setVendorInfo({ ...vendorInfo, company_name: e.target.value })} />

                                    <input type="email"
                                      className="formal text-xl bg-transparent text-dark form-control justify-center items-start p-2.5 mt-3  rounded-lg  max-md:pr-5 focus:outline-none focus:border-violet-900"
                                      style={{ border: '2px solid rgba(142, 142, 142, 1)' }}
                                      placeholder="Email"
                                      name="email"
                                      value={vendorInfo.email}
                                      onChange={e => setVendorInfo({ ...vendorInfo, email: e.target.value })} />
                                    <input type="text"
                                      className="formal text-xl bg-transparent text-dark form-control justify-center items-start p-2.5 mt-3 capitalize rounded-lg  max-md:pr-5 focus:outline-none focus:border-violet-900"
                                      style={{ border: '2px solid rgba(142, 142, 142, 1)' }}
                                      placeholder="Contact Number"
                                      name="contact_number"
                                      value={vendorInfo.contact_number}
                                      onChange={e => setVendorInfo({ ...vendorInfo, contact_number: e.target.value })} />
                                    <input type="url"
                                      className="formal text-xl bg-transparent text-dark form-control justify-center items-start p-2.5 mt-3 capitalize rounded-lg  max-md:pr-5 focus:outline-none focus:border-violet-900"
                                      style={{ border: '2px solid rgba(142, 142, 142, 1)' }}
                                      placeholder="Website"
                                      name="website"
                                      value={vendorInfo.website}
                                      onChange={e => setVendorInfo({ ...vendorInfo, website: e.target.value })} />
                                  </div>
                                  <div className="modal-footer">
                                    <button type="button" className="btn btn-secondary" onClick={() => setShowNewVendorModal(false)}>Close</button>
                                    <button type="button" className="btn btn-primary" onClick={submitNewVendor}>Save Vendor</button>
                                  </div>
                                </div>
                              </div>
                            </div>
                          )}

                        </div>
                      ))}

                      <button type="button" className="btn btn-primary w-100" onClick={addVendor}>
                        Add Vendor
                      </button>

                    </form>

                  </div>

                </div>

                {/* First Column*/}
                <div className="col-md-6 col-sm-12 mb-3 mt-4">


                  {/* select client drop down */}
                  <div className='col-md-10 mb-'>
                    <div className="container ">
                      <div className="form-floating">
                        <Dropdown label={"Select Client"}
                          options={clients.map(client => ({
                            id: client.id,
                            value: client.id,
                            label: client.name
                          }
                          ))}
                          addnewACF={true}
                          onSelect={handleClientSelectChange}
                          onChangeACF={setShowClientModal}
                
                        />
        
                      </div>
    
                      {isSubmit && clientSelectValue === '' &&
                        <div className="text-red-500 mt-2">
                          Client name is required.
                        </div>
                      }

                      <div className={`modal fade ${showClientModal ? 'show' : ''}`} style={{ display: showClientModal ? 'block' : 'none' }} tabIndex="-1" role="dialog" aria-labelledby="modalLabel" aria-hidden="true">
                        <div className="modal-dialog" role="document">
                          <div className="modal-content">
                            <div className="modal-header">
                              <h5 className="modal-title" id="modalLabel">Add New Client</h5>
                              <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={handleClientClose}></button>
                            </div>
                            <form onSubmit={(e) => {
                              e.preventDefault();
                            }}>
                              <div className="modal-body">

                                <div className="mb-3">
                                  {/* <label htmlFor="companyName"placeholder="Full name" className="form-label">Company Name</label> */}
                                  <input type="text"
                                    className="text-xl bg-transparent text-dark form-control justify-center items-start p-2.5 mt-2 capitalize rounded-lg  max-md:pr-5 focus:outline-none focus:border-violet-900"
                                    placeholder="Client Name"
                                    id="companyName"
                                    name="company_name"
                                    value={clientInfo.company_name}
                                    onChange={handleClientFieldChange}
                                    style={{ border: '2px solid rgba(142, 142, 142, 1)' }}
                                  />
                                </div>
                                <div className="mb-3">
                                  {/* <label htmlFor="email" className="form-label">Email</label> */}
                                  <input type="email"
                                    className="text-xl bg-transparent text-dark form-control justify-center items-start p-2.5 mt-2  rounded-lg  max-md:pr-5 focus:outline-none focus:border-violet-900"
                                    id="email"
                                    placeholder="Client Email ID"
                                    name="email"
                                    value={clientInfo.email}
                                    onChange={handleClientFieldChange}
                                    style={{ border: '2px solid rgba(142, 142, 142, 1)' }}
                                  />
                                </div>
                                <div className="mb-3">
                                  {/* <label htmlFor="contactNumber" className="form-label">Contact Number</label> */}
                                  <input type="text"
                                    style={{ border: '2px solid rgba(142, 142, 142, 1)' }}
                                    id="contactNumber"
                                    placeholder="Client Mobile NO"
                                    name="contact_number"
                                    value={clientInfo.contact_number}
                                    className="text-xl bg-transparent text-dark form-control justify-center items-start p-2.5 mt-2 capitalize rounded-lg  max-md:pr-5 focus:outline-none focus:border-violet-900"
                                    onChange={handleClientFieldChange}
                                  />
                                </div>
                                <div className="mb-3">
                                  {/* <label htmlFor="website" className="form-label">Website</label> */}
                                  <input type="url"
                                    className="text-xl bg-transparent text-dark form-control justify-center items-start p-2.5 mt-2  rounded-lg  max-md:pr-5 focus:outline-none focus:border-violet-900"
                                    style={{ border: '2px solid rgba(142, 142, 142, 1)' }}
                                    id="website"
                                    name="website"
                                    placeholder="Client Website"
                                    value={clientInfo.website}
                                    onChange={handleClientFieldChange} />
                                </div>
                              </div>
                              <div className="modal-footer">
                                <button type="button" className="btn btn-secondary" onClick={handleClientClose}>Close</button>
                                <button type="button" className="btn btn-primary" onClick={handleSaveClient}>Save Client</button>
                              </div>
                            </form>
                          </div>
                        </div>
                      </div>

                      {showClientModal && <div className="modal-backdrop fade show"></div>}
                    </div>
                  </div>



                  {/* start date and end date */}
                  <div className="flex gap-4 mt-3">

                    <div className="col-md-4 mb-3">
                      {/* <label htmlFor="firstName">Start Date</label> */}
                      {/* <input
                    type="text"
                    className="form-control"
                    id="startDate"
                    placeholder="Start Date" 
                    name="startDate"
                    value={formData.startDate}
                    onChange={handleChange}
                    required
                  /> */}
                      <DateInput
                        date={formData.startDate}
                        handleChange={handleStartDateChange}
                        // handleChange={handleChange}
                        label={"Start Date"}
                        className={"form-control"}
                        // handleBlur={handleBlur}
                        // handleFocus={handleFocus}
                        name={"startDate"}
                        required={"required"}
                        isPlaceholderVisible={isPlaceholderVisible}
                      />
                      {/* <div className="invalid-feedback">
                    Valid StartDate is required.
                  </div> */}
                    </div>

                    <div className="col-md-4 mb-3">
                      {/* <label htmlFor="firstName">End Date</label> */}
                      {/* <input
                    type="text"
                    className="form-control"
                    placeholder="End Date" 
                    id="endDate"
                    name="endDate"
                    value={formData.endDate}
                    onChange={handleChange}
                    required
                  /> */}
                      <DateInput
                        date={formData.endDate}
                        // handleChange={handleChange}
                        handleChange={handleEndDateChange}
                        className={"form-control"}
                        label={"End Date"}
                        // handleBlur={handleBlur}
                        // handleFocus={handleFocus}
                        isPlaceholderVisible={isPlaceholderVisible}
                        name={"endDate"}
                      />
                      {/* <div className="invalid-feedback">
                    Valid endDate is required.
                  </div> */}
                    </div>

                  </div>

                  {/* Project job role */}
                  <div className="col-md-10 mb-3">
                    {/* <label htmlFor="contactno">Project Job Role</label> */}
                    <input
                      type="text"
                      // className="form-control"
                      className="formal text-xl bg-transparent text-dark form-control justify-center items-start p-2.5 mt-2 capitalize rounded-lg  max-md:pr-5 focus:outline-none focus:border-violet-900"
                      style={{ border: '2px solid rgba(142, 142, 142, 1)' }}
                      id="job_role"
                      placeholder="Project Job Role"
                      name="job_role"
                      value={formData.job_role}
                      onChange={handleChange}
                      required
                    />
                    <div className="invalid-feedback">
                      Valid Job role name is required.
                    </div>
                  </div>
                  {/* client point of contract */}

                  <div className="col-md-10 mb-3">
                    {/* <label htmlFor="contactno">Client Point of Contract</label> */}
                    <input
                      type="text"
                      // className="form-control"
                      className="formal text-xl bg-transparent text-dark form-control justify-center items-start p-2.5 mt-2 capitalize rounded-lg  max-md:pr-5 focus:outline-none focus:border-violet-900"
                      style={{ border: '2px solid rgba(142, 142, 142, 1)' }}
                      id="clientPOCName"
                      placeholder="Client Point of Contact Name"
                      name="clientPOCName"
                      value={formData.clientPOCName}
                      onChange={handleChange}
                      required
                    />
                    <div className="invalid-feedback">
                      Valid Job role name is required.
                    </div>
                  </div>







                  {/* client email Id */}
                  <div className="flex gap-5">

                    <div className="col-md-10 mb-3">
                      {/* <label htmlFor="contactno">Client Email ID</label> */}
                      <input
                        type="text"
                        className="formal text-xl bg-transparent text-dark form-control justify-center items-start p-2.5 mt-2  rounded-lg  max-md:pr-5 focus:outline-none focus:border-violet-900"
                        style={{ border: '2px solid rgba(142, 142, 142, 1)' }}
                        placeholder="Client Email ID"
                        id="clientPOCEmail"
                        name="clientPOCEmail"
                        value={formData.clientPOCEmail}
                        onChange={handleChange}
                        required
                      />
                      <div className="invalid-feedback">
                        Valid Email ID name is required.
                      </div>
                    </div>

                  </div>

                  {/* client mobile pri no */}
                  <div className="flex gap-5">

                    <div className="col-md-10 mb-3">
                      {/* <label htmlFor="contactno">Client Primary No</label> */}
                      <input
                        type="text"
                        className="formal text-xl bg-transparent text-dark form-control justify-center items-start p-2.5 mt-2 capitalize rounded-lg  max-md:pr-5 focus:outline-none focus:border-violet-900"
                        style={{ border: '2px solid rgba(142, 142, 142, 1)' }}
                        id="clientPOCPriNo"
                        placeholder="Client Primary No"
                        name="clientPOCPriNo"
                        value={formData.clientPOCPriNo}
                        onChange={handleChange}
                        required
                      />
                      <div className="invalid-feedback">
                        Valid Mobile No is required.
                      </div>
                    </div>
                  </div>
                  {/* client mobile sec no */}
                  <div className="flex gap-5">

                    <div className="col-md-10 mb-3">
                      {/* <label htmlFor="contactno">Client Secondary No</label> */}
                      <input
                        type="text"
                        className="formal text-xl bg-transparent text-dark form-control justify-center items-start p-2.5 mt-2 capitalize rounded-lg  max-md:pr-5 focus:outline-none focus:border-violet-900"
                        style={{ border: '2px solid rgba(142, 142, 142, 1)' }}
                        placeholder="Client Secondary No"

                        id="clientPOCSecNo"
                        name="clientPOCSecNo"
                        value={formData.clientPOCSecNo}
                        onChange={handleChange}
                        required
                      />
                      <div className="invalid-feedback">
                        Valid Mobile No is required.
                      </div>
                    </div>
                  </div>

                  {/* Client job role */}
                  <div className="col-md-10 mb-3">
                    {/* <label htmlFor="contactno">Client POC Job Role</label> */}
                    <input
                      type="text"
                      // className="form-control"
                      className="formal text-xl bg-transparent text-dark form-control justify-center items-start p-2.5 mt-2 capitalize rounded-lg  max-md:pr-5 focus:outline-none focus:border-violet-900"
                      style={{ border: '2px solid rgba(142, 142, 142, 1)' }}
                      id="clientPOCRole"
                      placeholder="Client POC Job Role"
                      name="clientPOCRole"
                      value={formData.clientPOCRole}
                      onChange={handleChange}
                      required
                    />
                    <div className="invalid-feedback">
                      Valid Job role name is required.
                    </div>
                  </div>

                </div>
              </div>
              <button className="btn btn-primary btn-lg btn-block" type="submit">Add Project and Consultant</button>
            </form>
          </div>
        </div>)}
    </>


  );
}

export default AssignProjectForm;


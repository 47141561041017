import * as React from "react";
import { useState, useEffect } from 'react';
import axios from 'axios';
import '../assets/css/UserProfile.css';
import WeekTable from "./ConsultantDashboardWeekTable";
import dayjs from 'dayjs';
import Calender from "../assets/images/Calendar.svg"
import customParseFormat from 'dayjs/plugin/customParseFormat';
import Swal from 'sweetalert2'
import Loader from '../loader'
import Clock from "../assets/images/pepicons-print_rewind-time.svg"
import TimeDisplay from "./TimeDisplay";
import Timer from "../assets/images/game-icons_extra-time.svg"
import { useNavigate } from 'react-router-dom';
function getWeekNumber(d) {
  d = new Date(Date.UTC(d.getFullYear(), d.getMonth(), d.getDate()));
  d.setUTCDate(d.getUTCDate() + 4 - (d.getUTCDay() || 7));
  var yearStart = new Date(Date.UTC(d.getUTCFullYear(), 0, 1));
  var weekNo = Math.ceil((((d - yearStart) / 86400000) + 1) / 7);
  return weekNo;
}
function getYear(d) {
  return d.getFullYear();
}

function getDay(d) {
  return d.getDay(); // 0 (Sunday) to 6 (Saturday)
}
function ConsultantDashboardView() {
  const [weekNumber, setWeekNumber] = useState(getWeekNumber(new Date()));
  const navigate = useNavigate();
  const [userData, setUserData] = useState(null);
  const [clients, setClients] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [dropValue, setDropValue] = useState('');
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');

  const [consultantId, setConsultantId] = useState([]);
  const token = localStorage.getItem('token');
  // const id = localStorage.getItem('id');

  // const [currentTime, setCurrentTime] = useState(new Date());
  // useEffect(()=>{
  //   setCurrentTime(new Date());
  // })
  // const days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
  // const dayName = days[currentTime.getDay()];
  const [totalHours, setTotalHours] = useState(0);
  const updateTotalHours = (hours) => {
    setTotalHours(hours);
  };
  // const hours = currentTime.getHours();
  // const minutes = currentTime.getMinutes();
  // const seconds = currentTime.getSeconds()

  dayjs.extend(customParseFormat);

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [data, setData] = useState(null);

  useEffect(() => {
    const token = localStorage.getItem('token');  // Assuming token is stored in local storage
    if (!token) {
      console.error('Token not found in local storage');
      return; // Exit the effect if no token is found
    }

    const headers = {
      Authorization: `${token}`
    };

    const fetchConsultantById = async () => {
      setIsLoading(true);
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/consultant/`, { headers });
        setUserData(response.data);
        setIsLoading(false);
        setConsultantId(response.data._id);  // Assuming the response.data includes a _id
        fetchProjectsByConsultantId(response.data._id);  // Passing consultantId directly
      } catch (error) {
        console.error('Error fetching user data:', error);
      }
    };

    const fetchProjectsByConsultantId = async (consultantId) => {
      setIsLoading(true);
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/projects/consultant/`, { headers });
        const data = response.data;
        setIsLoading(false);
        const formattedClients = data.map(item => ({
          key: item._id,
          label: item.client_id.company_name,
          start_date: item.start_date,
          end_date: item.end_date
        }));
        // Populate start and end dates
        const starts = response.data[0].start_date

        const ends = response.data[0].end_date
        if (formattedClients.length > 0) {
          setClients(formattedClients);
          setDropValue(formattedClients[0].key);
          // Set dropValue to the first client's key
        }
        setStartDate(starts);
        setEndDate(ends);
      } catch (error) {
        if (error.response.status == 401) {


          alert("Session Expired Please Login");
          navigate("/");
        }
        console.error('Error fetching projects data:', error);
      }
    };

    fetchConsultantById();
  }, []);


  const handleChange = (event) => {
    const selectesClientId = event.target.value;


    setDropValue(selectesClientId);
    const selectedClient = clients.find(client => client.key === selectesClientId);
    if (selectedClient) {
      // Set the corresponding startDate and endDate from the found client
      setStartDate(selectedClient.start_date);
      setEndDate(selectedClient.end_date);
    } else {

      // Optionally clear the dates if no client is found
      setStartDate({});
      setEndDate({});
    }

  };

  const [notes, setNotes] = useState([]);
  useEffect(() => {
    if (!token) {
      console.error('Token not found in local  storage');

    }
    const headers = {
      Authorization: `${token}`
    };
    setIsLoading(true);
    axios.get(`${process.env.REACT_APP_API_URL}/consultants/notes/`, { headers })
      .then(response => {
        setIsLoading(false);
        response.data.map(item => {

          const timesheetDates = Object.keys(item.timesheet).sort();
          const firstDate = timesheetDates[0];
          const lastDate = timesheetDates[timesheetDates.length - 1];

          setNotes(prevNotes => {
            return [
              ...prevNotes,
              {
                _id: item.project_id._id,
                company_name: item.project_id.client_id.company_name,
                week: item.week,
                year: item.year,
                note: item.note,
                week: item.week,
                current_week_number: item.project_id.current_week_number,
                status: item.status,
                firstDate: firstDate,
                lastDate: lastDate,
              }
            ];
          });


        });
      })
      .catch(error => {
        console.error('Error fetching user data:', error);
      });
  }, [])
  const [submissionDue, setSubmissionDue] = useState(null);


  useEffect(() => {
    
    const fetchWeekDetails = async () => {
      const headers = {
        Authorization: `${token}`
      };
      const now = new Date();
      const currWeek = getWeekNumber(now);
      const currYear = getYear(now);
      const currentNumericDay = getDay(now);

      const fetchAPI = async (weekOffset) => {
        try {
          const response = await axios.get(`${process.env.REACT_APP_API_URL}/weekDetails/weekly/${dropValue}/${currYear}/${currWeek - weekOffset}`, { headers });
          return response.data;
        } catch (error) {
          console.error('Error fetching data:', error);
          return [];
        }
      };
  
      const week1Data = await fetchAPI(1);
      if (week1Data.length === 0) {
        let dueDate = currentNumericDay;
        const week2Data = await fetchAPI(2);
        if (week2Data.length === 0) {
          dueDate += 7;
        }
        const week3Data = await fetchAPI(3);
        if (week3Data.length === 0) {
          dueDate += 7;
        }
        setSubmissionDue(dueDate);
      }
      else {
        setSubmissionDue(0);
      }
    };
    if(dropValue){

      fetchWeekDetails();
    }
  }, [dropValue]);
  
  return (
    <>
      {isLoading ? (
        <Loader /> // You can replace this with your own loading indicator component
      ) : (
        <div>
          <div className="Div9">
            <div className="Div10">
              <div className="Div11">
                {userData && (
                  <>
                    <span style={{ fontWeight: 300 }}>Welcome</span>,
                    <span style={{ fontWeight: 600 }}>{userData.name}</span>
                  </>
                )}
              </div>
              <div className="Div12">
                <div className="Div13">Client:</div>
                <div className="Div14">
                  <select
                    id="projectSelect"
                    className="form-select"
                    value={dropValue}
                    onChange={handleChange}
                    aria-label="Vendor select example"
                  >
                    {clients?.map((item) => (
                      <option key={item?.key} value={item?.key} >{item?.label}</option>

                    ))}
                  </select>

                </div>
              </div>



            </div>

            <div className="Div16">
              <img
                loading="lazy"
                src={Calender}
                alt="Date Range"
                className="Img3"
              />
              <div className="Div17">


                <div className="Div18">

                  {startDate?.substring(0, 10)} To
                </div>

                <div className="Div19">
                  {endDate?.substring(0, 10)}
                </div>
              </div>
            </div>
          </div>
          <div className="Div35">

            <div className="Div33">
              {/* <Month/> */}

              {dropValue!=='' && <WeekTable updateTotalHours={updateTotalHours} id={dropValue} />}
            </div>
            <div className="Div34  my-2">
              <div class="max-w-2xl mx-auto w-100 ">
                <h2 class="text-3xl font-semibold text-center">Notifications</h2>
                {/* <div class="flex justify-between text-sm font-medium text-blue-600 mb-4">
            </div> */}
                <div className="bg-white shadow rounded-lg p-6 mt-4 overflow-y-auto">
                  {
                    notes && notes.map((item, index) => (
                      <div
                        key={index}
                        className={`border-l-4 pl-4 mb-6 cursor-pointer ${item.status === "Pending" ? "border-red-500" :
                          item.status === "In Review" ? "border-yellow-500" :
                            item.status === "Approved" ? "border-green-500" : "border-blue-500"
                          }`}
                        onClick={() => navigate("/consultant/consultantDashboard/addTimesheet", { state: { item } })}
                      >
                        <p className="text-sm font-extrabold">{item.company_name}</p>
                        <p className="text-sm font-bold">{item.firstDate} to {item.lastDate}</p>
                        <p className="font-semibold">{item.note}</p>
                        {/* <a href="#" className="text-blue-600 hover:text-blue-700">Read More</a> */}
                      </div>
                    ))
                  }
                </div>

              </div>

            </div>
          </div>
          <div className="Div20">
            <div className="Div21">
              <div className="Column3">
                <div className="Div22">
                  <div className="Div23">
                    <div className="Column4">
                      <div className="Div24">
                        <div className="Div25">
                          <img
                            loading="lazy"
                            src={Clock}
                            alt="Clock Icon"
                            className="Img5"
                          />
                          <div className="Div26 text-md">{totalHours} Hrs</div>
                        </div>
                        <div className="Div27">Recorded this month</div>
                      </div>
                    </div>
                    <div className="Column5">
                      <div className="Div28">
                        <div className="Div29">Submission due</div>
                        <div className="Div30">
                          <img
                            loading="lazy"
                            src={Timer}
                            alt="Timer Icon"
                            className="Img6"
                          />
                          <div className="Div31">{submissionDue !== null ? `${submissionDue} Days` : 'Loading...'}</div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="Column6">
                {/* <div className="Div32">{currentTime.toDateString()} ({dayName}),{hours < 10 ? '0' + hours : hours}:{minutes < 10 ? '0' + minutes : minutes}:{seconds < 10 ? '0' + seconds : seconds}</div>
          </div> */}
                <TimeDisplay />
              </div>
            </div>
          </div>
        </div>)}
    </>
  )

}
export default ConsultantDashboardView;
import './App.css';
import { BrowserRouter as Router, Routes, Route,Navigate  } from 'react-router-dom';
import AdminDashboard from './pages/adminPages/AdminDashboard';
import ConsultantDashboard from './pages/consultantPages/ConsultantDashboard';
import LoginPage from './pages/loginPage';
import ConsultantAuth from "./pages/AuthTokenForm";
import ConsultantNewPassword from './pages/ConsultantNewPassword';

// import 'bootstrap/dist/css/bootstrap.min.css';

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<LoginPage />} />
        <Route path="/admin" element={<LoginPage />} />
        <Route path="/admin/adminDashboard" element={<AdminDashboard />} />
        <Route path="/admin/adminDashboard/viewTimesheets" element={<AdminDashboard />} />
        <Route path="/admin/adminDashboard/viewProjectInfo" element={<AdminDashboard />} />
        <Route path="/admin/adminDashboard/addConsultant" element={<AdminDashboard />} />
        <Route path="/admin/adminDashboard/assignProject" element={<AdminDashboard />} />


        <Route path="/consultant/consultantDashboard" element={<ConsultantDashboard />} />
        <Route path="/consultant/consultantDashboard/viewPreviousTimesheets" element={<ConsultantDashboard />} />
        <Route path="/consultant/consultantDashboard/addTimesheet" element={<ConsultantDashboard />} />
        <Route path="/consultant/consultantDashboard/personalInfo" element={<ConsultantDashboard />} />
        <Route path="/consultant/twoFA" element={<PrivateRoute />} />
        <Route path="/consultant/consultantNewPassword" element={<ConsultantNewPassword />} />
        {/* <Route path="/admin/adminDashboard/changeConsultantInfo" element={<ChangeConsultantInfo />} /> */}
        <Route path="/admin/adminDashboard/changeConsultantInfo" element={<AdminDashboard />} />


      </Routes>
    </Router>
  );
}
function PrivateRoute() {
  const isAuthenticated = sessionStorage.getItem('canAccessTwoFA');

  return isAuthenticated ? <ConsultantAuth /> : <Navigate to="/" />;
}

export default App;

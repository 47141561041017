import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import dropdown from "../assets/images/drop-down.svg"
import search from "../assets/images/search.svg"
import eye_icon from "../assets/images/carbon_view.svg";
import Loader from '../loader';
import { parseISO, format } from 'date-fns';

function AdminDashboardView() {
  const [projects, setProjects] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [projectData, setProjectData] = useState({}); // Changed: Initialize as an object
  const [searchProject, setSearchProject] = useState("");
  const token = localStorage.getItem("token");
  const navigate = useNavigate();

  useEffect(() => {
    const fetchProjects = async () => {
      setIsLoading(true);
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/projects`, {
          headers: {
            Authorization: `${token}`
          }
        });
        setProjects(response.data);
      } catch (err) {
        setError(err);
        if (err.response && err.response.status === 401) {
          console.error('Failed to fetch week data:');
          alert("Session Expired Please Login");
          navigate("/admin");
        }
      } finally {
        setIsLoading(false);
        setLoading(false);
      }
    };
    fetchProjects();
  }, [token, navigate]);

  const handleonClick = async (id) => {
    const currentDate = new Date();
    const currentYear = currentDate.getFullYear();
    const currentMonth = (currentDate.getMonth() + 1).toString().padStart(2, '0');
    setIsLoading(true);
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/weekDetails/range/${id}/${currentMonth-1==0?currentYear-1:currentYear}/${currentMonth-1==0?52:currentMonth-1}/${currentYear}/${currentMonth}`, {
        headers: {
          Authorization: `${token}`
        }
      });
      const sortedData = response.data.timesheets.sort((a, b) => a.week - b.week);
      setProjectData(prevData => ({ // Changed: Update projectData object with new data
        ...prevData,
        [id]: sortedData
      }));
    } catch (err) {
      setError(err);
    } finally {
      setIsLoading(false);
      setLoading(false);
    }
  };

  const handleSearchChange = (e) => {
    setSearchProject(e.target.value);
  };

  const filteredProjects = projects.filter((project) => {
    const companyName = project.client_id?.company_name || '';
    return companyName.toLowerCase().includes(searchProject.toLowerCase());
  });

  const handleViewMore = (project) => {
    navigate('/admin/adminDashboard/viewTimesheets', {
      state: {
        projectId: project?._id,
        projectName: project.client_id?.company_name,
      },
    });
  };

  const formatDate = (timesheet)=>{
    const dates=Object.keys(timesheet);
    const startdate=format(parseISO(dates[0]),"dd MMM yyyy");
    const endDate = format(parseISO(dates[dates.length - 1]),"dd MMM yyyy");

    console.log(startdate);
    console.log(endDate);
    return startdate +" to "+ endDate;
  }
  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <div className="flex flex-col bg-purple-50">
          <div className="flex flex-col self-center mt-7 max-w-full w-[902px]">
            <div className="flex gap-2.5 justify-between text-2xl text-black max-md:flex-wrap">
              <div className="flex flex-row justify-center items-center rounded-lg border border-violet-900 border-solid text-black text-opacity-40 w-[13rem]">
                <img
                  loading="lazy"
                  src={search}
                  className="shrink-0 my-auto w-6 aspect-square pt-1"
                />
                <input
                  type="text"
                  className="text-xl text-slate-400 border-0 w-[10rem] bg-purple-50 focus:outline-none focus:ring-0"
                  value={searchProject}
                  onChange={handleSearchChange}
                  placeholder="Search by project"
                />
              </div>

              <div className="justify-center px-4 py-2.5 bg-purple-50 rounded shadow-lg text-xl">
                Projects: <span className="font-bold">{projects.length}</span>
              </div>
              <div className="justify-center px-4 py-2.5 bg-purple-50 rounded shadow-lg text-xl">
                <button onClick={() => navigate('/admin/adminDashboard/changeConsultantInfo')}>Consultant Edit Info</button>
              </div>
            </div>


            <table className='mt-2'>
              <thead >
                <th className='col-2'>Client name</th>
                <th className='col-3'>Consultant Name</th>
                <th className='col-1 text-start'>$/Hr</th>
                <th className='col-2'>Total hours</th>
                <th className='col-2 text-center'>Status</th>
                <th className='col-1 text-end'>More Info.</th>
              </thead>
            </table>
            {filteredProjects.map((project, index) => (
              <div className="accordion my-1" id="accordionExample" key={project._id}>
                <div className="accordion-item">
                  <h2 className="accordion-header" id={`heading${index}`}>
                    <button
                      className="accordion-button-icon collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target={`#collapse${index}`}
                      aria-expanded="false"
                      aria-controls={`collapse${index}`}
                      style={{ width: "-webkit-fill-available" }}
                      onClick={() => handleonClick(project._id)}
                    >
                      <table className="w-full">

                        <tbody>
                          <tr className="flex z-10 gap-2 justify-between items-center  w-full text-2xl leading-7 text-black capitalize bg-fuchsia-100 max-md:flex-wrap max-md:max-w-full">
                            <td className="col-2   self-stretch my-auto text-lg text-start">{project.client_id?.company_name}</td>
                            <td className="col-3  self-stretch my-auto text-lg text-start text-wrap">{project.consultant_id?.name}</td>
                            <td className="col-1 self-stretch my-auto text-lg text-start text-nowrap">$ {project.rate}</td>
                            <td className="col-2 self-stretch my-auto text-lg text-start">{project.total_hrs ? project.total_hrs : 0} Hours</td>
                            <td className={` col-2 justify-center self-stretch px-2.5 py-1.5 my-auto text-lg leading-7 whitespace-nowrap ${project.status ? "bg-green-300" : "bg-red-300"} rounded border border-red-800 border-solid`}>
                              {project.status ? "Running" : "Finished"}
                            </td>
                            <td className='col-1'>
                              <img
                                loading="lazy"
                                src={dropdown}
                                className="shrink-0 self-stretch w-12 aspect-square"
                              />
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </button>
                  </h2>
                  <div id={`collapse${index}`} className="accordion-collapse collapse" data-bs-parent="#accordionExample">
                    <div className="accordion-body visible p-0 m-0">
                      {isLoading && project._id in projectData ? (
                        <Loader />
                      ) : (
                        <table className="table m-0">
                          <tbody>
                            {(projectData[project._id] || []).map((data, dataIndex) => (
                              <tr key={dataIndex}>
                                {console.log(data.timesheet)}
                                <td scope="row" style={{maxWidth:"160px"}}>{formatDate(data.timesheet)}</td>

                                {Object.keys(data.timesheet).map((date) => (
                                  <td key={date} title={date}>{data.timesheet[date]}</td>
                                ))}
                                <td title={"Total Hours"}>{data?.total_hrs}</td>
                                <td>
                                  <a href={data.image_url} target="_blank" rel="noopener noreferrer">
                                    <img src={eye_icon} alt="" className="w-[25px] h-[25px]" />
                                  </a>
                                </td>
                              </tr>
                            ))}
                            {(!projectData[project._id] || projectData[project._id].length === 0) && (
                              <tr>
                                <td colSpan="8" className="text-center">No data found</td>
                              </tr>
                            )}
                          </tbody>
                        </table>
                      )}
                      <div className="flex justify-end">
                        {(projectData[project._id] || []).length === 0 ? null : (
                          <button className='px-2.5 py-1.5 text-lg leading-7 whitespace-nowrap rounded border border-red-800 border-solid bg-[#4c1d95] text-white' onClick={() => handleViewMore(project)}>
                            View more
                          </button>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      )}
    </>
  );
}

export default AdminDashboardView;

import React, { useEffect, useRef, useState } from 'react';
import DropdownImage from "../assets/images/drop-down.svg"
const Dropdown = ({ options, onSelect,label,addnew,setInfo,Info,handleSubmit,addnewACF,onChangeACF,onSelectACF,index,addnewACF2 }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [isTouched, setIsTouched] = useState(false);

  const [selectedOption, setSelectedOption] = useState(null);
  const handleOptionSelect = (option) => {
  
    setSelectedOption(option);
    onSelect(option);
    setIsOpen(false);
  };
  const handleBlur = () => {
    setIsTouched(true);
  };
  const ACF=(option)=>{

    onSelectACF(index,option.id);
    setSelectedOption(option)
    setIsOpen(false);
  }
  const dropdownRef = useRef(null);
  const [showModal,setShowModal]=useState(false);
  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    document.addEventListener('mousedown', handleOutsideClick);

    return () => {
      document.removeEventListener('mousedown', handleOutsideClick);
    };
  }, []);


  return (
    <div className="relative w-100"  ref={dropdownRef}
    onBlur={handleBlur}
    >
      <button
        className="flex items-center px-3 justify-between w-75 1px-3 py-1.5 text-2xl text-zinc-500 bg-transparent rounded-md focus:outline-none focus:border-violet-900"
        type='button'
        onClick={() => setIsOpen(!isOpen)}
      >
        <div className="my-auto fs-12">{selectedOption ? <p className='text-nowrap'>{selectedOption.label}</p> : label}</div>
        <img
          src={DropdownImage}
          alt="Dropdown Arrow"
          className={`shrink-0 w-10 aspect-square transform ${isOpen ? 'rotate-180' : ''}`}
        />
      </button>
      {/* {
        isTouched && selectedOption==null && 
        <div>
          {label} is required.
        </div>
      } */}
         
      {isOpen && (
        <div className="absolute overflow-y-scroll  h-200 max-h-[400px] top-full w-75 left-0 z-10 mt-1 bg-white  rounded-md shadow-md"
        >

          <button
          className="block w-100 px-4 py-2 text-lg text-disable hover:bg-gray-100 focus:outline-none focus:bg-gray-100"
          // disabled
          type='button'
          onClick={()=>handleOptionSelect}
          
          >
            {label}
          </button>
          {options.map((option) => (
            <>
            {addnewACF2==true ?<button
              key={option.value}
              className="block w-100 px-4 py-2 text-lg text-gray-800 hover:bg-gray-100 focus:outline-none focus:bg-gray-100"
              onClick={() => ACF(option)}
              type='button'
              aria-required="true"

            >
              {option.label}
            </button>:<button
              key={option.value}
              className="block w-100 px-4 py-2 text-lg text-gray-800 hover:bg-gray-100 focus:outline-none focus:bg-gray-100"
              onClick={() => handleOptionSelect(option)}
              type='button'
              aria-required="true"

            >
              {option.label}
            </button>}
            </>
          ))}
          {addnew==true && <button
              // key={option.value}
              type='button'
              className="block w-100 px-4 py-2 text-lg text-gray-800 hover:bg-gray-100 focus:outline-none focus:bg-gray-100"
              onClick={() =>{setShowModal(true);setIsOpen(false)}}
            >
              Add new
            </button>
          }
          {addnewACF==true && <button
              // key={option.value}
              type='button'
              className="block w-100 px-4 py-2 text-lg text-gray-800 hover:bg-gray-100 focus:outline-none focus:bg-gray-100"
              onClick={() =>{setIsOpen(false);onChangeACF(true)}}
            >
              Add new
            </button>
          }
          {addnewACF2==true && <button
              // key={option.value}
              type='button'
              className="block w-100 px-4 py-2 text-lg text-gray-800 hover:bg-gray-100 focus:outline-none focus:bg-gray-100"
              onClick={() =>{setIsOpen(false);onSelectACF(index,'new');}}
            >
              Add new
            </button>
          }
          
        </div>
        
      )}
      {showModal && (
                        <div className="modal show" style={{ display: 'block' }}>
                          <div className="modal-dialog">
                            <div className="modal-content">
                              <div className="modal-header">
                                <h5 className="modal-title">Add New {label.split(' ')[1]}</h5>
                                <button type="button" className="btn-close" onClick={() => setShowModal(false)}></button>
                              </div>
                              <div className="modal-body">
                                <input type="text" className="form-control mb-3" placeholder="Company Name" name="company_name" value={Info.company_name} onChange={e => setInfo({ ...Info, company_name: e.target.value })} />
                                <input type="email" className="form-control mb-3" placeholder="Email" name="email" value={Info.email} onChange={e => setInfo({ ...Info, email: e.target.value })} />
                                <input type="text" className="form-control mb-3" placeholder="Contact Number" name="contact_number" value={Info.contact_number} onChange={e => setInfo({ ...Info, contact_number: e.target.value })} />
                                <input type="url" className="form-control mb-3" placeholder="Website" name="website" value={Info.website} onChange={e => setInfo({ ...Info, website: e.target.value })} />
                              </div>
                              <div className="modal-footer">
                                <button type="button" className="btn btn-secondary" onClick={() => setShowModal(false)}>Close</button>
                                <button type="button" className="btn btn-primary" 
                                onClick={handleSubmit}
                                >Save {label.split(' ')[1]}</button>
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
    </div>
  );
};

export default Dropdown;

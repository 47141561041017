
import React, { createContext, useState, useContext } from 'react';

const AuthContext = createContext(null);

export const AuthProvider = ({ children }) => {
  const [authInfo, setAuthInfo] = useState({
    email: '',
    password: ''  
  });

  const setAuth = ({ email, password }) => {
    setAuthInfo({ email, password });
  };

  return (
    <AuthContext.Provider value={{ authInfo, setAuth }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => useContext(AuthContext);

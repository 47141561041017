import React, { useEffect, useState, useRef } from 'react';
import axios from 'axios';
import Dropdown from './DropDown';
import Swal from 'sweetalert2';
import { useNavigate } from 'react-router-dom';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

function AddConsultantForm() {
  const navigate = useNavigate();
  const startRef = useRef('');
  const [city, setCity] = useState('Select State');
  const endRef = useRef('')
  const [usStates, setUsStates] = useState([
    "Alabama",
    "Alaska",
    "Arizona",
    "Arkansas",
    "California",
    "Colorado",
    "Connecticut",
    "Delaware",
    "Florida",
    "Georgia",
    "Hawaii",
    "Idaho",
    "Illinois",
    "Indiana",
    "Iowa",
    "Kansas",
    "Kentucky",
    "Louisiana",
    "Maine",
    "Maryland",
    "Massachusetts",
    "Michigan",
    "Minnesota",
    "Mississippi",
    "Missouri",
    "Montana",
    "Nebraska",
    "Nevada",
    "New Hampshire",
    "New Jersey",
    "New Mexico",
    "New York",
    "North Carolina",
    "North Dakota",
    "Ohio",
    "Oklahoma",
    "Oregon",
    "Pennsylvania",
    "Rhode Island",
    "South Carolina",
    "South Dakota",
    "Tennessee",
    "Texas",
    "Utah",
    "Vermont",
    "Virginia",
    "Washington",
    "West Virginia",
    "Wisconsin",
    "Wyoming"
  ]);

  const [formData, setFormData] = useState({
    fullName: '',
    email: '',
    contactno: '',
    ssn: '',
    hname: '',
    gender: '',
    visa: '',
    area: 'USA',
    city: '',
    startDate: '',
    endDate: '',
    job_role: '',
    clientPoc: '',
    cleintEmail: '',
    cleintMobNo: '',
    passwordd: '',
    clientPOCName: '',
    clientPOCRole: '',
    clientPOCPriNo: '',
    clientPOCSecNo: '',
    clientPOCEmail: '',
    duration: '',
    rate: '',
    pay_type: ''
  });
  const [isPlaceholderVisible, setIsPlaceholderVisible] = useState(true);
  const [payType, setPayType] = useState('');
  const paytypes = [
    { id: 1, value: "FullTime" },
    { id: 2, value: "C2C" },
    { id: 3, value: "W2" },
  ]

  const handlePayTypes = (event) => {

    setPayType(event.value)

  }
  const handleChange = (event) => {
    const { name, value } = event.target;
 
    setFormData(prevState => ({
      ...prevState,
      [name]: value,
      // startDate:value,
      // endDate:value
      // startDate:inputRef.current.value,
      // endDate:endRef.current.value
    }));

  }
 
  // const handleDateChange = () => {
  //   handleChange({
  //     target: {
  //       value: inputRef.current.value,
  //     },
  //   });
  // };
  const handleStartDateChange = (date) => {
   

    const dateObject = new Date(date);
    const year = dateObject.getFullYear();
    const month = dateObject.getMonth() + 1;
    const day = dateObject.getDate();
   
    const formattedDate = `${year}/${month < 10 ? '0' : ''}${month}/${day}`;
    
    setStartDate(formattedDate)
    // const formattedDate = `${day < 10 ? '0' : ''}${day}/${month < 10 ? '0' : ''}${month}/${year}`;
    setFormData((prevData) => ({
      ...prevData,
      startDate: formattedDate,
      endDate: new Date(formattedDate) > new Date(prevData.endDate) ? formattedDate : prevData.endDate,
    }));

  };


  const handleEndDateChange = (date) => {
    const dateObject = new Date(date);
    const year = dateObject.getFullYear();
    const month = dateObject.getMonth() + 1;
    const day = dateObject.getDate();
    const formattedDate = `${year}/${month < 10 ? '0' : ''}${month}/${day < 10 ? '0' : ''}${day}`;
    setEndDate(formattedDate)
    setFormData((prevData) => ({
      ...prevData,
      endDate: formattedDate,
      startDate: new Date(formattedDate) < new Date(prevData.startDate) ? formattedDate : prevData.startDate,
    }));
  };

  const DateInput = ({ date,required, isPlaceholderVisible, dateRef, handleBlur, handleChange, handleFocus, label, className, name }) => (
    <DatePicker
      selected={date}
      onChange={handleChange}
      onBlur={handleBlur}
      onFocus={handleFocus}
      placeholderText={`${label} 📅`}
      className={className}
      ref={dateRef}
      required={required}
    />
  );



  const [vendorsPOC, setVendorsPOC] = useState([
    { company_id: '', job_role: '', email_address: '', contact_number: '', secondary_number: '', name: '' }
  ]);
  const [showNewVendorModal, setShowNewVendorModal] = useState(false);
  const [showClientModal, setShowClientModal] = useState(false);
  const [clientSelectValue, setclientSelectValue] = useState('');
  const [clients, setClients] = useState([]);
  const [vendors, setVendors] = useState([]);



  const [selectedClient, setSelectedClient] = useState([]);
  const [vendorsId, setVendorsId] = useState([]);
  const [companyPOCId, setCompanyPOCId] = useState([]);
  const [consultantId, setConsultantId] = useState();


  // Client Dropdown
  useEffect(() => {

    // Conditionally fetch clients if the array is empty to prevent infinite loops
    const token = localStorage.getItem('token');
    if (!token) {
      console.error('Token not found in local storage');
      return;
    }
    axios.get(`${process.env.REACT_APP_API_URL}/companies/clients`, {
      headers: {
        'Authorization': token,
      }
    })
      .then(response => {
        const newClients = response.data.map(item => ({
          id: item._id,
          name: item.company_name
        }));
        // Update state only if the fetched clients are different from the current state
        // This checks if the length is different or content is different (simplified check)
        if (newClients.length !== clients.length || newClients.some((client, index) => client.id !== clients[index]?.id)) {
          setClients(newClients);
        }
      })
      .catch(error => {
        if (error.response.status == 401) {
          console.error('Failed to fetch week data:');

          alert("Session Expired Please Login");
          navigate("/admin");
        }
        console.error('Error fetching clients:', error);
      });

  }, []);

  //Vendors Dropdwon
  useEffect(() => {
    const token = localStorage.getItem('token');
    if (!token) {
      console.error('Token not found in local storage');
      return;
    }
    axios.get(`${process.env.REACT_APP_API_URL}/companies/vendors`, {
      headers: {
        'Authorization': `${token}`,
      }
    })
      .then(response => {
   
        const newVendors = response.data.map((item) => ({
          id: item._id,
          name: item.company_name
        }));
        setVendors(newVendors);
      })
      .catch(error => {

      });
  }, []);


  // Dropdown Value change for Vendor
  const [vendorInfo, setVendorInfo] = useState({
    company_name: '',
    company_type: 1,
    email: '',
    contact_number: '',
    website: '',
    status: true
  })

  // Dropdown Value Change for client
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();


  const handleVendorSelectionChange = (index, event) => {
    const companyId = event;

 
    if (companyId === 'new') {
      // Set up for a new vendor
      setVendorInfo({
        company_name: '',
        company_type: 1,
        email: '',
        contact_number: '',
        website: '',
        status: true
      });
      setShowNewVendorModal(true);
    } else {
      // Update existing vendor details in the form
      const selectedVendor = vendors.find(vendor => vendor.id === companyId);
      setVendorsPOC(prevVendorsPOC => prevVendorsPOC.map((vendor, i) =>
        i === index ? {
          ...vendor,
          company_id: companyId,
          // name: selectedVendor ? selectedVendor.name : '',
          // email_address: selectedVendor ? selectedVendor.email : '',
          // contact_number: selectedVendor ? selectedVendor.contact_number : ''
        } : vendor
      ));
    }
  };

  const handleVendorInputChange = (index, event) => {
    const { name, value } = event.target;
    setVendorsPOC(prevVendorsPOC => prevVendorsPOC.map((vendor, i) =>
      i === index ? { ...vendor, [name]: value } : vendor
    ));
  };

  const addVendor = () => {
    setVendorsPOC(prevVendorsPOC => [
      ...prevVendorsPOC,
      {
        company_id: '',
        name: '',
        job_role: '',
        email_address: '',
        contact_number: '',
        secondary_number: '',
      }
    ]);
  };
  const removeVendor = index => {
    setVendorsPOC(prevVendorsPOC => prevVendorsPOC.filter((_, i) => i !== index));
  };
  const submitNewVendor = () => {
   
    const token = localStorage.getItem('token');
    if (!token) {
      console.error('Token not found in local storage');
      return;
    }

    axios.post(`${process.env.REACT_APP_API_URL}/companies/`, vendorInfo, {
      headers: {
        'Authorization': `${token}`
      }
    }).then(response => {
      const newVendor = response.data;
      setVendors(prevVendors => [...prevVendors, { id: newVendor._id, name: newVendor.company_name }]);
      setVendorsPOC(prevVendorsPOC => prevVendorsPOC.map((vendor, idx) =>
        idx === prevVendorsPOC.length - 1 ? {
          ...vendor,
          company_id: newVendor._id, // Set the company_id to the new vendor's ID
          // name: newVendor.company_name,
          // email_address: newVendor.email,
          // contact_number: newVendor.contact_number
        } : vendor
      ));
      setShowNewVendorModal(false); // Close the modal on save
   
    }).catch(error => {
      console.error('Failed to save new vendor:', error);
    });
  };


  const [clientInfo, setClientInfo] = useState({
    name: '',
    company_type: 0,
    email: '',
    contact_number: '',
    website: '',
    status: true
  })

  const handleCityClick = (event) => {
    // event.preventDefault(); // Prevent the default anchor behavior
    const city = event.value;

    // const city = event.target.getAttribute('name');
    setCity(city)
    setFormData(prevState => ({
      ...prevState,
      city: city
    }));
  };


  const handleClientSelectChange = (event) => {
    const selectedValue = event;

    setclientSelectValue(selectedValue);
    if (selectedValue === 'new') {

      setShowClientModal(true);  // Show the client modal
    } else {
      // Optionally, find the client object if more data is needed
      const selectedClient = clients.find(client => client.id === selectedValue.id);

      setSelectedClient(selectedClient.id);
      setclientSelectValue(selectedClient);
    

    }
  };

  const handleClientClose = () => {
    setShowClientModal(false);
    setclientSelectValue('');

  };

  const handleClientFieldChange = (event) => {
    const { name, value } = event.target;
    setClientInfo(prevInfo => ({
      ...prevInfo,
      [name]: value
    }));
 
    // setClientName(event.target.value);
  };


  const handleSaveClient = (event) => {
    event.preventDefault();

    const token = localStorage.getItem('token');
    if (!token) {
      console.error('Token not found in local storage');
      return;
    }

    const headers = {
      Authorization: `${token}`
    }
    axios.post(`${process.env.REACT_APP_API_URL}/companies/`, clientInfo, { headers })
      .then(response => {
        const newClient = response.data;
        if (newClient && newClient._id) {

          setClients(prevClients => [
            ...prevClients.filter(client => client.id !== 'new'),
            { id: newClient._id, name: newClient.company_name }
          ]);
       
          setSelectedClient(newClient._id)
          // setCompanyPOCId(prevIds => [
          //   ...prevIds,
          //   newClient._id  // Append new client ID to vendor POC IDs
          // ]);
      
          setclientSelectValue(newClient._id);

          setShowClientModal(false);
        } else {
          console.error('New client data is invalid:', newClient);
        }
      })
      .catch(error => {
        console.error('Failed to save client:', error);
        alert('Failed to save client');
      });
  };



  const handleCreateConsultant = async (personalData) => {
    const token = localStorage.getItem('token');
    if (!token) {
      console.error('Token not found in local storage');
      return;
    }

    const headers = {
      Authorization: `${token}`
    }
    return axios.post(`${process.env.REACT_APP_API_URL}/consultants/`, personalData, { headers }).then(response => {
      setConsultantId(response.data._id);
    });

  };

  const handleVendorSubmit = async (vendorsPOC) => {
    const token = localStorage.getItem('token');
    if (!token) {
      console.error('Token not found in local storage');
      return;
    }

    const headers = {
      Authorization: `${token}`
    }

    const promises = vendorsPOC.map(vendor => {
      return axios.post(`${process.env.REACT_APP_API_URL}/companypocs/`, vendor, { headers }).then(response => {
        setVendorsId(prev => {
          const vendorId = vendor.company_id;
          if (!prev.includes(vendorId)) {
            return [...prev, vendorId];
          }
          return prev;
        });
        setCompanyPOCId(prev => {
          const vendorPOCId = response.data._id;
          if (!prev.includes(vendorPOCId)) {
            return [...prev, vendorPOCId];
          }
          return prev;
        });
      });
    });

    return Promise.all(promises);
  };


  const handleClientSubmit = async (clientData) => {
    const token = localStorage.getItem('token');
    if (!token) {
      console.error('Token not found in local storage');
      return;
    }

    const headers = {
      Authorization: `${token}` // Typically Bearer authentication scheme is used
    };

    try {
      const response = await axios.post(`${process.env.REACT_APP_API_URL}/companypocs/`, clientData, {
        headers
      });


      // Update companyPOCId state
      setCompanyPOCId(prev => {
        const clientPOCId = response.data._id;
        if (!prev.includes(clientPOCId)) {
          return [...prev, clientPOCId];
        }
        return prev;
      });
    } catch (error) {
      if (error.response.status == 401) {


        alert("Session Expired Please Login");
        navigate("/");
      }


      console.error("Failed to submit client data:", error.response ? error.response.data : error.message);
    }
  };

  const handleCreateProject = () => {
    
    const projectData = {
      client_id: selectedClient,
      vendor_ids: vendorsId,
      start_date: startDate,
      end_date: endDate,
      rate: formData.rate,
      rate_type: payType,
      job_role: formData.job_role,
      company_poc_ids: companyPOCId,
      consultant_id: consultantId,
      duration: formData.duration,
      total_hrs: 0,
      status: true
    };

    const token = localStorage.getItem('token');
    if (!token) {
      console.error('Token not found in local storage');
      return;
    }

    const headers = {
      Authorization: `${token}`
    }

    axios.post(`${process.env.REACT_APP_API_URL}/projects`, projectData, { headers }).then(response => {
  
      Swal.fire({

        title: 'Project has been created successfully!',
        icon: 'success',
        showConfirmButton: false,
        timer: 1500
      });
    }).catch(error => {
      console.error("Error creating project:", error);
      Swal.fire({
        title: 'Error!',
        text: 'Failed to create the project.',
        icon: 'error'
      });
    });

  };

  const [submitSuccess, setSubmitSuccess] = useState(false);
  const [isSubmit,setIsSubmit]=useState(false);

  const handleSubmit = async (event) => {
    event.preventDefault();

    setIsSubmit(true);
    setFormData((prevData) => ({
      ...prevData,
      startDate: startRef.current.value,
      endDate: endRef.current.value
    }));

    if (!event.currentTarget.checkValidity()) {
      event.stopPropagation();
      event.currentTarget.classList.add('was-validated');
      return;
    }

    // Assuming formData has the needed info for client and vendors
    const clientData = {
      name: formData.clientPOCName,
      company_id: clientSelectValue.id,
      email_address: formData.clientPOCEmail,
      contact_number: formData.clientPOCPriNo,
      secondary_number: formData.clientPOCSecNo,
      job_role: formData.clientPOCRole,
    };
    const unwantedChars = [
      '\u202A', // Left-to-Right Embedding
      '\u200E', // Left-to-Right Mark
      '\u200F', // Right-to-Left Mark
      '\u202C', // Pop Directional Formatting
      '\u00A0', // Non-breaking Space
      '\u200B', // Zero Width Space
      '\u200C', // Zero Width Non-Joiner
      '\u200D', // Zero Width Joiner
      '\uFEFF', // Byte Order Mark
      ...Array.from({ length: 32 }, (_, i) => String.fromCharCode(i)),
      '\u007F'  // Delete
    ]
    const unwantedRegex = new RegExp(`[${unwantedChars.join('')}]`, 'g');

    const personalData = {
      name: formData.fullName,
      email: formData.email,
      contact_number: formData.contactno.replace(unwantedRegex, ''),
      gender: formData.gender[0]?.toUpperCase() + formData.gender.substring(1),
      ssn: formData.ssn,
      status: true,
      visa: formData.visa,
      location: formData.city+", "+ formData.area,
      residential_address: formData.hname ,
      password: formData.passwordd
    };
  
    try {
      // Simultaneous async operations to ensure all data is ready
      await Promise.all([
        handleCreateConsultant(personalData),
        vendorsPOC ? handleVendorSubmit(vendorsPOC) : Promise.resolve(),
        handleClientSubmit(clientData)
      ]);
      setSubmitSuccess(true);
    }
    catch (error) {
      setSubmitSuccess(true);
      if (error.response.status == 401) {


        alert("Session Expired Please Login");
        navigate("/");
      }
      if (error.response.status == 400) {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: error.response.data.error,

        });
      }

    }
  };

  useEffect(() => {
    if (submitSuccess && companyPOCId.length > 0) {
  
      handleCreateProject();
    }
    setSubmitSuccess(false);
  }, [submitSuccess]);

  // Manage placeholder visibility on focus
  const handleFocus = (event) => {

    setIsPlaceholderVisible(false);
    // if (handleFocus) handleFocus(event);

  };
  // Manage placeholder visibility on blur
  const handleBlur = () => {
    // startDate=
    // setFormData({startDate:inputRef.current.value,endDate:endRef.current.value})
    // setFormData({
    //   formData.startDate: inputRef.current.value
    // })
    // inputRef.current.value,
    // endDate=endRef.current.value

    // if (formData.startDate=="" && formData.endDate=='') {
    //   setIsPlaceholderVisible(true);  
    // }
  };

  return (
    <div className="container" style={{ width: '1000px', paddingLeft: '100px' }}>
      <div className="row">
        <form className="needs-validation" onSubmit={handleSubmit} noValidate>
          <div className="row">


            {/* First Column*/}
            <div className="col-md-6 col-sm-12 mb-3 mt-3">
              {/* <h2 className="mb-3 fw-bold">Consultant Information</h2> */}

              {/* fullname */}
              <div className="col-md-8 col-sm-12 mb-3">
                {/* <label htmlFor="firstName">Full name</label> */}
                <input
                  type="text"
                  className="formal text-xl bg-transparent text-dark form-control justify-center items-start p-2.5 mt-3 capitalize rounded-lg  max-md:pr-5 focus:outline-none focus:border-violet-900"
                  style={{ border: '2px solid rgba(142, 142, 142, 1)' }}
                  id="fullName"
                  placeholder="Full Name"
                  name="fullName"
                  value={formData.fullName}
                  onChange={handleChange}
                  required
                />
                <div className="invalid-feedback">
                  Valid full name is required.
                </div>
              </div>
              {/* contact no */}
              <div className="col-md-8 col-sm-12 mb-3">
                {/* <label htmlFor="contactno">Contact No.</label> */}
                <input
                  type="tel"
                  // className="form-control"
                  className="formal text-xl bg-transparent text-dark form-control justify-center items-start p-2.5 mt-3 capitalize rounded-lg  max-md:pr-5 focus:outline-none focus:border-violet-900"
                  style={{ border: '2px solid rgba(142, 142, 142, 1)' }}
                  placeholder="Contact No."
                  id="contactno"
                  name="contactno"
                  value={formData.contactno}
                  onChange={handleChange}
                  required
                />
                <div className="invalid-feedback">
                  Valid contact no is required.
                </div>
              </div>
              {/* Passowrd  */}
              <div className="col-md-8 col-sm-12 mb-3">
                {/* <label htmlFor="passwordd">passwordd</label> */}
                <input
                  type="password"
                  className="formal text-xl bg-transparent text-dark form-control justify-center items-start p-2.5 mt-3 capitalize rounded-lg  max-md:pr-5 focus:outline-none focus:border-violet-900"
                  style={{ border: '2px solid rgba(142, 142, 142, 1)' }}
                  // className="form-control"
                  placeholder="Password"
                  id="passwordd"
                  name="passwordd"
                  value={formData.passwordd}
                  onChange={handleChange}
                  required
                />
                <div className="invalid-feedback">
                  Valid email is required.
                </div>
              </div>

              {/* housename */}
              <div className="col-md-8 col-sm-12 mb-3">
                {/* <label htmlFor="firstName">House name</label> */}
                <input
                  type="text"
                  className="formal text-xl bg-transparent text-dark form-control justify-center items-start p-2.5 mt-3 capitalize rounded-lg  max-md:pr-5 focus:outline-none focus:border-violet-900"
                  style={{ border: '2px solid rgba(142, 142, 142, 1)' }}
                  // className="form-control"
                  placeholder="Residential Address"
                  id="hname"
                  name="hname"
                  value={formData.hname}
                  onChange={handleChange}
                  required
                />
                <div className="invalid-feedback">
                  Valid House name is required.
                </div>
              </div>
              {/* city name */}
              <div className="col-md-10 col-sm-12 mb-3">
                {/* <label htmlFor="contactno">City</label> */}
                {/* <input
                  type="text"
                  className="formal text-xl bg-transparent text-dark form-control justify-center items-start p-2.5 mt-3 capitalize rounded-lg  max-md:pr-5 focus:outline-none focus:border-violet-900"
                        style={{ border: '2px solid rgba(142, 142, 142, 1)' }}
                  // className="form-control"
                  id="city"
                  placeholder="Select State"
                  name="city"
                  value={formData.city}
                  onChange={handleChange}
                  required
                /> */}
                {/* <div class="dropdown">
                  <button class="btn btn-secondary dropdown-toggle outline-none focus:outline-none text-xl" style={{ backgroundColor: "transparent", color: "black", border: "none" }} type="button" data-bs-toggle="dropdown" aria-expanded="false">
                  {city}
                  </button>
                  <ul class="dropdown-menu h-[200px] overflow-y-scroll">
                    {usStates.map((item) => {
                      return (
                        <li><a class="dropdown-item" name={item} onClick={handleCityClick} href="#">{item}</a></li>
                      )
                    })}

                  </ul>
                </div> */}

                <div className="form-floating">
                  <Dropdown label={"Select State"}
                    options={usStates.map(state => ({
                      id: state.id,
                      value: state,
                      label: state
                    }
                    ))}

                    onSelect={handleCityClick}
                  />
                  {isSubmit && city==='Select State' &&
                      <div class="text-danger">
                      State name is required.
                    </div>
                  }
                </div>
              </div>
              {/* Area*/}
              <div className="col-md-8 mb-3 mt-4">
                {/* <label htmlFor="lastName">Area</label> */}
                <input
                  type="text"
                  // className="form-control"
                  className="formal text-xl bg-transparent text-dark form-control justify-center items-start p-2.5 mt-3 capitalize rounded-lg  max-md:pr-5 focus:outline-none focus:border-violet-900"
                  style={{ border: '2px solid rgba(142, 142, 142, 1)' }}
                  placeholder="Area"
                  id="area"
                  name="area"
                  value={formData.area}
                  onChange={handleChange}
                  // disabled
                  required
                />
                <div className="invalid-feedback">
                  Valid area name is required.
                </div>
              </div>

              {/* <h2 className="mb-3 fw-bold ">Project Information </h2>s */}
              {/* select client drop down */}
              <div className='col-md-10 mb-3'>
                <div className="container ">
                  <div className="form-floating">
                    <Dropdown label={"Select Client"}
                      options={clients.map(client => ({
                        id: client.id,
                        value: client.id,
                        label: client.name
                      }
                      ))}
                      addnewACF={true}
                      onSelect={handleClientSelectChange}
                      // onSelect={setclientSelectValue}
                      onChangeACF={setShowClientModal}
                    // onChangeACF={handleClientSelectChange}
                    // onChange={handleClientSelectChange}
                    />
                  </div>
                  {isSubmit && selectedClient.length===0 &&
                      <div class="text-danger">
                      Client name is required.
                    </div>
                  }

                  <div className={`modal fade ${showClientModal ? 'show' : ''}`} style={{ display: showClientModal ? 'block' : 'none' }} tabIndex="-1" role="dialog" aria-labelledby="modalLabel" aria-hidden="true">
                    <div className="modal-dialog" role="document">
                      <div className="modal-content">
                        <div className="modal-header">
                          <h5 className="modal-title" id="modalLabel">Add New Client</h5>
                          <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={handleClientClose}></button>
                        </div>
                        <form onSubmit={(e) => {
                          e.preventDefault();
                        }}>
                          <div className="modal-body">

                            <div className="mb-3">
                              {/* <label htmlFor="companyName"placeholder="Full name" className="form-label">Company Name</label> */}
                              <input type="text" className="form-control" placeholder="Client Company Name" id="companyName" name="company_name" value={clientInfo.company_name} onChange={handleClientFieldChange} />
                            </div>
                            <div className="mb-3">
                              {/* <label htmlFor="email" className="form-label">Email</label> */}
                              <input type="email" className="form-control" id="email" placeholder="Client Email ID" name="email" value={clientInfo.email} onChange={handleClientFieldChange} />
                            </div>
                            <div className="mb-3">
                              {/* <label htmlFor="contactNumber" className="form-label">Contact Number</label> */}
                              <input type="text" className="form-control" id="contactNumber" placeholder="Client Mobile NO" name="contact_number" value={clientInfo.contact_number} onChange={handleClientFieldChange} />
                            </div>
                            <div className="mb-3">
                              {/* <label htmlFor="website" className="form-label">Website</label> */}
                              <input type="url" className="form-control" id="website" name="website" placeholder="Client Website" value={clientInfo.website} onChange={handleClientFieldChange} />
                            </div>
                          </div>
                          <div className="modal-footer">
                            <button type="button" className="btn btn-secondary" onClick={handleClientClose}>Close</button>
                            <button type="button" className="btn btn-primary" onClick={handleSaveClient}>Save Client</button>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>

                  {showClientModal && <div className="modal-backdrop fade show"></div>}
                </div>
              </div>



              {/* start date and end date */}
              <div className="flex gap-4 mt-3">

                <div className="col-md-4 mb-3">

                  <DateInput
                    date={formData.startDate}
                    handleChange={handleStartDateChange}
                    label={"Start Date"}
                    className={"form-control"}
                    handleBlur={handleBlur}
                    handleFocus={handleFocus}
                    name={"startDate"}
                    dateRef={startRef}
                    required={"required"}
                    isPlaceholderVisible={isPlaceholderVisible}
                  />

                </div>

                <div className="col-md-4 mb-3">

                  <DateInput

                    date={formData.endDate}
                    handleChange={handleEndDateChange}
                    className={"form-control"}
                    label={"End Date"}
                    handleBlur={handleBlur}
                    handleFocus={handleFocus}
                    dateRef={endRef}
                    isPlaceholderVisible={isPlaceholderVisible}
                    name={"endDate"}
                  />
                  {/* <div className="invalid-feedback">
                    Valid endDate is required.
                  </div> */}
                </div>

              </div>

              {/* Project job role */}
              <div className="col-md-10 mb-3">
                {/* <label htmlFor="contactno">Project Job Role</label> */}
                <input
                  type="text"
                  // className="form-control"
                  className="formal text-xl bg-transparent text-dark form-control justify-center items-start p-2.5 mt-3 capitalize rounded-lg  max-md:pr-5 focus:outline-none focus:border-violet-900"
                  style={{ border: '2px solid rgba(142, 142, 142, 1)' }}
                  id="job_role"
                  placeholder="Project Job Role"
                  name="job_role"
                  value={formData.job_role}
                  onChange={handleChange}
                  required
                />
                <div className="invalid-feedback">
                  Valid Job role name is required.
                </div>
              </div>
              {/* client point of contract */}

              <div className="col-md-10 mb-3">
                {/* <label htmlFor="contactno">Client Point of Contract</label> */}
                <input
                  type="text"
                  // className="form-control"
                  className="formal text-xl bg-transparent text-dark form-control justify-center items-start p-2.5 mt-3 capitalize rounded-lg  max-md:pr-5 focus:outline-none focus:border-violet-900"
                  style={{ border: '2px solid rgba(142, 142, 142, 1)' }}
                  id="clientPOCName"
                  placeholder="Client Point of Contact Name"
                  name="clientPOCName"
                  value={formData.clientPOCName}
                  onChange={handleChange}
                  required
                />
                <div className="invalid-feedback">
                  Valid Client POC Name is required.
                </div>
              </div>







              {/* client email Id */}
              <div className="flex gap-5">

                <div className="col-md-10 mb-3">
                  {/* <label htmlFor="contactno">Client Email ID</label> */}
                  <input
                    type="text"
                    // className="form-control"
                    className="formal text-xl bg-transparent text-dark form-control justify-center items-start p-2.5 mt-3  rounded-lg  max-md:pr-5 focus:outline-none focus:border-violet-900"
                    style={{ border: '2px solid rgba(142, 142, 142, 1)' }}
                    placeholder="Client Email ID"
                    id="clientPOCEmail"
                    name="clientPOCEmail"
                    value={formData.clientPOCEmail}
                    onChange={handleChange}
                    required
                  />
                  <div className="invalid-feedback">
                    Valid Client POC Email ID name is required.
                  </div>
                </div>

              </div>

              {/* client mobile pri no */}
              <div className="flex gap-5">

                <div className="col-md-10 mb-3">
                  {/* <label htmlFor="contactno">Client Primary No</label> */}
                  <input
                    type="text"
                    // className="form-control"
                    className="formal text-xl bg-transparent text-dark form-control justify-center items-start p-2.5 mt-3 capitalize rounded-lg  max-md:pr-5 focus:outline-none focus:border-violet-900"
                    style={{ border: '2px solid rgba(142, 142, 142, 1)' }}
                    id="clientPOCPriNo"
                    placeholder="Client Primary No"
                    name="clientPOCPriNo"
                    value={formData.clientPOCPriNo}
                    onChange={handleChange}
                    required
                  />
                  <div className="invalid-feedback">
                    Valid Client Pri Mobile No is required.
                  </div>
                </div>
              </div>
              {/* client mobile sec no */}
              <div className="flex gap-5">

                <div className="col-md-10 mb-3">
                  {/* <label htmlFor="contactno">Client Secondary No</label> */}
                  <input
                    type="text"
                    // className="form-control"
                    className="formal text-xl bg-transparent text-dark form-control justify-center items-start p-2.5 mt-3 capitalize rounded-lg  max-md:pr-5 focus:outline-none focus:border-violet-900"
                    style={{ border: '2px solid rgba(142, 142, 142, 1)' }}
                    placeholder="Client Secondary No"

                    id="clientPOCSecNo"
                    name="clientPOCSecNo"
                    value={formData.clientPOCSecNo}
                    onChange={handleChange}
                    required
                  />
                  <div className="invalid-feedback">
                    Valid Client Sec Mobile No is required.
                  </div>
                </div>
              </div>

              {/* Client job role */}
              <div className="col-md-10 mb-3">
                {/* <label htmlFor="contactno">Client POC Job Role</label> */}
                <input
                  type="text"
                  // className="form-control"
                  className="formal text-xl bg-transparent text-dark form-control justify-center items-start p-2.5 mt-3 capitalize rounded-lg  max-md:pr-5 focus:outline-none focus:border-violet-900"
                  style={{ border: '2px solid rgba(142, 142, 142, 1)' }}
                  id="clientPOCRole"
                  placeholder="Client POC Job Role"
                  name="clientPOCRole"
                  value={formData.clientPOCRole}
                  onChange={handleChange}
                  required
                />
                <div className="invalid-feedback">
                  Valid Client POC Job role name is required.
                </div>
              </div>

            </div>

            {/* Second Column */}
            <div className="col-md-6 mb-3 mt-4">

              {/* Email  */}
              <div className="col-md-10 mb-3">
                {/* <label htmlFor="email">Email</label> */}
                <input
                  type="email"
                  // className="form-control"
                  className="formal text-xl bg-transparent text-dark form-control justify-center items-start p-2.5 mt-3  rounded-lg  max-md:pr-5 focus:outline-none focus:border-violet-900"
                  style={{ border: '2px solid rgba(142, 142, 142, 1)' }}
                  id="email"
                  name="email"
                  placeholder="Email"
                  value={formData.email}
                  onChange={handleChange}
                  required
                />
                <div className="invalid-feedback">
                  Valid Email is required.
                </div>
              </div>
              {/* SSN  */}
              <div className="col-md-10 mb-3">
                {/* <label htmlFor="ssn">Last 4 Digits of Social Security No.</label> */}
                <input
                  type="text"
                  // className="form-control"
                  className="formal text-xl bg-transparent text-dark form-control justify-center items-start p-2.5 mt-3 capitalize rounded-lg  max-md:pr-5 focus:outline-none focus:border-violet-900"
                  style={{ border: '2px solid rgba(142, 142, 142, 1)' }}
                  placeholder="Last 4 Digits of Social Security No."
                  id="ssn"
                  name="ssn"
                  value={formData.ssn}
                  onChange={handleChange}
                  required
                />
                <div className="invalid-feedback">
                  Valid SSN is required.
                </div>
              </div>
              {/* visa */}
              <div className="col-md-10 col-sm-12 mb-3">
                {/* <label htmlFor="contactno">Visa</label> */}
                <input
                  type="text"
                  // className="form-control"
                  className="formal text-xl bg-transparent text-dark form-control justify-center items-start p-2.5 mt-3 capitalize rounded-lg  max-md:pr-5 focus:outline-none focus:border-violet-900"
                  style={{ border: '2px solid rgba(142, 142, 142, 1)' }}
                  placeholder="Visa"
                  id="visa"
                  name="visa"
                  value={formData.visa}
                  onChange={handleChange}
                  required
                />
                <div className="invalid-feedback">
                  Valid Visa name is required.
                </div>
              </div>
              {/* gender */}
              <div className="col-md-10 col-sm-12 mb-0">
                {/* <label htmlFor="lastName">Gender</label> */}
                <input
                  type="text"
                  // className="form-control"
                  className="formal text-xl bg-transparent text-dark form-control justify-center items-start p-2.5 mt-3 capitalize rounded-lg  max-md:pr-5 focus:outline-none focus:border-violet-900"
                  style={{ border: '2px solid rgba(142, 142, 142, 1)' }}
                  id="gender"
                  placeholder="Gender"
                  name="gender"
                  value={formData.gender}
                  onChange={handleChange}
                  required
                />
                <div className="invalid-feedback">
                  Valid Gender  is required.
                </div>
              </div>



              {/* Pay rate Duration */}

              <div className="col-md-10 col-sm-12 mb-3 mt-4 pt-2">
                {/* <label htmlFor="firstName">Rate</label> */}
                <input
                  type="text"
                  // className="form-control"
                  className="formal text-xl bg-transparent text-dark form-control justify-center items-start p-2.5 mt-3 capitalize rounded-lg  max-md:pr-5 focus:outline-none focus:border-violet-900"
                  style={{ border: '2px solid rgba(142, 142, 142, 1)' }}
                  id="rate"
                  placeholder="Rate"
                  name="rate"
                  value={formData.rate}
                  onChange={handleChange}
                  required
                />
                <div className="invalid-feedback">
                  Valid rate  is required.
                </div>
              </div>

              <div className="col-md-10 col-sm-12 mb-3">
                {/* <label htmlFor="firstName">Duration</label> */}
                <input
                  type="text"
                  // className="form-control"
                  className="formal text-xl bg-transparent text-dark form-control justify-center items-start p-2.5 mt-3 capitalize rounded-lg  max-md:pr-5 focus:outline-none focus:border-violet-900"
                  style={{ border: '2px solid rgba(142, 142, 142, 1)' }}
                  placeholder="Duration"
                  id="duration"
                  name="duration"
                  value={formData.duration}
                  onChange={handleChange}
                  required
                />
                <div className="invalid-feedback">
                  Valid duration is required.
                </div>
              </div>

              {/* pay type */}
              <div className="col-md-10">
                {/* <label htmlFor="contactno">Pay Type</label> */}
                <Dropdown label={"Pay Type"}
                  options={paytypes.map(type => ({
                    id: type.id,
                    value: type.value,
                    label: type.value
                  }
                  ))}
                  required
                  onSelect={handlePayTypes}
                />
                {isSubmit && payType==='' &&
                      <div className="text-danger ">
                      PayType is required.
                    </div>
                  }
                {/* <select className=" bg-transparent fs-12 w-50 px-2 py-1 mt-3 focus:outline-none text-xl rounded-lg focus:border-violet-900 focus:outline-none focus:border-violet-900 focus:bg-transparent" id="floatingSelectw" aria-label="Floating label select example"
                  style={{ border: '2px solid rgba(142, 142, 142, 1)' }}
                  onChange={(e) => setPayType(e.target.value)}
                >
                  <option value="">Pay Type</option>
                  <option value="fullTime">Full Time</option>
                  <option value="c2c">C2C</option>
                  <option value="w2">W2</option>

                </select>
                <div className="invalid-feedback">
                  Valid Pay Type is required.
                </div> */}
              </div>

              {/* <h2 className="mb-3 fw-bold mt-4">Vendor Information </h2> */}
              {/* Add Vendor Details */}
              <div className="col-md-10 mb-3 mt-4">
                <form onSubmit={handleVendorSubmit}>
                  {vendorsPOC.map((vendor, index) => (
                    <div key={index} className="mb-3 border-bottom pb-3">
                      {/* Vendor selection */}
                      <Dropdown label={"Select Vendor"}
                        options={vendors.map(vendorOption => (
                          { id: vendorOption.id, value: vendorOption.name, label: vendorOption.name }
                        ))}
                        addnewACF2={true}
                        onSelectACF={handleVendorSelectionChange}
                        // onChangeACF={}
                        // onSelect={vendor.company_id}
                        index={index}
                      />
                      {isSubmit && vendorsPOC[0].company_id===''  &&
                      <div className="text-danger ">
                      Vendorname is required.
                    </div>
                  }
                      {/* <div className="form-floating mb-3">
                        <select
                          className="form-select"
                          value={vendor.company_id}
                          onChange={(e) => handleVendorSelectionChange(index, e)}
                          aria-label="Vendor select example">
                          <option value="">Select Vendor</option>
                          {vendors.map(vendorOption => (
                            <option key={vendorOption.id} value={vendorOption.id}>{vendorOption.name}</option>
                          ))}
                          <option value="new">Add New</option>
                        </select>
                        <label htmlFor="vendorSelect">Vendors</label>
                      </div> */}

                      {/* Vendor details */}
                      <input
                        type="text"
                        // className="form-control mb-3"
                        placeholder="Vendor Point Of Contact Name"
                        className="formal text-xl bg-transparent text-dark form-control justify-center items-start p-2.5 mt-3 capitalize rounded-lg  max-md:pr-5 focus:outline-none focus:border-violet-900"
                        style={{ border: '2px solid rgba(142, 142, 142, 1)' }}
                        name="name"
                        value={vendor.name}
                        onChange={(e) => handleVendorInputChange(index, e)}
                        required
                      />
                      <div className="invalid-feedback">
                        Valid Vendor name is required.
                      </div>
                      <input
                        type="email"
                        // className="form-control mb-3"
                        className="formal text-xl bg-transparent text-dark form-control justify-center items-start p-2.5 mt-3  rounded-lg  max-md:pr-5 focus:outline-none focus:border-violet-900"
                        style={{ border: '2px solid rgba(142, 142, 142, 1)' }}
                        placeholder="Vendor Email ID"
                        name="email_address"
                        value={vendor.email_address}
                        onChange={(e) => handleVendorInputChange(index, e)}
                        required
                      />
                      <div className="invalid-feedback">
                        Valid Vendor Email ID is required.
                      </div>
                      <input
                        type="text"
                        // className="form-control mb-3"
                        className="formal text-xl bg-transparent text-dark form-control justify-center items-start p-2.5 mt-3 capitalize rounded-lg  max-md:pr-5 focus:outline-none focus:border-violet-900"
                        style={{ border: '2px solid rgba(142, 142, 142, 1)' }}
                        placeholder="Vendor Primary No"
                        name="contact_number"
                        value={vendor.contact_number}
                        onChange={(e) => handleVendorInputChange(index, e)}
                        required
                      />
                      <div className="invalid-feedback">
                        Valid Vendor Primary No is required.
                      </div>
                      <input
                        type="text"
                        // className="form-control mb-3"
                        className="formal text-xl bg-transparent text-dark form-control justify-center items-start p-2.5 mt-3 capitalize rounded-lg  max-md:pr-5 focus:outline-none focus:border-violet-900"
                        style={{ border: '2px solid rgba(142, 142, 142, 1)' }}
                        placeholder="Vendor Secondary No"
                        name="secondary_number"
                        value={vendor.secondary_number}
                        onChange={(e) => handleVendorInputChange(index, e)}
                        required
                      />
                      <div className="invalid-feedback">
                        Valid Vendor Secondary No is required.
                      </div>
                      <input
                        type="text"
                        // className="form-control mb-3"
                        className="formal text-xl bg-transparent text-dark form-control justify-center items-start p-2.5 mt-3 capitalize rounded-lg  max-md:pr-5 focus:outline-none focus:border-violet-900"
                        style={{ border: '2px solid rgba(142, 142, 142, 1)' }}
                        placeholder="Vendor POC Job Role"
                        name="job_role"
                        value={vendor.job_role}
                        onChange={(e) => handleVendorInputChange(index, e)}
                        required
                      />
                      <div className="invalid-feedback">
                        Valid Vendor POC Job Role is required.
                      </div>

                      <button type="button" className="btn btn-danger mt-2 w-100" onClick={() => removeVendor(index)}>
                        Remove Vendor
                      </button>

                      {/* {showNewVendorModal && ( */}

                      <div className={`modal fade ${showNewVendorModal ? 'show' : ''}`}
                        style={{ display: showNewVendorModal ? 'block' : 'none' }}

                        tabIndex="-1" role="dialog" aria-labelledby="modalLabel" aria-hidden="true">
                        <div className="modal-dialog" role="document">
                          <div className="modal-content">
                            <div className="modal-header">
                              <h5 className="modal-title">Add New Vendor</h5>
                              <button type="button" className="btn-close" onClick={() => setShowNewVendorModal(false)}></button>
                            </div>
                            <div className="modal-body">
                              <input type="text" className="form-control mb-3" placeholder="Vendor Company Name" name="company_name" value={vendorInfo.company_name} onChange={e => setVendorInfo({ ...vendorInfo, company_name: e.target.value })} />
                              <input type="email" className="form-control mb-3" placeholder="Vendor Email" name="email" value={vendorInfo.email} onChange={e => setVendorInfo({ ...vendorInfo, email: e.target.value })} />
                              <input type="text" className="form-control mb-3" placeholder="Vendor Contact Number" name="contact_number" value={vendorInfo.contact_number} onChange={e => setVendorInfo({ ...vendorInfo, contact_number: e.target.value })} />
                              <input type="url" className="form-control mb-3" placeholder="Vendor Website" name="website" value={vendorInfo.website} onChange={e => setVendorInfo({ ...vendorInfo, website: e.target.value })} />
                            </div>
                            <div className="modal-footer">
                              <button type="button" className="btn btn-secondary" onClick={() => setShowNewVendorModal(false)}>Close</button>
                              <button type="button" className="btn btn-primary" onClick={submitNewVendor}>Save Vendor</button>
                            </div>
                          </div>
                        </div>
                      </div>
                      {/* // )} */}

                    </div>
                  ))}

                  <button type="button" className="btn btn-primary w-100" onClick={addVendor}>
                    Add Vendor
                  </button>

                </form>

              </div>

            </div>

          </div>
          <button className="btn btn-primary btn-lg btn-block" type="submit">Add Project and Consultant</button>
        </form>
      </div>
    </div>


  );
}

export default AddConsultantForm;


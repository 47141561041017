import React, { useState } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

const MonthYearPicker = ({ selectedDate, onChange , width,height}) => {
  
  const today = new Date();
  const maxDate = new Date(today.getFullYear(), today.getMonth());
  const dynamicClasses = `w-[${width}px] h-[${height}px] rounded-[4px] px-3`;
  
  return (
    <DatePicker
      selected={selectedDate}
      onChange={onChange}
      dateFormat="MMMM/yyyy"
      showMonthYearPicker
      maxDate={maxDate}
      placeholderText="Select month and year"
      // className={dynamicClasses}
      className='w-[150px] h-[30px] rounded-[4px] px-3'
      style={{
        border:'2px solid black'
      }}
    />
  );
};

export default MonthYearPicker;

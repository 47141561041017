import * as React from 'react';
import footer from "../../components/assets/images/consultantDashboardFooter.png";
import Navbar from '../../components/adminComponents/Navbar';
import AddConsultantForm from '../../components/adminComponents/AddConsultantForm';
import AssignProjectForm from '../../components/adminComponents/AssignProjectForm';
import AdminDashboardView from '../../components/adminComponents/AdminDashboardView';
import AdminDashboardViewTimesheets from '../../components/adminComponents/AdminDashboardViewTimesheets';
import AdminDashboardProjectDetail from '../../components/adminComponents/AdminDashboardProjectDetail';
import ChangeConsultantInfo from '../../components/adminComponents/ChangeConsultantInfo';
import {useLocation, useNavigate} from 'react-router-dom';

function AdminDashboard() {
  const location = useLocation();
  const navigate = useNavigate();
  const path = location.pathname;
  // if(true){
  if(localStorage.getItem('usertype')==='1'||undefined || localStorage.getItem('id')===undefined || localStorage.getItem('token')===undefined){
    
  return (
<>
<div className="flex flex-col min-h-screen">
  <div style={{justifyContent:"space-between"}} className="flex flex-col flex-grow pt-8 bg-purple-50 ">
    <div className="flex flex-col self-center w-full max-w-[1087px] max-md:max-w-full">
      <Navbar/>
      {path === "/admin/adminDashboard/addConsultant" ? <AddConsultantForm/> 
        : path === "/admin/adminDashboard/assignProject" ? <AssignProjectForm/> 
        : path === "/admin/adminDashboard" ? <AdminDashboardView/>
        : path === "/admin/adminDashboard/viewTimesheets" ? <AdminDashboardViewTimesheets/>
        : path === "/admin/adminDashboard/viewProjectInfo" ? <AdminDashboardProjectDetail/>
        : path === "/admin/adminDashboard/changeConsultantInfo" ? <ChangeConsultantInfo/> 
        : <h1>404 Not Found</h1>}
    </div>
  <div className="flex-shrink-0">
    <img
      loading="lazy"
      srcSet={footer}
      className="w-full aspect-[7.69]"
    />
  </div>
  </div>
</div>
</>

  );
}
else {
  localStorage.removeItem("token");
  localStorage.removeItem("otpVerified");
  localStorage.removeItem("usertype");
  window.alert("You are not authorized to view this page or your session has expired, please log in again.");
  window.location.href = '/admin';
  return (
    <>
    </>
  );
}
};

export default AdminDashboard;
import { useEffect, useState } from "react";
import { useNavigate, useLocation } from 'react-router-dom';
import axios from 'axios';
import Swal from 'sweetalert2'
import DarkEye from '../assets/images/carbon_view-filled.svg'
import Loader from '../loader'
import Send from "../assets/images/send.svg"
import Close from "../assets/images/Close.svg"
import DownloadImage from "../assets/images/material-symbols_download.svg"
import moment from 'moment';
import ViewInactive from "../assets/images/view inactive.svg"
import message from "../assets/images/chat icon  notifiction.svg"
import DatePicker from 'react-datepicker';
import Calender from "../assets/images/Calendar.svg"
import Accept from "../assets/images/Accept.svg"
import Deny from "../assets/images/Deny.svg"
import Info from "../assets/images/info.svg"
import RightArrow from "../assets/images/chevron.svg"
import LeftArrow from "../assets/images/chevron left.svg"
import DownloadImageShade from "../assets/images/material-symbols_download light shade.svg"
import 'react-datepicker/dist/react-datepicker.css';
const pageSize = 10;

function AdminDashboardViewTimesheets() {
  const [sheet, setSheet] = useState(false);
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [note, setNote] = useState(false);
  const navigate = useNavigate();
  const today = new Date();
  const threeMonthsAgo = new Date(today);
  threeMonthsAgo.setMonth(threeMonthsAgo.getMonth() - 3);

  const formatToYYYYMM = (date) => {
    const year = date.getFullYear();
    const month = date.getMonth() + 1; // Months are zero-based
    return `${year}/${month < 10 ? '0' : ''}${month}`;
  };

  const [startDate, setStartDate] = useState(formatToYYYYMM(threeMonthsAgo));
  const [endDate, setEndDate] = useState(formatToYYYYMM(today));
  const [showForm, setShowForm] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);

  const location = useLocation();
  const { projectId, projectName } = location.state;

  const downloadFileAtURL = (image_url) => {
    const url = image_url;
    const atag = document.createElement('a');
    atag.href = url;
    atag.setAttribute('target', '_blank');
    document.body.appendChild(atag);
    atag.click();
    atag.remove();
  };

  const [id, setId] = useState('');
  const totalPages = Math.ceil(data.length / pageSize);
  const startIndex = (currentPage - 1) * pageSize;
  const endIndex = Math.min(startIndex + pageSize, data.length);
  const currentRecords = data.slice(startIndex, endIndex);

  function isoWeekToDayAndMonthName(year, week) {
    const startDate = moment().year(year).week(week).startOf('isoWeek');
    const endDate = startDate.clone().add(6, 'days'); // Monday to Sunday
    const monthName = startDate.format('MMMM');
    const monthWeekNumber = Math.ceil(startDate.date() / 7);
    // return `Week ${monthWeekNumber} of ${monthName} (${startDate.format('MMMM Do')} to ${endDate.format('MMMM Do, YYYY')})`;

    return ` ${startDate.format('MMMM Do')} to ${endDate.format('MMMM Do, YYYY')}`;
  }

  const nextPage = () => {
    setCurrentPage(prevPage => Math.min(prevPage + 1, totalPages));
  };

  const prevPage = () => {
    setCurrentPage(prevPage => Math.max(prevPage - 1, 1));
  };

  const handleFormClick = () => {
    const tid = id;
    const headers = {
      Authorization: `${token}`
    };
    const note = document.getElementById('commentText').value;
    if (note === "") {
      setNote(true);
      return;
    }
    const body = {
      approval: false,
      note,
    };
    setIsLoading(true);
    axios.put(`${process.env.REACT_APP_API_URL}/weekDetails/approval/${tid}`, body, { headers }).then((response) => {
      setIsLoading(false);
      setSheet(!sheet);
      setNote(false);
      setId('');
      setShowForm(false);
    }).catch((e) => {
      if (e.response.status === 401) {
        alert("Session Expired Please Login");
        navigate("/admin");
      }
    });
    Swal.fire({
      icon: "success",
      title: "You have sent a note",
      showConfirmButton: false,
      timer: 1500
    });
  };

  const handleSecondFormClick = (nid) => {
    const headers = {
      Authorization: `${token}`
    };
    const body = {
      approval: true,
    };
    setIsLoading(true);
    Swal.fire({
      title: "Are you sure?",
      text: "You want to approve this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, approve it!"
    }).then((result) => {
      if (result.isConfirmed) {
        Swal.fire({
          title: "Approved!",
          text: "You have approved the Timesheet.",
          icon: "success"
        });
        axios.put(`${process.env.REACT_APP_API_URL}/weekDetails/approval/${nid}`, body, { headers }).then((response) => {
          setSheet(!sheet);
          setIsLoading(false);
          setId('');
        }).catch((e) => {
          if (e.response.status === 401) {
            alert("Session Expired Please Login");
            navigate("/");
          }
        });
      }
      else {
        Swal.fire({
          title: "Cancelled!",
          text: "You have cancelled the approval.",
          icon: "info"
        });
        setIsLoading(false);
      }
    });
  };

  function handleRadioClick(text) {
    document.getElementById('commentText').value = text;
  }

  const [client, setClient] = useState("");
  const [rate, setRate] = useState("");
  const token = localStorage.getItem('token');
  if (!token) {
    console.error('Token not found in local storage');
  }

  const headers = {
    Authorization: `${token}`
  };

  useEffect(() => {
    setIsLoading(true);
    axios.get(`${process.env.REACT_APP_API_URL}/weekDetails/range/${projectId}/${formatToMonthYear(new Date(startDate))}/${formatToMonthYear(new Date(endDate))}`, { headers }).then(response => {
      setIsLoading(false);
      setData(response.data.timesheets);
      setClient(response.data.project_details.client_id.company_name);
      setRate(response.data.project_details.rate);
    }).catch(error => {

      if (error.response.status == 401) {
        console.error('Failed to fetch week data:');

        alert("Session Expired Please Login");
        navigate("/");
      }
      if (error.response.status === 401) {
        alert("Session Expired Please Login");
        navigate("/");
      }
    });
  }, [sheet, startDate, endDate]);

  const handleStartChange = (date) => {
    const dateObject = new Date(date);
    const year = dateObject.getFullYear();
    const month = dateObject.getMonth() + 1;
    const formattedDate = `${year}/${month < 10 ? '0' : ''}${month}`;
    setStartDate(formattedDate);
    if (new Date(formattedDate) > new Date(endDate)) {
      setEndDate(formattedDate);
    }
  };

  const handleEndChange = (date) => {
    const dateObject = new Date(date);
    const year = dateObject.getFullYear();
    const month = dateObject.getMonth() + 1;
    const formattedDate = `${year}/${month < 10 ? '0' : ''}${month}`;
    setEndDate(formattedDate);
    if (new Date(formattedDate) < new Date(startDate)) {
      setStartDate(formattedDate);
    }
  };

  const handleClose = () => {

    setId('')
    setNote(false)
    setShowForm(false)
  }
  const [chatHistory, setchatHistory] = useState(false);
  const [adminNote, setAdminNote] = useState();
  const [consultantNote, setConsultantNote] = useState();

  const showNoteRemark = (note, remark) => {
    setAdminNote(note);
    setConsultantNote(remark);

    setchatHistory(true);

  }

  const handleChatClose = () => {
    setchatHistory(false);
  }
  const MonthYearPicker = ({ selectedDate, onChange, minDate, maxDate }) => {
    return (
      <DatePicker
        selected={selectedDate}
        onChange={onChange}
        dateFormat="MMMM/yyyy"
        showMonthYearPicker
        minDate={minDate}
        maxDate={maxDate}
        placeholderText="Select month and year"
        className='w-[180px] rounded-[4px]'
      />
    );
  };
  const formatToMonthYear = (date) => {
    const year = date.getFullYear();
    const month = date.getMonth() + 1;
    return `${year}/${month}`;
  };


  function downloadFileAt(url) {
    fetch(url)
      .then(response => {
        if (!response.ok) {
          throw new Error('Network response was not ok.');
        }
        return response.blob();
      })
      .then(blob => {
        const downloadUrl = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = downloadUrl;

        const filename = url.split('/').pop();
        link.setAttribute('download', filename);

        document.body.appendChild(link);
        link.click();

        document.body.removeChild(link);
        window.URL.revokeObjectURL(downloadUrl);
      })
      .catch(error => {
        console.error('Error downloading the file:', error);
      });
  }


  return (
    <>
      {isLoading ? (
        <Loader />
      ) : (
        <div className="relative">
          {showForm && (
            <div
              style={{
                position: "fixed",
                top: 0,
                left: 0,
                width: "100%",
                height: "100%",
                backgroundColor: "rgba(0, 0, 0, 0.5)",
                backdropFilter: "blur(5px)",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                zIndex: 999,
              }}
            >
              <div
                style={{
                  backgroundColor: "rgba(255, 255, 255, 0.9)",
                  padding: "20px",
                  borderRadius: "10px",
                  maxWidth: "593px",
                  position: "relative",
                }}
              >
                <button
                  type="button"
                  style={{
                    position: "absolute",
                    top: "5px",
                    right: "5px",
                    fontSize: "15px",
                    fontWeight: "bold",
                    cursor: "pointer",
                  }}
                  className="btn-close"
                  onClick={handleClose}
                  aria-label="Close"
                ></button>
                <div className="text-lg leading-7 text-black capitalize max-md:max-w-full">
                  Comment
                </div>
                <div className="flex gap-2.5 py-1.5 mt-2 text-base text-black bg-white max-md:flex-wrap">
                  <input
                    type="radio"
                    id="timesheetMismatch"
                    name="commentType"
                    onClick={() => handleRadioClick('Timesheet Doesn’t match with hours recorded')}
                    value="timesheetMismatch"
                  />
                  <label htmlFor="timesheetMismatch">Timesheet Doesn’t match with hours recorded</label>
                </div>
                <div className="flex gap-2.5 py-1.5 mt-3 text-base text-black bg-white max-md:flex-wrap">
                  <input
                    type="radio"
                    id="wrongWeek"
                    name="commentType"
                    value="wrongWeek"
                    onClick={() => handleRadioClick('Wrong week on timesheet')}
                  />
                  <label htmlFor="wrongWeek">Wrong week on timesheet</label>
                </div>
                <div className="flex gap-3.5 mt-3 max-md:flex-wrap">
                  <input
                    type="text"
                    id="commentText"
                    className="shrink-0 max-w-full bg-white rounded-sm border border-black border-solid h-[31px] w-[527px]"
                  />
                  <img
                    onClick={handleFormClick}
                    loading="lazy"
                    src={Send}
                    className="shrink-0 aspect-square w-[31px] cursor-pointer"
                    style={{ marginLeft: "-1%" }}
                  />
                </div>
                {note && <div className="text-red-500">Please add a note</div>}
              </div>
            </div>
          )}

          {chatHistory &&
            <div style={{
              position: "fixed",
              top: 0,
              left: 0,
              width: "100%",
              height: "100%",
              backgroundColor: "rgba(0, 0, 0, 0.5)",
              backdropFilter: "blur(5px)",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              zIndex: 999,
            }}>
              <div style={{
                backgroundColor: "#F9E7FF",
                padding: "20px",
                borderRadius: "10px",
                width: '500px',
                position: "relative",
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                gap: '20px'
              }}>
                <div className="flex gap-5 justify-between self-stretch w-full"
                  style={{
                    maxWidth: "593px",
                  }}
                >
                  <div className="my-auto">Comments</div>
                  <img
                    src={Close}
                    alt="Close"
                    className="shrink-0 aspect-square w-[18px] cursor-pointer"
                    onClick={handleChatClose}
                  />
                </div>
                <input
                  type="text"
                  value={adminNote ? adminNote : "NA"}  // Placeholder value for the first input
                  readOnly
                  style={{
                    width: "100%",
                    height: "100%",
                    padding: '10px 20px',
                    fontSize: 'large',
                    border: '1px solid #ccc',
                    borderRadius: '5px',
                    backgroundColor: 'white',  // Set background color to white
                    backgroundRepeat: 'no-repeat',
                    backgroundPosition: 'right 10px center',
                    backgroundSize: '40px 40px'
                  }}
                // placeholder="NA"
                />

                <input
                  type="text"
                  value={consultantNote ? consultantNote : "NA"}  // Static text, displayed but not editable
                  readOnly
                  style={{
                    width: "100%",
                    height: "100%",
                    padding: '10px 20px',
                    fontSize: 'large',
                    border: '1px solid #ccc',
                    borderRadius: '5px',
                    backgroundColor: '#E7C9F8',  // Set background color to violet
                    color: 'black',  // Text color set to white for better visibility on violet background
                    backgroundRepeat: 'no-repeat',
                    backgroundPosition: 'right 50px center',
                    backgroundSize: '40px 40px'
                  }}
                  placeholder="Additional info here..."
                />

              </div>
            </div>
          }

          <div className="flex gap-5 justify-between mb-5 mt-5 items-center px-5 text-xl text-black bg-purple-50 rounded-lg shadow-lg max-md:flex-wrap max-md:px-5">
            <div className="flex gap-2 justify-between self-stretch my-auto">
              <div className="flex  gap-2 w-100 ">
                Client, <span className="font-semibold text-nowrap">{client}</span>
                <img
                  onClick={() => {
                    navigate('/admin/adminDashboard/viewProjectInfo', {
                      state: {
                        projectId: projectId,
                      },
                    });
                  }}
                  loading="lazy"
                  src={Info}
                  className="shrink-0 my-auto w-6 aspect-square cursor-pointer"
                />
              </div>
            </div>
            <div className="self-stretch my-auto flex">
              Amount: <span className="font-bold">${rate}</span>
            </div>
            <div className="flex gap-2 self-stretch font-bold">
              <img
                loading="lazy"
                src={Calender}
                className="shrink-0 self-start w-12 aspect-square"
              />
              <div className="flex gap-3 justify-between p-2.5">
                <div>
                  <MonthYearPicker
                    selectedDate={startDate}
                    onChange={handleStartChange}
                    maxDate={endDate || today}
                  />
                </div>
                →
                <div>
                  <MonthYearPicker
                    selectedDate={endDate}
                    onChange={handleEndChange}
                    minDate={startDate}
                    maxDate={today}
                  />
                </div>
              </div>
            </div>
          </div>
<div className="w-full">
          <table className="min-w-full  table-bordered  text-black">
  <thead className="bg-gray-200">
  {/* <tr className="font-medium tracking-normal text-center whitespace-nowrap"> */}

    <tr className=" ">
      <th className="px-5 py-2">Week</th>
      <th className="px-3 py-2">Mon</th>
      <th className="px-3 py-2">Tue</th>
      <th className="px-3 py-2">Wed</th>
      <th className="px-3 py-2">Thur</th>
      <th className="px-3 py-2">Fri</th>
      <th className="px-3 py-2">Sat</th>
      <th className="px-3 py-2">Sun</th>
      <th className="px-3 py-2">Amount</th>
      <th className="px-3 py-2">Status</th>
      <th className="px-3 py-2">Download</th>
      <th className="px-3 py-2">View</th>
      <th className="px-3 py-2">Actions</th>
      <th className="px-3 py-2">Remarks</th>
    </tr>
  </thead>
  <tbody>
    {currentRecords.map((record, index) => (
      <tr key={index} className="justify-center mt-3 bg-purple-50">
        <td className=" py-3 ">
          <p className="text-wrap ">
            {isoWeekToDayAndMonthName(record.year, record.week)}
          </p>
        </td>
        {Object.values(record.timesheet).map((item, idx) => (
          <td key={idx} className=" py-3 px-2 text-center">
            {item < 10 ? `0${item}` : item}
          </td>
        ))}
        <td className=" py-3 px-2 text-center">
          ${rate * record.total_hrs}
        </td>
        <td className=" py-3 px-2 text-center">
          {record.status === "Pending" ? (
            <div className="justify-center self-stretch p-2.5 text-xl leading-7 text-black capitalize whitespace-nowrap bg-red-400 rounded border border-red-800 border-solid">
              {record.status}
            </div>
          ) : record.status === "In Review" ? (
            <div className="justify-center self-stretch p-2.5 text-xl leading-7 text-black capitalize whitespace-nowrap bg-yellow-200 rounded border border-red-800 border-solid">
              {record.status}
            </div>
          ) : record.status === "Approved" ? (
            <div className="justify-center self-stretch p-2.5 text-xl leading-7 text-black capitalize whitespace-nowrap bg-green-400 rounded border border-red-800 border-solid">
              {record.status}
            </div>
          ) : (
            <div className="justify-center self-stretch p-2.5 text-xl leading-7 text-black capitalize whitespace-nowrap bg-blue-400 rounded border border-red-800 border-solid">
              {record.status}
            </div>
          )}
        </td>
        <td className=" py-3 px-2 text-center cursor-pointer">
          {record.image_url === null ? (
            <img
              loading="lazy"
              src={DownloadImageShade}
              className="shrink-0 my-auto w-100 aspect-square h-[40px]"
            />
          ) : (
            <img
              loading="lazy"
              src={DownloadImage}
              className="shrink-0 my-auto w-100 aspect-square h-[40px]"
              onClick={() => downloadFileAt(record.image_url)}
            />
          )}
        </td>
        <td className=" py-3 px-2 text-center cursor-pointer">
          {record.image_url === null ? (
            <img
              loading="lazy"
              src={ViewInactive}
              className="shrink-0 my-auto w-100 aspect-square h-[40px]"
            />
          ) : (
            <img
              loading="lazy"
              src={DarkEye}
              className="shrink-0 my-auto w-100 aspect-square h-[40px]"
              onClick={() => downloadFileAtURL(record.image_url)}
            />
          )}
        </td>
        <td className="	 h-full py-9 px-2 text-center flex flex-row ">
          {record.approval === false ? (
            <>
              <img
                onClick={() => handleSecondFormClick(record._id)}
                loading="lazy"
                src={Accept}
                className="shrink-0 aspect-square w-[30px] cursor-pointer"
              />
              <img
                onClick={() => {
                  setId(record._id);
                  setShowForm(true);
                }}
                loading="lazy"
                src={Deny}
                className="shrink-0 aspect-square w-[30px] cursor-pointer"
              />
            </>
          ) : (
            <>
              <img
                onClick={() => handleSecondFormClick(record._id)}
                loading="lazy"
                src={Accept}
                className="shrink-0 aspect-square w-[30px] cursor-pointer disabled-image"
                style={{ pointerEvents: 'none', opacity: 0.5 }}
              />
              <img
                onClick={() => {
                  setId(record._id);
                  setShowForm(true);
                }}
                loading="lazy"
                src={Deny}
                className="shrink-0 aspect-square w-[30px] cursor-pointer"
              />
            </>
          )}
        </td>
        <td className=" py-3 px-4 text-center">
          <img
            loading="lazy"
            src={message}
            className="shrink-0 aspect-square w-[30px] cursor-pointer"
            onClick={() => showNoteRemark(record.note, record.remark)}
          />
        </td>
      </tr>
    ))}
  </tbody>
  <div className="flex justify-end">
    <div className="flex justify-between">
      <img
        onClick={prevPage}
        disabled={currentPage === 1}
        loading="lazy"
        src={LeftArrow}
        className="shrink-0 w-10 aspect-square cursor-pointer"
        height="30px"
      />
      <div className="my-auto text-xl text-black">{currentPage}</div>
      <img
        onClick={nextPage}
        disabled={currentPage === totalPages}
        loading="lazy"
        src={RightArrow}
        className="shrink-0 w-10 aspect-square cursor-pointer"
        height="30px"
      />
    </div>
  </div>
</table>
</div>
        </div>
      )}
    </>
  );
}

export default AdminDashboardViewTimesheets;


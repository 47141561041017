import axios from 'axios';
import React, { useEffect, useState, useRef } from 'react'
import Edit from '../assets/images/edit.svg'
import Swal from 'sweetalert2'
import Loader from '../loader';
import '../assets/css/ConsultantInfo.css'


const ChangeConsultantInfo = () => {
    const [consultants, setConsultants] = useState([]);
    const [render, setRender] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [form, setShowForm] = useState(false);
    const [id, setId] = useState(0);
    // const [secondId, setSecondId] = useState(0);
    const token = localStorage.getItem('token');
    const headers = {
        Authorization: `${token}`
    };
    const divRef = useRef(null);

    const handleClickOutside = (event) => {
        if (divRef.current && !divRef.current.contains(event.target)) {
            // Perform the action when clicking outside the div
            handleClose();
        }
    };

    useEffect(() => {
        // Add event listener for clicks on the document
        document.addEventListener('mousedown', handleClickOutside);

        // Cleanup the event listener on component unmount
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    const [formData, setFormData] = useState({
        name: '',
        email: '',
        contact_number: '',
        ssn: '',
        residential_address: '',
        location: '',
        visa: '',
        password: ''
    });


    if (!token) {
        console.error('Token not found in local storage');
    }
    const handleEditChanges = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: value
        }));
    };
    const handleClose = () => {
        setShowForm(false);
        setFormData({
            name: '',
            email: '',
            contact_number: '',
            ssn: '',
            residential_address: '',
            location: '',
            visa: '',
            password: ''
        })
        setId(0);
    }

    useEffect(() => {

 
            setIsLoading(true)
            axios.get(`${process.env.REACT_APP_API_URL}/consultants/`, { headers }).then((response) => {
                setIsLoading(false)
               
                setConsultants(response.data);
            }).catch(error => {
                console.error("Error", error);
            })
      
    }, [render])

    const handleEditClick = (name, residential_address, contact_number, ssn, location, email, visa, id) => {
        setFormData({
            name,
            email,
            contact_number,
            ssn,
            residential_address,
            location,
            visa,
            password: ''
        });
        setId(id);
        setShowForm(true);
    }
    const handleSaveChanges = () => {
        const confirmPass = document.getElementById('c-password').value;
   
        if (confirmPass === formData.password) {
            setIsLoading(true)
             if(formData.password==='') {
                delete formData.password;
             }
            axios.put(`${process.env.REACT_APP_API_URL}/consultant/${id}`, formData, { headers }).then((response) => {
            
                setRender(!render);
                setIsLoading(false);
                setShowForm(false);
                setFormData({
                    name: '',
                    email: '',
                    contact_number: '',
                    ssn: '',
                    residential_address: '',
                    location: '',
                    visa: '',
                    password: ''
                })
                setId(0);
                Swal.fire({
                    icon: "success",
                    title: "Updated Successfully",
                    showConfirmButton: false,
                    timer: 1500
                });
            }).catch(err => {
                Swal.fire({
                    icon: "error",
                    title: "Something Went Wrong",
                    showConfirmButton: false,
                    timer: 1500
                });
                console.error(err);
            })

        } else {
            Swal.fire({
                icon: "error",
                title: "Update Failed",
                text: "Password and confirm password not same",
                showConfirmButton: true,
                confirmButtonText: "OK"
            });
        }
    }

    const handleToggle = (value, id) => {
        Swal.fire({
            title: "Are you sure?",
            text: "You won't be able to revert this!",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Yes"
        }).then((result) => {
            if (result.isConfirmed) {
                axios.put(`${process.env.REACT_APP_API_URL}/consultant/${id}`, { status: !value }, { headers }).then((response) => {
                   
                    setRender(!render);
                    Swal.fire({
                        title: "Updated",
                        text: "Status has been updated.",
                        icon: "success"
                    });
                }).catch(err => console.error(err));
                
            }
        });
    }
  
    return (
        <>
        {isLoading ? (
            <Loader/>
        ):(
        <div className='w-full mt-5'>
            {form &&
                (

                    <div
                        style={{
                            position: "fixed",
                            top: 0,
                            left: 0,
                            width: "100%",
                            height: "100%",
                            backgroundColor: "rgba(0, 0, 0, 0.5)",
                            backdropFilter: "blur(5px)",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            zIndex: 999,
                        }}
                    >
                        <div
                            ref={divRef}
                            style={{
                                backgroundColor: "rgba(255, 255, 255, 0.9)",
                                padding: "20px",
                                borderRadius: "10px",
                                width: "700px",
                                height: "589px",

                                position: "relative",
                            }}
                        >
                            <div className='flex relative justify-center align-items-center w-full h-full'>
                                <button
                                    type="button"
                                    style={{
                                        position: "absolute",
                                        top: "2px",
                                        right: "2px",
                                        fontSize: "15px",
                                        fontWeight: "bold",
                                        cursor: "pointer",
                                    }}
                                    className="btn-close"
                                    onClick={handleClose}
                                    aria-label="Close"
                                ></button>
                                <div className='flex flex-col w-[50%] h-full'>
                                    <div className='w-full h-[30%]'>
                                        <div className="flex flex-col w-full h-full">
                                            {/* <h2 className='text-lg px-2'>Personal Info</h2> */}
                                            <span className='ml-2 mt-2 font-bold'>Full Name</span>
                                            <input
                                                type="text"
                                                name="name"
                                                value={formData.name}
                                                onChange={handleEditChanges}
                                                className='mt-1 w-[300px] h-[48px] rounded-[8px] border-1 px-3 outline-none'
                                                placeholder='Full Name'
                                                style={{ borderColor: "#9F9F9F" }}
                                            />
                                            <span className='ml-2 mt-2 font-bold'>SSN</span>
                                            <input
                                                type="text"
                                                name="ssn"
                                                value={formData.ssn}
                                                onChange={handleEditChanges}
                                                className='mt-1 w-[300px] h-[48px] rounded-[8px] border-1 px-3 outline-none'
                                                placeholder='SSN'
                                                style={{ borderColor: "#9F9F9F" }}
                                            />
                                        </div>
                                    </div>
                                    <div className='w-full h-[20%] mt-1'>
                                        {/* <h2 className='text-lg px-2'>Current Location</h2> */}
                                        <span className='ml-2 mt-2 font-bold'>Residential Address</span>
                                        <input
                                            type="text"
                                            name="residential_address"
                                            value={formData.residential_address}
                                            onChange={handleEditChanges}
                                            className='mt-1 w-[300px] h-[48px] rounded-[8px] px-3 outline-none border-1'
                                            placeholder='Residential Address'
                                            style={{ borderColor: "#9F9F9F" }}
                                        />
                                    </div>
                                    <div className='w-full h-[40%]'>
                                        {/* <h2 className='text-lg px-2'>Login Info</h2> */}
                                        <span className='ml-2 mt-3 font-bold'>Email</span>
                                        <input
                                            type="email"
                                            name="email"
                                            value={formData.email}
                                            onChange={handleEditChanges}
                                            className='mt-1 w-[300px] h-[48px] rounded-[8px] px-3 outline-none border-1 mb-2'
                                            placeholder='Email'
                                            style={{ borderColor: "#9F9F9F" }}
                                        />
                                        <span className='ml-2 mt-4 pt-2 font-bold'>Password</span>
                                        <input
                                            type="password"
                                            name='password'
                                            onChange={handleEditChanges}
                                            className='mt-1 w-[300px] h-[48px] rounded-[8px] px-3 outline-none border-1 mb-2'
                                            placeholder='New Password'
                                            style={{ borderColor: "#9F9F9F" }}
                                        />
                                        <span className='ml-2 mt-2 font-bold'>Confirm Password</span>
                                        <input
                                            type="password"
                                            id='c-password'
                                            className='mt-1 w-[300px] h-[48px] rounded-[8px] px-3 outline-none border-1 '
                                            placeholder='Confirm New Password'
                                            style={{ borderColor: "#9F9F9F" }}
                                        />
                                    </div>
                                </div>
                                <div className='w-[50%] h-full'>
                                    <div className='w-full h-[30%]'>
                                        <div className="flex flex-col w-full h-full">
                                            <span className='ml-2 mt-2 font-bold'>Contact Number</span>
                                            <input
                                                type="text"
                                                name="contact_number"
                                                value={formData.contact_number}
                                                onChange={handleEditChanges}
                                                style={{ marginTop: "5px", borderColor: "#9F9F9F" }}
                                                className='w-[300px] h-[48px] rounded-[8px] border-1 px-3 outline-none border-1'
                                                placeholder='Contact Number'
                                            // style={{borderColor:"#9F9F9F"}}
                                            />
                                            <span className='ml-2 mt-2 font-bold'>Visa</span>
                                            <input
                                                type="text"
                                                name="visa"
                                                value={formData.visa}
                                                onChange={handleEditChanges}
                                                className='mt-1 w-[300px] h-[48px] rounded-[8px] px-3 outline-none border-1'
                                                placeholder='Visa'
                                                style={{ borderColor: "#9F9F9F" }}
                                            />
                                        </div>
                                    </div>
                                    <div className='w-full h-[30%] mt-1'>
                                        <div className="flex flex-col w-full h-full">
                                            <span className='ml-2 font-bold'>Location</span>
                                            <input
                                                type="text"
                                                name="location"
                                                value={formData.location}
                                                onChange={handleEditChanges}
                                                style={{ marginTop: "5px", borderColor: "#9F9F9F" }}
                                                className='w-[300px] h-[48px] rounded-[8px] px-3 outline-none border-1'

                                                placeholder='Location'
                                            />
                                        </div>
                                        <div className="flex flex-col w-full h-full">
                                            <button
                                                className='w-[248px] h-[48px] rounded-[10px] text-white mt-5 ml-3 cursor-pointer'
                                                style={{ backgroundColor: "#421A84" }}
                                                onClick={handleSaveChanges}
                                            >
                                                Save Changes
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>


                        </div>
                    </div>

                )
            }
            <div className="justify-center w-[29%] p-6 text-3xl font-semibold leading-7 text-violet-900 capitalize bg-purple-50 rounded-lg shadow-lg">
                Manage Consultant
            </div>
            <div className='w-full mt-4'>
                <div className='flex justify-center align-items-center'>
                    <table className='w-full ml-5'>
                        <thead>
                            <tr>
                                <th className='w-[280px] text-lg text-center'>Consultant</th>
                                <th className='w-[280px] text-lg text-center' >Edit</th>
                                <th className='w-[280px] text-lg text-center'>Status</th>
                            </tr>
                        </thead>
                        <tbody>
                            {consultants && consultants.map((item) => {
                                return (
                                    <tr className='text-center'>
                                        <td className='text-lg'>{item.name}</td>
                                        <td >
                                            <img src={Edit} height="35px" width="35px" style={{
                                                position: "relative",
                                                left: "45%"

                                            }} onClick={() => handleEditClick(item.name, item.residential_address, item.contact_number, item.ssn, item.location, item.email, item.visa, item._id)} alt="" />
                                        </td>
                                        <td>
                                            <label class="switch mt-2">
                                                <input type="checkbox" checked={item.status === true ? true : false} onChange={() => handleToggle(item.status, item._id)} id="togBtn" />
                                                <div class="slider round">
                                                    <span class="on">Active</span>
                                                    <span class="off">Inactive</span>
                                                </div>
                                            </label>
                                        </td>
                                    </tr>
                                )
                            })}


                        </tbody>
                    </table>
                </div>
            </div>
        </div>
        )};
        </>
    )
}

export default ChangeConsultantInfo

import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import Loader from "../loader";
import DarkEye from '../assets/images/carbon_view-filled.svg';

export default function AdminDashboardProjectDetail() {
  const navigate = useNavigate();
  const [clientData, setClientData] = useState(null);
  const [consultant, setConsultant] = useState({});
  const [jobrole, setJobrole] = useState("");
  const [payType, setPayType] = useState("");
  const [rate, setRate] = useState(0);
  const [vendorData, setVendorData] = useState([]);
  const [companyPocData, setCompanyPocData] = useState([]);
  const location = useLocation();
  const { projectId } = location.state;
  const [isLoading, setIsLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [selectedPoc, setSelectedPoc] = useState([]);

  useEffect(() => {
    const token = localStorage.getItem("token");
    if (!token) {
      console.error("Token not found in local storage");
      return;
    }

    const headers = {
      Authorization: `${token}`,
    };
    setIsLoading(true);
    axios
      .get(`${process.env.REACT_APP_API_URL}/projects/${projectId}`, { headers })
      .then((response) => {
        setIsLoading(false);
        setConsultant(response.data.consultant_id);
        setJobrole(response.data.job_role);
        setPayType(response.data.rate_type);
        setRate(response.data.rate);
        setVendorData(response.data.vendor_ids);
        setClientData(response.data.client_id);
        setCompanyPocData(response.data.company_poc_ids);
      })
      .catch((error) => {
        if (error.response.status === 401) {
          console.error("Failed to fetch week data:");
          alert("Session Expired Please Login");
          navigate("/admin");
        }
        console.error("Error fetching user data:", error);
      });
  }, [projectId, navigate]);

  const getPocDetails = (companyId) => {
    return companyPocData.filter((poc) => poc.company_id === companyId);
  };

  const openModal = (companyId) => {
    const pocDetails = getPocDetails(companyId);
    setSelectedPoc(pocDetails);
    setShowModal(true);
  };

  const closeModal = () => {
    setShowModal(false);
  };

  const handleClickOutside = (event) => {
    if (event.target.id === "modal-background") {
      closeModal();
    }
  };

  return (
    <>
      {isLoading ? (
        <Loader />
      ) : (
        <div className="flex flex-col items-start self-center pt-24 w-full max-w-full">
          <div className="flex gap-1 ml-10 text-base font-semibold leading-7 text-violet-900 capitalize max-md:ml-2.5">
            <nav aria-label="breadcrumb">
              <ol className="breadcrumb">
                <a href="/admin/adminDashboard">
                  <li className="breadcrumb-item">HomeDashboard/</li>
                </a>
                <a
                  href=""
                  onClick={() => {
                    navigate("/admin/adminDashboard/viewTimesheets", {
                      state: {
                        projectId: projectId,
                      },
                    });
                  }}
                >
                  <li className="breadcrumb-item">Timesheet/</li>
                </a>
                <li className="breadcrumb-item active" aria-current="page">
                  Project Information
                </li>
              </ol>
            </nav>
          </div>
          <div className="justify-center p-6 mt-9 ml-10 text-4xl font-semibold leading-7 text-violet-900 capitalize bg-purple-50 rounded-lg shadow-lg max-md:px-5 max-md:ml-2.5">
            Project Information
          </div>

          <div className="grid gap-10 mt-20 max-md:mt-10 max-md:max-w-full w-full">
            <div className="max-md:w-full">
              <div className="flex flex-col grow px-5 text-black capitalize max-md:mt-10">
                <div className="text-3xl font-bold leading-7">Consultant Info.</div>
                <table className="w-full mt-6 border-collapse border border-gray-400">
                  <tbody>
                    <tr>
                      <td className="border border-gray-300 p-2 font-bold">Consultant:</td>
                      <td className="border border-gray-300 p-2">{consultant.name}</td>
                    </tr>
                    <tr>
                      <td className="border border-gray-300 p-2 font-bold">Job Role:</td>
                      <td className="border border-gray-300 p-2">{jobrole}</td>
                    </tr>
                    <tr>
                      <td className="border border-gray-300 p-2 font-bold">Pay Type:</td>
                      <td className="border border-gray-300 p-2">{payType}</td>
                    </tr>
                    <tr>
                      <td className="border border-gray-300 p-2 font-bold">Pay Rate:</td>
                      <td className="border border-gray-300 p-2">${rate}/hr</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>

            <div className="max-md:w-full">
              <div className="flex flex-col grow px-5 text-black  max-md:mt-10 max-md:max-w-full">
                <div className="text-3xl font-bold leading-7 max-md:max-w-full">Vendor Info.</div>
                <table className="w-full mt-6 border-collapse border border-gray-400">
                  <thead>
                    <tr>
                      <th className="border border-gray-300 p-2">Company Name</th>
                      <th className="border border-gray-300 p-2">Email</th>
                      <th className="border border-gray-300 p-2">Contact Number</th>
                      <th className="border border-gray-300 p-2">Website</th>
                      <th className="border border-gray-300 p-2">POC Details</th>
                    </tr>
                  </thead>
                  <tbody>
                    {vendorData.map((vendor, index) => (
                      <tr key={index}>
                        <td className="border border-gray-300 p-2">{vendor.company_name}</td>
                        <td className="border border-gray-300 p-2">{vendor.email}</td>
                        <td className="border border-gray-300 p-2">{vendor.contact_number}</td>
                        <td className="border border-gray-300 p-2">{vendor.website}</td>
                        <td className="border border-gray-300 p-2">
                          <div className="flex justify-center items-center">
                            <img
                              loading="lazy"
                              src={DarkEye}
                              className="w-8 h-8 cursor-pointer"
                              onClick={() => openModal(vendor._id)}
                            />
                          </div>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>

            <div className="max-md:w-full">
              <div className="flex flex-col grow px-5 text-black  max-md:mt-10 max-md:max-w-full">
                <div className="text-3xl font-bold leading-7 max-md:max-w-full">Client Info.</div>
                {clientData && (
                  <table className="w-full mt-6 border-collapse border border-gray-400">
                    <thead>
                      <tr>
                        <th className="border border-gray-300 p-2">Company Name</th>
                        <th className="border border-gray-300 p-2">Email</th>
                        <th className="border border-gray-300 p-2">Contact Number</th>
                        <th className="border border-gray-300 p-2">Website</th>
                        <th className="border border-gray-300 p-2">POC Details</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td className="border border-gray-300 p-2">{clientData.company_name}</td>
                        <td className="border border-gray-300 p-2">{clientData.email}</td>
                        <td className="border border-gray-300 p-2">{clientData.contact_number}</td>
                        <td className="border border-gray-300 p-2">{clientData.website}</td>
                        <td className="border border-gray-300 p-2">
                          <div className="flex justify-center items-center">
                            <img
                              loading="lazy"
                              src={DarkEye}
                              className="w-8 h-8 cursor-pointer"
                              onClick={() => openModal(clientData._id)}
                            />
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                )}
              </div>
            </div>
          </div>

          {showModal && (
            <div
              id="modal-background"
              className="fixed inset-0 bg-gray-800 bg-opacity-75 flex justify-center items-center"
              onClick={handleClickOutside}
            >
              <div className="bg-white p-6 rounded-lg shadow-lg w-11/12 md:w-1/2">
                <div className="flex justify-between items-center mb-4">
                  <h2 className="text-xl font-bold">POC Details</h2>
                  <button
                    className="text-gray-700 hover:text-gray-900 text-3xl"
                    onClick={closeModal}
                  >
                    &times;
                  </button>
                </div>
                <table className="w-full mt-6 border-collapse border border-gray-400">
                  <thead>
                    <tr>
                      <th className="border border-gray-300 p-2">Name</th>
                      <th className="border border-gray-300 p-2">Email</th>
                      <th className="border border-gray-300 p-2">Contact Number</th>
                      <th className="border border-gray-300 p-2">Job Role</th>
                    </tr>
                  </thead>
                  <tbody>
                    {selectedPoc.map((poc, index) => (
                      <tr key={index}>
                        <td className="border border-gray-300 p-2">{poc.name}</td>
                        <td className="border border-gray-300 p-2">{poc.email_address}</td>
                        <td className="border border-gray-300 p-2">{poc.contact_number}</td>
                        <td className="border border-gray-300 p-2">{poc.job_role}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          )}
        </div>
      )}
    </>
  );
}

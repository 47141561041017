import React, { useEffect, useState } from 'react'

const TimeDisplay = () => {
    const [currentTime, setCurrentTime] = useState(new Date());

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentTime(new Date());
    }, 1000);

    return () => clearInterval(interval);
  }, []);
  const dayName = currentTime.toLocaleDateString('en-US', { weekday: 'long' });
  const hours = currentTime.getHours();
  const minutes = currentTime.getMinutes();
  const seconds = currentTime.getSeconds();
  return (
    
    <div className="Div32">
      {currentTime.toDateString()} ({dayName}), {hours < 10 ? '0' + hours : hours}:{minutes < 10 ? '0' + minutes : minutes}:{seconds < 10 ? '0' + seconds : seconds}
    </div>
    
  )
}

export default TimeDisplay

import logo from "../assets/images/logo.png";
import logout from "../assets/images/majesticons_logout.svg"

import { useLocation, useNavigate } from 'react-router-dom';
const inactive = "relative px-6 py-4 ml-5 text-center text-violet-900 bg-purple-50 rounded-lg border border-violet-900 border-solid appearance-none cursor-pointer";
const active = "relative px-6 py-4 ml-5 text-center text-white bg-violet-900 rounded-lg border border-violet-900 border-solid appearance-none cursor-pointer";
function Navbar() {
  const location = useLocation();
  const navigate = useNavigate();
  const path = location.pathname;


  return (
    <div className="max-md:max-w-full">
      <div className="flex gap-7 max-md:flex-col max-md:gap-0">
        <div className="flex flex-col w-3/12 max-md:ml-0 max-md:w-full">
          <img
            loading="lazy"
            srcSet={logo}
            className="grow shrink-0 max-w-full aspect-[1.75] w-[223px] max-md:mt-10"
          />
        </div>
        <div className="flex flex-col ml-5 w-9/12 max-md:ml-0 max-md:w-full">
          <div className="flex gap-10 justify-center self-stretch pr-16 my-auto text-xl font-semibold leading-7 text-violet-900 capitalize max-md:flex-wrap max-md:mt-10 max-md:max-w-full">
            <button
              className={path === "/admin/adminDashboard" ? active : path === "/admin/adminDashboard/viewTimesheets" ? active : path === "/admin/adminDashboard/viewProjectInfo" ? active : inactive}
              onClick={() => navigate('/admin/adminDashboard')}
            >
              Home
            </button>
            <button
              className={path === "/admin/adminDashboard/addConsultant" ? active : inactive}
              onClick={() => navigate('/admin/adminDashboard/addConsultant')}
            >
              Add Consultant
            </button>
            <button
              className={path === "/admin/adminDashboard/assignProject" ? active : inactive}
              onClick={() => navigate('/admin/adminDashboard/assignProject')}
            >
              Assign Project
            </button>
            <button
              className={inactive}
              onClick={() => {
                localStorage.setItem("token", "");
                localStorage.setItem("otpVerified","");
                // localStorage.setItem("usertype","");
                navigate('/admin');
              }}
            >
              <img srcSet={logout} alt="Logout Icon" style={{ marginRight: 1 }} />Logout
            </button>
            
          </div>
        </div>
      </div>
    </div>
  )
}
export default Navbar;
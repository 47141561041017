import React, { useState,useEffect } from 'react';
import Swal from 'sweetalert2';
import axios from 'axios';
import { useLocation, useNavigate } from 'react-router-dom';
import Loader from '../components/loader'

export default function ConsultantNewPassword() {
    let location = useLocation();
    const navigate = useNavigate();
    const { email } = location.state || {};
    
    const [password, setPassword] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [confirmPassword, setConfirmPassword] = useState('');
    const [error, setError] = useState('');

    useEffect(() => {
        const token = localStorage.getItem('token');
        const otpVerified = localStorage.getItem('otpVerified');
        if (token && otpVerified) {
          navigate("/consultant/consultantDashboard")
        }else{
            navigate("/")
        }
        setIsLoading(false); // Set loading to false after checking the token
      }, []);
    
    const handlePasswordChange = (event) => {
        setPassword(event.target.value);
    };

    const handleConfirmPasswordChange = (event) => {
        setConfirmPassword(event.target.value);
    };

    const handleSubmit = async () => {
        if (password !== confirmPassword) {
            setError('Passwords do not match.');
            return;
        }
        else if (password.length < 8) {
            setError('Password must be at least 8 characters.');
            return;
        } else {
            const tempToken = localStorage.getItem('tempToken')
            const formData = {
                token: tempToken,
                newPassword: password
            }
            setIsLoading(true);

            try {
                const response = await axios.post(`${process.env.REACT_APP_API_URL}/consultants/initialResetPassword`,  formData );
                setIsLoading(false); 
                if (response.status === 200) {
                    Swal.fire({
                        icon: 'success',
                        title: 'Your have successfully changed your password',
                        showConfirmButton: false,
                        timer: 1500
                      }).then(() => {
                        localStorage.removeItem("tempToken")
                        navigate(-1);
                      }); 
                } else {
                    Swal.fire({
                        icon: "error",
                        title: "Oops...",
                        text: "Failed to set the new password contact the Admin",
                      }).then(() => {
                        navigate(-1);
                      });
                    setError('Failed to verify password.');
                }
            } catch (error) {
                    if (error.response.status == 401) {
                        alert("Session Expired Please Login");
                        navigate("/");
                    }
                setError('An error occurred during password verification.');
                console.error(error);
            }
        }
    };

    return (
        <>
        {isLoading ? (
        <Loader/> // You can replace this with your own loading indicator component
      ) : (
            <div className="flex justify-center items-center px-16 py-20 text-base font-light text-black bg-purple-50 max-md:px-5">
                <div className="flex flex-col px-10 py-8 mt-20 max-w-full bg-white rounded-xl shadow-lg w-[457px] max-md:px-5 max-md:mt-10">
                    <img
                        loading="lazy"
                        srcSet="https://cdn.builder.io/api/v1/image/assets/TEMP/0342a4f251516866d2657b87036005d8a41f342b50c0c98d8753bb4538a7304c?apiKey=64c677c5ce594c56a18b86a88b37179e&width=100 100w, https://cdn.builder.io/api/v1/image/assets/TEMP/0342a4f251516866d2657b87036005d8a41f342b50c0c98d8753bb4538a7304c?apiKey=64c677c5ce594c56a18b86a88b37179e&width=200 200w, https://cdn.builder.io/api/v1/image/assets/TEMP/0342a4f251516866d2657b87036005d8a41f342b50c0c98d8753bb4538a7304c?apiKey=64c677c5ce594c56a18b86a88b37179e&width=400 400w, https://cdn.builder.io/api/v1/image/assets/TEMP/0342a4f251516866d2657b87036005d8a41f342b50c0c98d8753bb4538a7304c?apiKey=64c677c5ce594c56a18b86a88b37179e&width=800 800w, https://cdn.builder.io/api/v1/image/assets/TEMP/0342a4f251516866d2657b87036005d8a41f342b50c0c98d8753bb4538a7304c?apiKey=64c677c5ce594c56a18b86a88b37179e&width=1200 1200w, https://cdn.builder.io/api/v1/image/assets/TEMP/0342a4f251516866d2657b87036005d8a41f342b50c0c98d8753bb4538a7304c?apiKey=64c677c5ce594c56a18b86a88b37179e&width=1600 1600w, https://cdn.builder.io/api/v1/image/assets/TEMP/0342a4f251516866d2657b87036005d8a41f342b50c0c98d8753bb4538a7304c?apiKey=64c677c5ce594c56a18b86a88b37179e&width=2000 2000w, https://cdn.builder.io/api/v1/image/assets/TEMP/0342a4f251516866d2657b87036005d8a41f342b50c0c98d8753bb4538a7304c?apiKey=64c677c5ce594c56a18b86a88b37179e&"
                        className="self-center max-w-full aspect-[5.88] w-[355px]"
                    />
                    <div className="self-center mt-4 text-3xl font-medium text-center">
                        Welcome
                    </div>
                    <div className="mt-8 text-xl font-medium text-center">
                        Create a strong password
                    </div>

                    <div className="mt-6">
                        Create a new, strong password that you don’t use for other websites
                    </div>
                    <input
                        type="password"
                        value={password}
                        onChange={handlePasswordChange}
                        placeholder="Create Password"
                        className="mt-6 p-2.5 w-full border border-black border-solid rounded"
                    />
                    <input
                        type="password"
                        value={confirmPassword}
                        onChange={handleConfirmPasswordChange}
                        placeholder="Confirm Password"
                        className="mt-4 p-2.5 w-full border border-black border-solid rounded"
                    />
                    <div className="mt-2 text-sm">At least 8 characters</div>

                    {error && <div className="text-red-500">{error}</div>}
                    <button
                        onClick={handleSubmit}
                        className="justify-center self-end p-2.5 mt-16 text-2xl font-semibold text-center whitespace-nowrap bg-violet-900 rounded-lg shadow-sm text-zinc-100 max-md:px-5 max-md:mt-10"
                    >
                        Next
                    </button>
                </div>
            </div>)}
        </>
    )
}

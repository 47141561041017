import { useRef, useState, useEffect } from 'react';
import { useAuth } from './AuthContext';
import axios from 'axios';
import { useLocation } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import Loader from '../components/loader'
import LoginLogo from "../components/assets/images/loginLogo.svg";
import Swal from 'sweetalert2'

function AuthenticationCodePage() {
  const { authInfo } = useAuth();
  const { email, password } = authInfo;
  // const location = useLocation();
  // const { email, password } = location.state || {};
  const [otp, setOtp] = useState('');
  const navigate = useNavigate();
  const [isVerified, setIsVerified] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  // Ensure secure handling


  console.log("Hello")
  useEffect(() => {
    const token = localStorage.getItem('token');
    const otpVerified = localStorage.getItem('otpVerified');
    console.log(token)
    
    if (token && otpVerified) {
      setIsVerified(true);
    }
    setIsLoading(false);
    sessionStorage.removeItem('canAccessTwoFA'); // Set loading to false after checking the token
  }, []);


  const handleOTPChange = (e) => {
    setOtp(e.target.value);
  };

  const verifyOTP = async () => {
    // e.preventDefault();
    setIsLoading(true);
    try {
      const response = await axios.post(`${process.env.REACT_APP_API_URL}/consultants/verifyMfa`, {
        email,
        token: otp
      });
      setIsLoading(false);
      if (response.status === 200) {
        localStorage.setItem('token', response.data.token);
        localStorage.setItem('otpVerified', 'true');
        setIsVerified(true);

        Swal.fire({
          icon: "success",
          title: "Logged In Successfully!",
          showConfirmButton: false,
          timer: 1500
        }).then(() => {
          navigate('/consultant/consultantDashboard');
        });
      } else {

        throw new Error('OTP Verification failed: ' + response.data.message);
      }
    } catch (error) {
      if (error.response.status == 401) {


        alert("Session Expired Please Login");
        navigate("/");
      }

      console.error('Error verifying OTP:', error);


      if (error.response && error.response.status === 401) {
        navigate("/");
      }


      Swal.fire({
        title: "Error",
        text: "Failed to verify OTP. Please try again.",
        icon: "error"
      });
    }

  };

  const resendOTP = async () => {
    setIsLoading(true);
    try {
      const response = await axios.post(`${process.env.REACT_APP_API_URL}/consultants/login/`, {
        email,
        password
      }).then(() => {
        Swal.fire({
          icon: "success",
          title: "OTP Sent Please Check Your Email",
          showConfirmButton: false,
          timer: 1500
        });
      })
      setIsLoading(false);


    } catch (error) {
      console.error('Error resending OTP:', error);
    }
  };

  var lengthofCode = 6;
  const inputRef = useRef(Array(lengthofCode).fill(0));

  const handleTextChange = (input, index) => {
    const newPin = [...otp];
    newPin[index] = input;
    setOtp(newPin);

    if (input.length === 1 && index < lengthofCode - 1) {
      inputRef.current[index + 1]?.focus();
    }
    if (input.length === 0 && index > 0) {
      inputRef.current[index - 1]?.focus();
    }
    if (newPin.every((digit) => digit !== '')) {
      setOtp(newPin.join(''));
    }

  };

  const handlePaste = (e) => {
    e.preventDefault();
    const pasteData = e.clipboardData.getData('text/plain').trim().slice(0, lengthofCode);

    if (pasteData.length === lengthofCode) {
      setOtp(pasteData);
    }
  };

  const handleKeyPress = (event, index) => {
    if (index === 5) {
      if (event.key === 'Enter') {
        verifyOTP();
      }
    }

  };

  // const [isLoading, setIsLoading] = useState(true);
  if (isLoading) {
    return <Loader />; // You can replace this with your own loading indicator component
  }

  if (isVerified) {
    navigate("/consultant/consultantDashboard")// Do not render the main component if token is set and OTP is verified
  }

  return (
    <>
      {/* {isLoading ? (
        <Loader /> // You can replace this with your own loading indicator component
      ) : ( */}
      <main className="flex justify-center items-center px-16 py-20 text-2xl h-[100vh] text-center bg-purple-50 max-md:px-5">
        <div className="flex flex-col items-center mt-6 max-w-full w-[969px]">
          <img
            loading="lazy"
            src={LoginLogo}
            alt="Authentication Code Illustration"
            className="max-w-full aspect-[1.72] w-[355px]"
          />
          <h1 className="mt-4 text-6xl font-semibold text-black max-md:max-w-full max-md:text-4xl">
            Enter Authentication Code
          </h1>
          <p className="self-stretch mt-8 font-light text-black max-md:max-w-full">
            We've sent an authentication code to your{" "}
            <span className="font-bold">Email</span> for signing into Technman
            Timesheet
          </p>
          <form onSubmit={verifyOTP} className="shrink-0 mt-14 max-w-full rounded-xl border-black  border-solid border-[3px] h-[87px] w-[582px] max-md:mt-10">
            <label htmlFor="authCode" className="sr-only">
              Authentication Code
            </label>
            <div className="grid grid-cols-6 gap-2" onPaste={handlePaste}>
              {
                Array(lengthofCode).fill(0).map((_, index) => {
                  return <input
                    key={index}
                    type="text"
                    placeholder="0"
                    maxLength="1"
                    value={otp[index]}
                    ref={(ref) => (inputRef.current[index] = ref)}
                    style={{ borderRight: index === lengthofCode - 1 ? '0px' : '3px solid black' }}
                    className="  bg-transparent h-[84px] w-100 text-lg border-black border-solid  text-center  focus:border-blue-600  outline-none"
                    onChange={(e) => handleTextChange(e.target.value, index)}
                    onKeyPress={(e) => handleKeyPress(e, index)}
                  />
                })
              }
            </div>
          </form>
          <div className="flex gap-5 justify-between mt-16 max-w-full w-[405px] max-md:mt-10">
            <button
              type="submit"
              className="justify-center p-2.5 font-semibold whitespace-nowrap bg-violet-900 rounded-lg shadow-sm text-zinc-100 max-md:px-5"
              onClick={verifyOTP}
            >
              Verify
            </button>
            <button
              type="button"
              className="justify-center p-2.5 font-light text-black rounded-lg border border-black border-solid"
              onClick={resendOTP}
            >
              Resend Code
            </button>
          </div>
        </div>
      </main>
      {/* )} */}
    </>
  );
}

export default AuthenticationCodePage;

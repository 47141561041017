import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

import Loader from '../loader'
export default function PersonalInfoForm() {
  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState(false);
  const token = localStorage.getItem('token');
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    contact_number: '',
    ssn: '',
    residential_address: '',
    location: '',
    visa: ''
  });
  const [originalData, setOriginalData] = useState({});

  useEffect(() => {
    if (!token) {
      console.error('Token not found in local storage');
      return;
    }
    setIsLoading(true);

    const headers = { Authorization: `${token}` };
    axios.get(`${process.env.REACT_APP_API_URL}/consultant`, { headers })
      .then(response => {
        setIsLoading(false); 
        const { name, email, contact_number, ssn, residential_address, location, visa } = response.data;
        const newData = {
          name,
          email,
          contact_number,
          ssn,
          residential_address,
          location,
          visa
        };
        setFormData(newData);
        setOriginalData(newData); // Save the original data for reset functionality
      })
      .catch(error => {
        if (error.response.status == 401) {


          alert("Session Expired Please Login");
          navigate("/");
      }
        console.error('Failed to fetch user data:', error);
      });
  }, [token]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prev => ({ ...prev, [name]: value }));
  };

  const handleSubmit = () => {
    const headers = { Authorization: `${token}` };
    setIsLoading(true);
    axios.put(`${process.env.REACT_APP_API_URL}/consultant`, formData, { headers })
      .then(response => {
       
        setIsLoading(false);
      })
      .catch(error => {
        console.error('Failed to update user data:', error);
      });

  };

  const handleReset = () => {
    setFormData(originalData);
  };

  // Helper function to create a title case label from a field name
  const createLabel = (fieldName) => {
    return fieldName.replace(/([A-Z])/g, ' $1') // insert a space before all caps
                     .replace(/^./, str => str.toUpperCase()) // capitalize the first letter
                     .replace('Ssn', 'SSN') // special case for acronyms
                     .replace('Residential_address', 'Residential Address')
                     .replace('Contact_number','Contact Number');

  };

  return (
    <>
    {isLoading ? (
        <Loader/> // You can replace this with your own loading indicator component
      ) : (
    <div className="flex flex-col pt-8 bg-purple-50">
      <div className="flex flex-col self-center w-full max-w-[1087px]">
        <form onSubmit={(e) => { e.preventDefault(); handleSubmit(); }} className="p-5 grid grid-cols-2 gap-4">
          {Object.entries(formData).map(([key, value]) => (
            <div key={key} className="col-span-1">
              <label htmlFor={key} className="block text-sm font-medium text-gray-700">
                {createLabel(key)}
              </label>
              <input
                type="text"
                name={key}
                id={key}
                value={value}
                onChange={handleChange}
                disabled={key === 'name' || key === 'ssn' || key === 'email'|| key==='visa'}
                placeholder={createLabel(key)}
                className="mt-1 p-2.5 m-1 border rounded-lg block w-full"
                required
              />
               <div className="invalid-feedback">
                  Please fill the details
              </div>
            </div>
          ))}
          <div className="flex gap-5 justify-between mt-10 text-2xl leading-7 col-span-2">
            <button type="submit" className="flex-1 justify-center p-2.5 text-purple-50 bg-violet-900 rounded-lg shadow-sm">
              Save Changes
            </button>
            <button type="button" onClick={handleReset} className="flex-1 justify-center p-2.5 text-violet-900 rounded-lg shadow-sm bg-neutral-300">
              Reset Changes
            </button>
          </div>
        </form>
      </div>
    </div>)}
    </>
  );
}

import { useEffect, useState, useRef } from "react";
import axios from 'axios';
import moment from 'moment';
import DisabledUpload from '../assets/images/material-symbols_upload.svg';
import Swal from 'sweetalert2';
import Upload from "../assets/images/upload.svg";
import Accept from "../assets/images/Accept.svg";
import Deny from "../assets/images/Deny.svg";
import DarkEye from '../assets/images/carbon_view-filled.svg';
import Loader from '../loader';
import ViewInactive from "../assets/images/view inactive.svg"
import Calender from "../assets/images/Calendar.svg"
import DatePicker from 'react-datepicker';
import LeftArrow from "../assets/images/chevron left.svg"
import RightArrow from "../assets/images/chevron.svg"
import 'react-datepicker/dist/react-datepicker.css';
import { useLocation } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';

const pageSize = 10;

const MonthYearPicker = ({ selectedDate, onChange, minDate, maxDate }) => {
  return (
    <DatePicker
      selected={selectedDate}
      onChange={onChange}
      dateFormat="MMMM/yyyy"
      showMonthYearPicker
      minDate={minDate}
      maxDate={maxDate}
      placeholderText="Select month and year"
      className='w-[120px] rounded-[4px]'
    />
  );
};

function ViewPreviousTimesheets() {
  const navigate = useNavigate();
  const location = useLocation();
  // var { projectId,name } = location.state;
  
  var { projectId,name } = location.state || {};
  
  const fileInputRef = useRef(null);
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState([]);
  const [projectStartMonth, setProjectStartMonth] = useState('');
  const [projectEndMonth, setProjectEndMonth] = useState('');
  const today = new Date();
  const threeMonthsAgo = new Date(today);
  threeMonthsAgo.setMonth(threeMonthsAgo.getMonth() - 3);

  const formatToMonthYear = (date) => {
    const year = date.getFullYear();
    const month = date.getMonth() + 1; // Months are zero-based
    return `${year}/${month}`;
  };

  const [startDate, setStartDate] = useState(threeMonthsAgo);
  const [endDate, setEndDate] = useState(today);

  const [currentPage, setCurrentPage] = useState(1);
  const downloadFileAtURL = (image_url) => {
    const url = image_url;
    const atag = document.createElement('a');
    atag.href = url;
    atag.setAttribute('target', '_blank');
    document.body.appendChild(atag);
    atag.click();
    atag.remove();
  };
  const [id, setId] = useState('')
  const [refresh, setRefresh] = useState(false)
  const [clients, setClients] = useState([])
  const [selectedClient, setSelectedClient] = useState('')
  const [initialId, setInitialId] = useState(projectId || 0)
  const [clientName,setClientName]=useState("")
  const totalPages = Math.ceil(data.length / pageSize);
  const startIndex = (currentPage - 1) * pageSize;
  const endIndex = Math.min(startIndex + pageSize, data.length);
  const currentRecords = data.slice(startIndex, endIndex);

  // function isoWeekToDayAndMonthName(year, week) {
  //   const firstDayOfWeek = new Date(year, 0, (week - 1) * 7 + 1);
  //   let dayOfMonth = firstDayOfWeek.getDate();
  //   const monthName = firstDayOfWeek.toLocaleString('en-US', { month: 'long' }).slice(0, 3);

  //   if (dayOfMonth < 10) {
  //     dayOfMonth = `0${dayOfMonth}`;
  //   }

  //   return `${monthName} ${dayOfMonth}`;
  // }

  function isoWeekToDayAndMonthName(year, week) {
    const startDate = moment().year(year).week(week).startOf('isoWeek');
    const endDate = startDate.clone().add(6, 'days'); // Monday to Sunday
    const monthName = startDate.format('MMMM');
    const monthWeekNumber = Math.ceil(startDate.date() / 7);
    // return `Week ${monthWeekNumber} of ${monthName} (${startDate.format('MMMM Do')} to ${endDate.format('MMMM Do, YYYY')})`;

    return ` ${startDate.format('MMMM Do')} to ${endDate.format('MMMM Do, YYYY')}`;
  }

  const nextPage = () => {
    setCurrentPage(prevPage => Math.min(prevPage + 1, totalPages));
  };

  const handleStartChange = (date) => {
    setStartDate(date);

    if (endDate && new Date(date) > new Date(endDate)) {
      setEndDate(date);
    }
  };

  const handleEndChange = (date) => {
    setEndDate(date);

    if (startDate && new Date(date) < new Date(startDate)) {
      setStartDate(date);
    }
  };

  const prevPage = () => {
    setCurrentPage(prevPage => Math.max(prevPage - 1, 1));
  };

  const handleImageClick = () => {
    fileInputRef.current.click();
  };

  const handleFileSelect = (event) => {
    const file = event.target.files[0];
    const headers = {
      Authorization: token
    };

    const formData = new FormData();
    formData.append('image', file);
    setIsLoading(true);

    axios.put(`${process.env.REACT_APP_API_URL}/weekDetails/${id}`, formData, { headers })
      .then((response) => {
        Swal.fire({
          title: "Uploaded!!",
          text: "You have uploaded the image.",
          icon: "success"
        });
        setIsLoading(false);
        setRefresh(true);
        setId('');
      })
      .catch((err) => {
        console.error("Error uploading image:", err);
      });
  }
  

  
  const [rate, setRate] = useState("");
  const token = localStorage.getItem('token');
  if (!token) {
    console.error('Token not found in local storage');
  }

  const headers = {
    Authorization: `${token}`
  };

  useEffect(() => {
    axios.get(`${process.env.REACT_APP_API_URL}/weekDetails/range/${initialId}/${formatToMonthYear(startDate)}/${formatToMonthYear(endDate)}`, { headers })
      .then(response => {
        setData(response.data.timesheets);
        setRate(response.data.project_details.rate);
        setClientName(response.data.project_details.client_id.company_name);
        const startDate = new Date(response.data.project_details.start_date);
        const endDate = new Date(response.data.project_details.end_date);
        const startMonth = startDate.toLocaleString('default', { month: 'long' });
        const endMonth = endDate.toLocaleString('default', { month: 'long' });
        setProjectStartMonth(startMonth);
        setProjectEndMonth(endMonth);
      })
      .catch(error => {
        if (error.response.status === 401) {
          alert("Session Expired Please Login");
          navigate("/");
        }
      });
  }, [startDate, endDate, initialId, refresh]);

  useEffect(() => {
    setIsLoading(true);

    axios.get(`${process.env.REACT_APP_API_URL}/projects/consultant`, { headers })
      .then(response => {
        setClients(response.data.map(obj => ({
          company_name: obj.client_id.company_name,
          client_id: obj._id
        })));
        setIsLoading(false);
      })
      .catch(error => {

      });
  }, []);

  const handleDropDownClick = (id, name) => {
    setSelectedClient(name);
    setInitialId(id);
  };

  return (
    <>
      {isLoading ? (
        <Loader />
      ) : (
      <div className="relative ">
        <div className="flex gap-5 justify-between mb-5 mt-5 items-center px-5 text-md text-black bg-purple-50 rounded-lg shadow-lg max-md:flex-wrap max-md:px-5">
          <div className="flex gap-2 justify-between self-stretch my-auto">
            <div>
              <span className="font-bold ">{name}</span>
            </div>
            
          </div>
          <div class="dropdown ">
          
            {clientName}
          </div>
          <div className="self-stretch my-auto">
            Amount: <span className="font-bold">${rate}</span>
          </div>
          <div className="flex gap-2 self-stretch font-bold">
            <img
              loading="lazy"
              src={Calender}
              className="shrink-0 self-start w-12 aspect-square h-[38px]"
            />
            <div className="flex gap-3 justify-between p-2.5">
            <div>
                  {/* From */}
                  <MonthYearPicker
                    selectedDate={startDate}
                    onChange={handleStartChange}
                    minDate={new Date(projectStartMonth)}
                    maxDate={new Date(endDate) || today}
                  />
                </div>
                →
                <div>
                  {/* To */}
                  <MonthYearPicker
                    selectedDate={endDate}
                    onChange={handleEndChange}
                    minDate={new Date(startDate)}
                    maxDate={today}
                  />
                </div>
            </div>
          </div>
        </div>
        <div className="flex flex-col">

        <div className="w-full">
      {/* Table */}
      <table className="min-w-full bg-purple-50">
        {/* Table Header */}
        <thead className="bg-gray-200">
          <tr className="text-center">
            <th className="px-4 py-2">Week</th>
            <th className="px-4 py-2">Mon</th>
            <th className="px-4 py-2">Tue</th>
            <th className="px-4 py-2">Wed</th>
            <th className="px-4 py-2">Thur</th>
            <th className="px-4 py-2">Fri</th>
            <th className="px-4 py-2">Sat</th>
            <th className="px-4 py-2">Sun</th>
            <th className="px-4 py-2">Amount</th>
            <th className="px-4 py-2">Status</th>
            <th className="px-4 py-2">View</th>
            <th className="px-4 py-2">Upload</th>
          </tr>
        </thead>
        
        {/* Table Body */}
        <tbody>
          {currentRecords?.map((record, index) => (
            <tr key={index} className="text-center">
              <td className="border px-4 py-2">
                {/* <div>{record.week < 10 ? `0${record.week}` : record.week}</div> */}
                <div>{isoWeekToDayAndMonthName(record.year, record.week)}</div>
              </td>
              {Object.values(record.timesheet).map((item, i) => (
                <td key={i} className="border px-4 py-2">
                  {item < 10 ? `0${item}` : item}
                </td>
              ))}
              <td className="border px-4 py-2">
                ${rate * record.total_hrs}
              </td>
              <td className="border px-4 py-2">
                {record?.approval === false ? (
                  <img
                    loading="lazy"
                    src={Deny}
                    className="inline-block w-[30px] "
                    alt="Deny"
                  />
                ) : (
                  <img
                    loading="lazy"
                    src={Accept}
                    className="inline-block w-[30px] "
                    alt="Accept"
                  />
                )}
              </td>
              <td className="border px-4 py-2">
                {record?.image_url === null ? (
                  <img
                    loading="lazy"
                    src={ViewInactive}
                    className="inline-block w-10 h-10 cursor-pointer"
                    alt="Image"
                    onClick={() => downloadFileAtURL(record.image_url)}
                  />
                ) : (
                  <img
                    loading="lazy"
                    src={DarkEye}
                    className="inline-block w-10 h-10 cursor-pointer"
                    alt="Dark Eye"
                    onClick={() => downloadFileAtURL(record.image_url)}
                  />
                )}
              </td>
              <td className="border px-4 py-2">
                <input
                  type="file"
                  ref={fileInputRef}
                  style={{ display: 'none' }}
                  onChange={handleFileSelect}
                />
                {record?.approval === false ? (
                  <img
                    loading="lazy"
                    onClick={() => {
                      setId(record._id);
                      handleImageClick();
                    }}
                    src={Upload}
                    className="inline-block w-10 h-10 cursor-pointer"
                    alt="Upload"
                  />
                ) : (
                  <img
                    loading="lazy"
                    src={DisabledUpload}
                    className="inline-block w-10 h-10 cursor-not-allowed"
                    alt="Disabled Upload"
                  />
                )}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
            <div className="flex justify-end">
              <div className="flex justify-between">
                <img
                  onClick={prevPage}
                  disabled={currentPage === 1}
                  loading="lazy"
                  src={LeftArrow}
                  className="shrink-0 w-10 aspect-square cursor-pointer"
                  height="30px"
                />
                <div className="my-auto text-xl text-black">{currentPage}</div>
                <img
                  onClick={nextPage}
                  disabled={currentPage === totalPages}
                  loading="lazy"
                  src={RightArrow}
                  className="shrink-0 w-10 aspect-square cursor-pointer"
                  height="30px"
                />
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default ViewPreviousTimesheets;

import React, { useState, useEffect } from 'react';
import Button from "@mui/material/Button";
import '../assets/css/WeekTable.css';
import axios from 'axios';
import { DatePicker, Space } from 'antd';
import moment from 'moment';
import Loader from '../loader'
import MonthYearPicker from '../adminComponents/MonthYearPicker';
import { useNavigate } from 'react-router-dom';

const WeekTable = ({ id, updateTotalHours }) => {
    const navigate = useNavigate();


    const [rowData1, setRowData1] = useState([]);
    const formatToYYYYMM = (date) => {
        const year = date.getFullYear();
        const month = date.getMonth() + 1; // Months are zero-based
        return `${year}/${month < 10 ? '0' : ''}${month}`;
    };
    const [isLoading, setIsLoading] = useState(true);
    const [isLoader, setIsLoader] = useState(true);
    const [errorFlag, setErrorFlag] = useState(true);

    const today = new Date();
    const [date, setDate] = useState(formatToYYYYMM(today));
    const [monthData, setmonthData] = useState([(today.getMonth() + 1).toString().padStart(2, '0')]);
    const currentYear = today.getFullYear();

    useEffect(() => {
        const fetchData = async () => {
            const token = localStorage.getItem('token');
            const headers = {
                Authorization: `${token}`
            };
            setIsLoader(true)
            try {
                axios.get(`${process.env.REACT_APP_API_URL}/weekDetails/monthly/${id}/${date}`, { headers })
                    .then(response => {
                        // Process the fetched data here
                        setIsLoader(true)
                        const data = response.data;

                        setRowData1(data)
                        const total = data.reduce((acc, obj) => acc + parseInt(obj.total_hrs || 0), 0);

                        updateTotalHours(total);
                        setIsLoading(false);

                        setErrorFlag(true);
                    })
                    .catch(error => {

                        setIsLoading(false);
                        setErrorFlag(false);
                    });
            } catch (error) {
                if (error.response.status == 401) {


                    alert("Session Expired Please Login");
                    navigate("/");
                }
                console.error('Error fetching data:', error);
            }



        };

        if (id!=='') { 

         
            fetchData(); }
    }, [date, id]);


    if (isLoading) {
        return <p>Loading...</p>;
    }

    const tableStyle = {
        padding: '5px',
        margin: 'auto',
        border: 'none',
        textAlign: 'center'
    };

    const thStyle = {
        ...tableStyle,
        background: 'transparent',
        padding: '5px 5px' // Remove default grey color from thead
    };

    const handleStartChange = (date) => {
        const dateObject = new Date(date);
        const year = dateObject.getFullYear();
        const month = dateObject.getMonth() + 1; // Adding 1 because months are zero-based
        const formattedDate = `${year}/${month < 10 ? '0' : ''}${month}`;
        setDate(formattedDate)
    }



    return (
        <>
            {isLoading ? (
                <Loader /> // You can replace this with your own loading indicator component
            ) : (
                <div className='w-100'>
                    <div className='justify-center flex bg-transparent'>
                        {/* <Space direction="vertical">
                <DatePicker onChange={onChange} picker="month" />
            </Space> */}
                        <div className='flex gap-3 align-items-center'>
                            <h4>Date</h4>
                            <MonthYearPicker selectedDate={date} onChange={handleStartChange} height={48} width={105} />
                        </div>
                    </div>

                    {!errorFlag && <p className='week-table'>Timesheet Not Found</p>}
                    {errorFlag && <div className='week-table'>

                        <table className="week-table">
                            <thead>
                                <tr>
                                    <th style={thStyle}>Week</th>
                                    <th style={thStyle}>Mon</th>
                                    <th style={thStyle}>Tue</th>
                                    <th style={thStyle}>Wed</th>
                                    <th style={thStyle}>Thu</th>
                                    <th style={thStyle}>Fri</th>
                                    <th style={thStyle}>Sat</th>
                                    <th style={thStyle}>Sun</th>
                                    <th style={thStyle}>Total Hours</th>
                                    <th style={thStyle}>Status</th>
                                    {/* <th style={thStyle}>Actions</th> */}

                                </tr>
                            </thead>
                            <tbody>
                                {rowData1.map((timesheet, index) => (
                                    <tr key={index}>
                                        <td>
                                            <Button variant="outlined" className="button">
                                                {timesheet.timesheet && Object.keys(timesheet.timesheet).length > 0 ?
                                                    `${Object.keys(timesheet.timesheet)[0].substring(0, 10)} to ${Object.keys(timesheet.timesheet)[Object.keys(timesheet.timesheet).length - 1].substring(0, 10)}`
                                                    : ""}
                                            </Button>
                                        </td>
                                        {timesheet.timesheet && Object.entries(timesheet.timesheet).map(([date, hours], dataIndex) => (

                                            <td key={dataIndex}>
                                                <h3 className="input-field">{hours}</h3>
                                            </td>


                                        ))}

                                        <td>
                                            <h3 className="">{timesheet.total_hrs}</h3>
                                        </td>

                                        <td>
                       
                                            {timesheet.status === "Pending" ? (
                                                <div className="justify-center self-stretch p-2.5 text-xl leading-7 text-black capitalize whitespace-nowrap bg-red-400 rounded border border-red-800 border-solid">
                                                    {timesheet.status}
                                                </div>
                                            ) : timesheet.status === "In Review" ? (
                                                <div className="justify-center self-stretch p-2.5 text-xl leading-7 text-black capitalize whitespace-nowrap bg-yellow-200 rounded border border-red-800 border-solid">
                                                    {timesheet.status}
                                                </div>
                                            ) : timesheet.status === "Approved" ? (
                                                <div className="justify-center self-stretch p-2.5 text-xl leading-7 text-black capitalize whitespace-nowrap bg-green-400 rounded border border-red-800 border-solid">
                                                    {timesheet.status}
                                                </div>
                                            ) :
                                                <div className="justify-center self-stretch p-2.5 text-xl leading-7 text-black capitalize whitespace-nowrap bg-blue-400 rounded border border-red-800 border-solid">
                                                    {timesheet.status}
                                                </div>
                                            }


                                        </td>
                                    </tr>
                                ))}

                            </tbody>
                        </table>

                    </div>}
                </div>)}
        </>
    );
};

export default WeekTable;

import * as React from "react";
// import Navbar from "../../Navbar" 
import { useState, useEffect } from 'react';
import TimeSheet from "./AddTimesheetFormTimeSheet"


function AddTimesheetForm() {
  const [userData, setUserData] = useState(null);
  const [dropValue, setDropValue] = useState('');
  const [weekid,setWeekId]=useState('');

    return (
          <>
      <div className="container">
     
        <header className="header">
          <div className="user-info">
            {/* <div className="user-name">Selina</div> */}
            {userData && (
              <>
                <span style={{ fontWeight: 300 }}>Welcome</span>,
                <span style={{ fontWeight: 600 }}>{userData.name}</span>
              </>
            )}
          </div>
          
          {/* <ClientSelector /> */}
        
        </header>
        {/* <WeeklyHours /> */}
        <main>
          {/* <WeekSelector /> */}
          <TimeSheet weekid={weekid} projectid={dropValue}  />
        </main>
        {/* <Actions /> */}
      </div>
    </>
    )
}
export default AddTimesheetForm;
import React, { useState, useEffect, useRef } from 'react';
import { getISOWeek, getISOWeekYear } from 'date-fns';
import axios from 'axios';
import Loader from '../loader'
import attachment from "../assets/images/add attachment.svg"
import Time from "../assets/images/pepicons-print_rewind-time.svg"
import ArrowLeft from "../assets/images/arrow left.svg"
import ArrowRight from "../assets/images/arrow right.svg"
import { useLocation } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2'

function getWeekNumber(d) {
    d = new Date(Date.UTC(d.getFullYear(), d.getMonth(), d.getDate()));
    d.setUTCDate(d.getUTCDate() + 4 - (d.getUTCDay() || 7));
    var yearStart = new Date(Date.UTC(d.getUTCFullYear(), 0, 1));
    var weekNo = Math.ceil((((d - yearStart) / 86400000) + 1) / 7);
    return weekNo;
}
export default function AddTimesheetFormTimeSheet() {
    // For File
    const [file, setFile] = useState(null);
    const [fileName, setFileName] = useState('No file selected')
    const [fileAdded, setFileAdded] = useState(false);
    const navigate = useNavigate();
    let location = useLocation();
    const [currentProjectWeek, setCurrentProjectWeek] = useState();
    const [weekNumber, setWeekNumber] = useState(
        location?.state?.item?.week ? location.state.item.week : getWeekNumber(new Date()) - 1
    );
    const [weekData, setWeekData] = useState({});
    const [enableButtons, setEnableButtons] = useState(false);

    let initialHoursInput = {};
    const token = localStorage.getItem('token');

    try {
        // Ensure weekData is an object before processing it. If weekData is undefined, default to an empty object
        initialHoursInput = Object.entries(weekData || {}).reduce((acc, [date, hours]) => ({
            ...acc,
            [date]: hours
        }), {});
    } catch (error) {
        if (error.response.status == 401) {


            alert("Session Expired Please Login");
            navigate("/");
        }


        initialHoursInput = {};  // Reset to default if there was an error
    }

    const [hoursInput, setHoursInput] = useState(initialHoursInput);
    const [yearNumber, setYearNumber] = useState(
        location?.state?.item?.year ? location.state.item.year : new Date().getFullYear()
    );

    const [isLoading, setIsLoading] = useState(false);

    if (!token) {
        console.error('Token not found in local storage');
    }

    const headers = {
        Authorization: `${token}`
    };

    //GetProjectByConsultantId
    const [companyNames, setCompanyNames] = useState([]);
    const [selectedProjectId, setSelectedProjectId] = useState('');
    const [consultantName, setConsultantName] = useState('');
    const [initialProjectWeek,setInitialProjectWeek]=useState();
    useEffect(() => {
        const currentDate = new Date();
        const currentISOWeek = getISOWeek(currentDate) - 1;

        setIsLoading(true);
        try {
            axios.get(`${process.env.REACT_APP_API_URL}/projects/consultant`, { headers })
                .then(response => {
                    // setCurrentProjectWeek(response.data.current_week_number);
                    setIsLoading(false);
           
                    const newProjectCompanyMap = response.data

                    setCompanyNames(newProjectCompanyMap);
                    if (Object.keys(response.data).length > 0) {
                        setSelectedProjectId(location?.state?.item?._id ? location.state.item._id : response.data[0]._id) // Set the first project's ID as default
                        setCurrentProjectWeek(location?.state?.item?.current_week_number ? location.state.item.current_week_number - (currentISOWeek - location.state.item.week) : response.data[0].current_week_number);
                        setInitialProjectWeek(location?.state?.item?.current_week_number ? location.state.item.current_week_number - (currentISOWeek - location.state.item.week) : response.data[0].current_week_number);
                    
                    }
                    setConsultantName(response.data[0]?.consultant_id?.name)
                })
                .catch(error => {
                    console.error('Error:', error);
                });
        } catch (error) {

        }
    }, [])


    const handleProjectSelectionChange = (e) => {
        const projectId = e.target.value;
        const week = e.target.options[e.target.selectedIndex].getAttribute('week');
        setSelectedProjectId(projectId);
        setCurrentProjectWeek(parseInt(week));
    };
    // Function to decrement the week number

    const handlePrevWeek = () => {
        if(currentProjectWeek>1){
            setHoursInput({});
            setFileName(" ")
            setFile(null)
            setCurrentProjectWeek(prev => prev - 1);
            if (weekNumber == 1) {
                setYearNumber(prev => prev - 1);
                setWeekNumber(52);
            }
            else {
                setWeekNumber(prevWeek => prevWeek - 1);
    
            }
        }
    };


    // Function to increment the week number
    const handleNextWeek = () => {
        setHoursInput({});
        setFileName(" ")
        setFile(null)

        // Get the current date's ISO week and year
        const currentDate = new Date();
        const currentISOWeek = getISOWeek(currentDate);
        const currentISOYear = getISOWeekYear(currentDate);

        if (yearNumber === currentISOYear && weekNumber === currentISOWeek - 1) {
         
            return;
        }

        if (weekNumber === 52) {
            setYearNumber(prev => prev + 1);
            setWeekNumber(1); // ISO weeks start from 1
        } else {
            setCurrentProjectWeek(prev => prev + 1);
            setWeekNumber(nextWeek => nextWeek + 1);
        }
    };



    useEffect(() => {
        if (weekNumber && selectedProjectId) {  // Fetch data if at least one is truthy
            fetchWeekData(weekNumber);
        }
    }, [weekNumber, selectedProjectId])

    const [flag, setFlag] = useState(false);
    const [timesheetID, setTimesheetID] = useState();
    const [isUpdate, setIsUpdate] = useState(false);
    const fetchWeekData = async (weekNumber) => {
        setIsLoading(true);
        setHoursInput({});
        try {
            const response = await axios.get(`${process.env.REACT_APP_API_URL}/weekDetails/weekly/${selectedProjectId}/${yearNumber}/${weekNumber}`, { headers });

            setIsLoading(false);

            if (Boolean(response.data.length === 0)) {
                setIsUpdate(false);
                setFlag(true);
                setEnableButtons(false);
                setWeekData(weekDetails);
                setComments("")
            }
            else {
                setIsUpdate(true);
                setTimesheetID(response.data[0]._id);

                const approved = response.data[0].approval;

                const timesheetData = response?.data[0]?.timesheet;
                setFlag(!approved);
                setEnableButtons(approved);
                const initialHours = Object.entries(timesheetData).reduce((acc, [date, hours]) => ({
                    ...acc,
                    [date]: hours
                }), {});
                setComments(response.data[0].remark)
                setHoursInput(initialHours);
                setWeekData(response?.data[0]?.timesheet);
            }


        } catch (error) {
            if (error.response.status == 401) {


                alert("Session Expired Please Login");
                navigate("/");
            }

            console.error('Failed to fetch week data:');
        }
    };

    function getWeekDetailsFromISO(weekNumber, year) {
        // Helper function to find the first day of the ISO year
        function getFirstDayOfISOYear(y) {
            const simple = new Date(y, 0, 1); // January 1st of given year
            const dayOfWeek = simple.getDay(); // Day of week
            const ISOStart = new Date(simple);
            if (dayOfWeek <= 4) {
                ISOStart.setDate(simple.getDate() - simple.getDay() + 1);
                // Get the Monday of this week
            } else {
                ISOStart.setDate(simple.getDate() + 8 - simple.getDay()); // Get next Monday
            }
            return ISOStart;
        }

        const firstDayOfISOYear = getFirstDayOfISOYear(year);
        const firstISOWeekStartDate = new Date(firstDayOfISOYear);
        firstISOWeekStartDate.setDate(firstISOWeekStartDate.getDate() + (weekNumber - 1) * 7); // Get start date of the given ISO week number

        const weekDates = {}; // Use an object instead of an array

        // Loop from Monday to Friday (5 days)
        for (let i = 0; i < 7; i++) {
            const day = new Date(firstISOWeekStartDate);
            day.setDate(day.getDate() + i);

            weekDates[day.toISOString().substring(0, 10)] = 0; // Format to 'YYYY-MM-DD' and set value to 0
        }

        return weekDates;
    }

    const [recordedWeek, setRecordedWeek] = useState();
    const weekDetails = getWeekDetailsFromISO(weekNumber, yearNumber);


    useEffect(() => {
        const totalValue = Object.values(weekData).reduce((acc, currentValue) => {
            return acc + currentValue;
        }, 0);

        setRecordedWeek(totalValue);
    }, [weekNumber, weekData])


    function formatDate(inputDate) {
        // Add 1 day to the input date to get the correct date
        const date = new Date(inputDate);
        date.setDate(date.getDate() + 1);
        const formatter = new Intl.DateTimeFormat('en-US', {
            weekday: 'short', // "Mon"
            month: 'short',   // "Apr"
            day: 'numeric',   // "15"
            timeZone: 'EST'
        });
        const formattedDate = formatter.format(date);
        return formattedDate;
    }



    // Handle input changes for empty data 
    const handleInputChange = (date, value) => {
        setHoursInput(prev => ({
            ...prev,
            [date]: value
        }));
    };

    const [totalHours, setTotalHours] = useState(0);
    const [triggerUpload, setTriggerUpload] = useState(false);
    const [comments, setComments] = useState("");

    const calculateTotal = () => {
        let total = 0;
        for (const date in hoursInput) {
            total += parseInt(hoursInput[date]);
        }
        return total;
    };

    // Example function to perform an API call
    const submitHours = async () => {

        setTotalHours(calculateTotal());
        setTriggerUpload(true);

    };


    // const [uploadStatus, setUploadStatus] = useState('');

    const handleFileSelect = (event) => {
        const selectedFile = event.target.files[0];
        if (selectedFile) {
            setFile(selectedFile);
            setFileName(selectedFile.name);
            setFileAdded(true); // Set the file added state to true

        } else {
            setFile(null);
            setFileName('No file selected');

        }


    };

    const uploadFile = async (selectedFile) => {
        if(!isUpdate){

            if(file==null){
                Swal.fire({
                    icon: "error",
                    title: "Oops...",
                    text: "Please Attach the File!",
    
                }).then((result) => {
                    // if (result.isConfirmed || result.isDismissed) {
    
                    //     window.location.reload();
                    // }
                });
                return;
            }
        }

        if (!isValidTimesheet(hoursInput)) {
            
            Swal.fire({
                icon: "error",
                title: "Oops...",
                text: "Please fill the timesheet ",
            }).then((result) => {
                window.location.reload();
            });
            return;
        } else {
            // Proceed with uploading the timesheet
            // console.log("Timesheet is ready for upload.");
        }
        const formData = new FormData();
        formData.append('remark', comments);
        formData.append('project_id', selectedProjectId);
        formData.append('total_hrs', totalHours);
        formData.append('year', yearNumber);
        formData.append('week', weekNumber);
        formData.append('timesheet', JSON.stringify(hoursInput));
        formData.append('image', selectedFile);
        setHoursInput({});
        setIsLoading(true);

        try {
            if (isUpdate) {
                const response = await axios.put(`${process.env.REACT_APP_API_URL}/weekDetails/${timesheetID}`, formData, {
                    headers: {
                        'Authorization': `${token}`
                    },
                });

                setIsLoading(false);
            } else {
                const response = await axios.post(`${process.env.REACT_APP_API_URL}/weekDetails`, formData, {
                    headers: {
                        'Authorization': `${token}`
                    },
                });

         
                setIsLoading(false);
            }

            // setUploadStatus('File uploaded successfully.');
        } catch (error) {
            if (error.response.status == 401) {


                alert("Session Expired Please Login");
                navigate("/");
            }
            console.error('Error uploading file:', error);
            // setUploadStatus('Error uploading file.');
        }
    };



    const addFile = async () => {

        document.getElementById('fileInput').click();
    }


    useEffect(() => {
        if (triggerUpload) {
           
            uploadFile(file);
            setTriggerUpload(false);  // Reset the flag after uploading
           
        }
    }, [totalHours, triggerUpload, hoursInput]);

    //final submit function 
    const isValidTimesheet = (input) => {
        const keys = Object.keys(input);
        if (keys.length < 7) {
            return false;
        }
    
        for (const key of keys) {
            if (input[key] === "" || input[key] == null) {
                return false;
            }
        }
    
        return true;
    };
    
    const handleSubmit = () => {
        if (isUpdate) {
            if (Object.keys(hoursInput).length < 7) {
                Swal.fire({
                    icon: "error",
                    title: "Oops...",
                    text: "Please fill the timesheet before uploading it!",
                }).then((result) => {

                });
            }
            submitHours()
            setFileAdded(false);
            Swal.fire({
                title: "You have submitted the timesheet",
                icon: "success",
                showConfirmButton: false,
                timer: 1500
            }).then((result) => {
                navigate("/consultant/consultantDashboard")
                // window.location.reload();

            });
        }
        else {
            if (!fileAdded) {
                Swal.fire({
                    icon: "error",
                    title: "Oops...",
                    text: "Please Attach the File!",

                }).then((result) => {
                    // if (result.isConfirmed || result.isDismissed) {

                    //     window.location.reload();
                    // }
                });
            }
            // Check if timesheet is filled or not
            else if (Object.keys(hoursInput).length < 7) {
                Swal.fire({
                    icon: "error",
                    title: "Oops...",
                    text: "Please fill the timesheet before uploading it!",
                }).then((result) => {
                    if (result.isConfirmed || result.isDismissed) {

                        window.location.reload();
                    }
                });
            }
            else {
                submitHours()
                setFileAdded(false);
                Swal.fire({
                    title: "You have submitted the timesheet",
                    icon: "success",
                    showConfirmButton: false,
                    timer: 1500
                }).then((result) => {
                    // window.location.reload();
                    navigate("/consultant/consultantDashboard")
                });
            }
        }
        // if(!fileAdded){
        //     Swal.fire({
        //         icon: "error",
        //         title: "Oops...",
        //         text: "Something went wrong!",

        //       }).then((result) => {
        //         if (result.isConfirmed || result.isDismissed) {

        //           window.location.reload();
        //         }
        //       });
        // }else
        // {
        // submitHours()
        // setFileAdded(false);
        // Swal.fire({
        //     title: "Good job!",
        //     text: "You have submitted the timesheet",
        //     icon: "success"
        //   }).then((result) => {
        //      window.location.reload();

        //   });
        // }

    }

    // Function to go to the current week
    const handleGoToCurrentWeek = () => {

        const currentWeek = getWeekNumber(new Date());
        setWeekNumber(currentWeek-1);
        setCurrentProjectWeek(initialProjectWeek);

    };

    const prevTimesheet = () => {
        navigate('/consultant/consultantDashboard/viewPreviousTimesheets', {
            state: {
                projectId: selectedProjectId,
                name: consultantName
            },
        });
   
    }



    return (
        <>
            {isLoading ? (
                <Loader /> // You can replace this with your own loading indicator component
            ) : (
                <div className="flex flex-col pt-8 bg-purple-50">
                    <div className="flex flex-col items-center self-center  w-full max-w-[1247px] max-md:max-w-full">
                        <div className="flex gap-5 justify-between px-4 py-0.5 mt-11 w-full bg-purple-50 rounded-lg shadow-md max-w-[1134px] max-md:flex-wrap max-md:mt-10 max-md:max-w-full">
                            <div className="flex gap-5 justify-between my-auto text-2xl">
                                <div className="flex flex-col justify-center font-semibold text-black whitespace-nowrap">
                                    <div className="flex flex-col justify-center">
                                        <div className="justify-center px-2 py-2.5">Welcome, {consultantName}</div>
                                    </div>
                                </div>
                                <div className="flex flex-col justify-center">
                                    <div className="flex gap-0">
                                        <div className="justify-center py-2.5 pl-2 font-light text-black whitespace-nowrap">
                                            Client:
                                        </div>
                                        <div className="flex gap-1.5 font-semibold text-zinc-500">
                                            <div className="justify-center px-2 py-2.5">
                                                <select
                                                    id="projectSelect"
                                                    className="form-select"
                                                    value={selectedProjectId}
                                                    onChange={handleProjectSelectionChange}
                                                    aria-label="Vendor select example"
                                                >
                                                    {location?.state?.item?._id && (
                                                        <option value={location.state.item._id}>
                                                            {
                                                                companyNames.find(company => company._id === location.state.item._id)?.client_id.company_name
                                                            }
                                                        </option>
                                                    )}
                                                    {companyNames.map((data) => (
                                                        data._id !== location?.state?.item?._id && (
                                                            <option week={data.current_week_number} key={data._id} value={data._id}>
                                                                {data.client_id.company_name}
                                                            </option>
                                                        )
                                                    ))}
                                                </select>
                                            </div>
                                            
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="flex flex-col text-black">
                                <div className="flex gap-2.5 mx-5 text-4xl font-semibold tracking-normal leading-5 text-center max-md:mx-2.5">
                                    <img
                                        loading="lazy"
                                        src={Time}
                                        className="shrink-0 w-12 aspect-square"
                                    />
                                    <div className="my-auto">{recordedWeek}.00 h</div>
                                </div>
                                <div className="text-2xl">Recorded this week</div>
                            </div>
                        </div>

                        {/* week */}
                        {selectedProjectId &&
                            <div className="flex gap-5 justify-between items-start mt-9 w-full text-2xl text-black max-w-[1134px] max-md:flex-wrap max-md:max-w-full">
                                <div className="flex gap-1 justify-between bg-purple-50 rounded-lg shadow-md">
                                    {/* Button with previous week image */}
                                    <button onClick={handlePrevWeek} className="shrink-0 w-10 aspect-[0.81]">
                                        <img
                                            loading="lazy"
                                            src={ArrowLeft}
                                            alt="Previous Week"
                                        />
                                    </button>

                                    {/* Display the current week number */}
                                    <div className="flex gap-0 items-center">
                                        <div className="justify-center p-2.5">Week {currentProjectWeek}</div>
                                    </div>

                                    {/* Button with next week image */}
                                    <button onClick={handleNextWeek} className="shrink-0 w-10 aspect-[0.81]">
                                        <img
                                            loading="lazy"
                                            src={ArrowRight}
                                        />
                                    </button>
                                </div>
                                <div className="flex gap-5 justify-center mt-3 text-center">
                                    <div style={{ cursor: 'pointer' }} className="justify-center p-2.5 bg-fuchsia-100 rounded-lg border border-fuchsia-100 border-solid shadow-sm" onClick={handleGoToCurrentWeek}>
                                        Go to current week
                                    </div>
                                </div>
                            </div>
                        }

                        <div className="flex  gap-2.5 justify-center px-2 mt-6 ml-14 tracking-normal text-center text-black bg-fuchsia-100 rounded-lg shadow-md max-md:flex-wrap">
                            {weekData && Object.entries(weekData).map(([date, hours], index) => (
                                <div key={index} className="flex flex-col gap-2 w-[130px] flex-1 px-1 py-4 bg-violet-300 max-md:px-5">
                                    <div className="justify-center w-full  text-l font-medium leading-5">
                                        {formatDate(date)}
                                    </div>
                                    <div className="justify-center self-center p-2 bg-violet-300 text-lg leading-5">
                                        {flag ? (
                                            <>
                                                <input
                                                    type="text"
                                                    value={hoursInput[date]}
                                                    onChange={e => handleInputChange(date, e.target.value)}
                                                    className="text-center bg-white w-full"
                                                ></input>
                                            </>
                                        ) : (
                                            // <input
                                            //     type="text"
                                            //     value={hours || ''}
                                            //     onChange={e => handleInputChange(date, e.target.value)}
                                            //     className="text-center bg-white w-full"
                                            // />
                                            <p className='bg-violet-300'>{hours}</p>
                                        )}

                                    </div>

                                </div>
                            ))}

                            {!selectedProjectId &&
                                <div className="justify-center p-2.5 text-2xl font-medium leading-5">
                                    <div className="justify-center p-2.5 self-center  text-lg leading-5">
                                        <h6>PLEASE SELECT SUITABLE PROJECT</h6>
                                    </div>
                                </div>}
                        </div>
                        <div className="mt-3">
                            <label for="exampleFormControlSelect" class="form-label ">
                                Remark
                            </label>
                            <input
                                value={comments}
                                type="text"
                                disabled={enableButtons}
                                class="form-control bg-light focus:text-black
                                    "
                                id="exampleFormControlInput1"
                                onChange={(e) => setComments(e.target.value)}
                            />
                        </div>
                        {selectedProjectId &&
                            <div className="flex gap-5 justify-between mt-9 w-full max-w-[1134px] max-md:flex-wrap max-md:max-w-full">
                                <div className="flex gap-5 justify-center max-md:flex-wrap">
                                    <div className='flex flex-col'>
                                        <button onClick={addFile}><div className="flex gap-2 justify-center px-6 py-2.5 bg-emerald-200 rounded-lg shadow-md max-md:px-5">
                                            <div className="flex justify-center items-center p-0.5">
                                                <img
                                                    loading="lazy"
                                                    src={attachment}
                                                    className="w-8 aspect-[1.03] fill-emerald-800"
                                                />
                                            </div>
                                            <div className="my-auto text-2xl font-medium tracking-normal leading-5 text-center text-black">
                                                <input
                                                    type="file"
                                                    id="fileInput"
                                                    accept="image/*,.pdf"
                                                    style={{ display: 'none' }} // Hide the actual file input
                                                    disabled={enableButtons}
                                                    onChange={handleFileSelect}
                                                />
                                                Add Attachment

                                            </div>

                                        </div></button>

                                        <div class="h-0 w-0 overflow-visible">
                                            <span class="inline-block h-auto w-auto whitespace-nowrap text-lg pt-4">{fileAdded && <div className="text-green-500">{fileName}</div>}
                                            </span>
                                        </div>

                                    </div>

                                    {/* <p>Selected File: {fileName}</p>     */}
                                    {/* <p>{uploadStatus}</p> */}
                                    <div style={{ cursor: 'pointer' }} className="justify-center p-2.5 text-2xl text-center text-black bg-fuchsia-100 rounded-lg">
                                        <button disabled={enableButtons} onClick={handleSubmit}>Save and Submit</button>
                                    </div>
                                    {/* <div className="form-check mt-3">
                                        Bind onChange event to handleCheckboxChange function
                                        <input
                                            className="form-check-input"
                                            type="checkbox"
                                            value=""
                                            id="defaultCheck1"
                                            checked={isChecked} 
                                            onChange={handleCheckboxChange}
                                        />
                                        <label className="form-check-label" htmlFor="defaultCheck1">
                                            On leave
                                        </label>
                                    </div> */}
                                </div>
                                <div className="justify-center p-2.5 text-2xl text-center text-black bg-fuchsia-100 rounded-lg border border-fuchsia-100 border-solid shadow-sm">
                                    <button onClick={prevTimesheet}>View Previous Timesheets</button>
                                </div>
                            </div>
                        }
                    </div>
                </div>
            )}
        </>
    )
}


import { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import "./assets/css/LoginPage.css";
import axios from 'axios';
import companyLogo from "./assets/images/loginLogo.svg";
import { useAuth } from "../pages/AuthContext"
import Loader from './loader'
import Swal from 'sweetalert2'
function LoginForm() {
  const { setAuth } = useAuth();
  const [email, setEmail] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [password, setPassword] = useState('');
  const [error, setError] = useState(null);
  const [selectedOption, setSelectedOption] = useState();
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    setSelectedOption(location.pathname === '/admin' ? 1 : location.pathname === '/Admin' ? 1 : 0);
  }, [location.pathname]);

  useEffect(() => {
    const token = localStorage.getItem('token');
    const otpVerified = localStorage.getItem('otpVerified');
    if (token && otpVerified) {
      navigate("/consultant/consultantDashboard")
    }
    setIsLoading(false); // Set loading to false after checking the token
  }, []);


  const handleSubmit = async (e) => {
    e.preventDefault();

    let apiEndpoint = '';
    setIsLoading(true);
    if (selectedOption === 0) {
     
      apiEndpoint = `${process.env.REACT_APP_API_URL}/consultants/login/`;
    } else {
      apiEndpoint = `${process.env.REACT_APP_API_URL}/consultantmanagers/login/`;
    }
    try {
      const response = await axios.post(apiEndpoint, {
        email,
        password,
      });
      setIsLoading(false);
   
      localStorage.setItem('token', response.data.token);
      // localStorage.setItem('id', response.data.user.id || response.data.user._id);
      localStorage.setItem("usertype", selectedOption.toString()); // Ensure this is stored as a string


      if (response.status === 200 && selectedOption === 0) {
        setAuth({ email, password });
        sessionStorage.setItem('canAccessTwoFA', 'true');
        navigate('/consultant/twoFA');
      } else {
        navigate('/admin/adminDashboard');
      }
    } catch (error) {

      if (error.response) {
        const { status, data } = error.response;

        if (status === 401 || status === 404 || status === 400) {

          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: error.response.data.error,
          }).then((result) => {
            if (result.isConfirmed || result.isDismissed) {

              window.location.reload();
              setIsLoading(false);

            }
          });

          setError('Invalid password. Please try again.');
        } else if (selectedOption === 0 && status === 403) {
          
          localStorage.setItem('tempToken', data.token); // Example of storing the token received in error response
          navigate("/consultant/consultantNewPassword", { state: { email } });
          setError('You must set your initial password');
        } else {
          console.error(error);
          setError('An unexpected error occurred. Please try again later.');
        }
      } else {
        // This handles cases where there is no response (e.g., network error)
        console.error('Network error or no server response:', error);
        setError('Network error or no server response.');
      }
    }
  };


  return (
    <>
      {isLoading ? (
        <Loader /> // You can replace this with your own loading indicator component
      ) : (
        <div className="div">
          <form onSubmit={handleSubmit} >
            <div className="div-2">
              <img src={companyLogo} alt="Technman Logo" />
              <div className="div-3">Welcome back {selectedOption == 0 ? "Consultant" : "Admin"}! Please enter your details.</div>
              <input
                className="div-7 text-black"
                type="text"
                placeholder="Email or username"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
              <input className="div-8 text-black"
                type="password"
                placeholder="Password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
              <div className="div-9">
                {error && <p className="error-message">{error}</p>}
              </div>
              <button className="button-like-div-12"
                type='submit'>
                Login
              </button>
            </div>
          </form>
        </div>)}
    </>
  );
}
export default LoginForm;
import * as React from 'react';
import footer from "../../components/assets/images/consultantDashboardFooter.png";
import Navbar from '../../components/consultantComponents/Navbar';
import AddTimesheetForm from '../../components/consultantComponents/AddTimesheetForm';
import PersonalInfoForm from '../../components/consultantComponents/PersonalInfoForm';
import ConsultantDashboardView from '../../components/consultantComponents/ConsultantDashboardView';
import ViewPreviousTimesheets from  "../../components/consultantComponents/ViewPreviousTimesheet"
import { useLocation, useNavigate } from 'react-router-dom';

function ConsultantDashboard() {
  const location = useLocation();
  const navigate = useNavigate();
  const path = location.pathname;

  if (localStorage.getItem('usertype') === '0' || localStorage.getItem('id') === undefined || localStorage.getItem('token') === undefined) {
    return (
      <>
        <div className="flex flex-col min-h-screen">
          <div style={{ justifyContent: "space-between" }} className="flex flex-col flex-grow pt-8 bg-purple-50 ">
            <div className="flex flex-col self-center w-full max-w-[1087px] max-md:max-w-full">
              <Navbar />
              {path === "/consultant/consultantDashboard" ? <ConsultantDashboardView />
                : path === "/consultant/consultantDashboard/addTimesheet" ? <AddTimesheetForm />
                  : path === '/consultant/consultantDashboard/personalInfo' ? <PersonalInfoForm />
                    : path === '/consultant/consultantDashboard/viewPreviousTimesheets' ? <ViewPreviousTimesheets />
                      : <h1>404 Not Found</h1>}
            </div>
            <div className="flex-shrink-0">
              <img
                loading="lazy"
                srcSet={footer}
                className="w-full aspect-[7.69]"
              />
            </div>
          </div>
        </div>
      </>
    );
  } else {
    localStorage.removeItem("token");
    localStorage.removeItem("otpVerified");
    localStorage.removeItem("usertype");
    window.alert("You are not authorized to view this page or your session has expired, please log in again.");
    window.location.href = '/';
    return (
      <>
      </>
    );
  }
}

export default ConsultantDashboard;
